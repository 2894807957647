 @font-face {
  font-family: 'Metropolis-Regular';
  src: url('font/Metropolis-Regular.eot?#iefix') format('embedded-opentype'),  url('font/Metropolis-Regular.otf')  format('opentype'),
	     url('font/Metropolis-Regular.woff') format('woff'), url('font/Metropolis-Regular.ttf')  format('truetype'), url('font/Metropolis-Regular.svg#Metropolis-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Metropolis-ExtraBold';
    src: url('font/Metropolis-ExtraBold.eot?#iefix') format('embedded-opentype'),  url('font/Metropolis-ExtraBold.otf')  format('opentype'),
           url('font/Metropolis-ExtraBold.woff') format('woff'), url('font/Metropolis-ExtraBold.ttf')  format('truetype'), url('font/Metropolis-ExtraBold.svg#Metropolis-ExtraBold') format('svg');
    font-weight: normal;
    font-style: normal;
  }


@font-face {
    font-family: 'Metropolis-Bold';
    src: url('font/Metropolis-Bold.eot?#iefix') format('embedded-opentype'),  url('font/Metropolis-Bold.otf')  format('opentype'),
           url('font/Metropolis-Bold.woff') format('woff'), url('font/Metropolis-Bold.ttf')  format('truetype'), url('font/Metropolis-Bold.svg#Metropolis-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
@font-face {
  font-family: 'Metropolis-SemiBold';
  src: url('font/Metropolis-SemiBold.eot?#iefix') format('embedded-opentype'),  url('font/Metropolis-SemiBold.otf')  format('opentype'),
	     url('font/Metropolis-SemiBold.woff') format('woff'), url('font/Metropolis-SemiBold.ttf')  format('truetype'), url('font/Metropolis-SemiBold.svg#Metropolis-SemiBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

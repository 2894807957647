//@import '../node_modules/leaflet.markercluster/dist/MarkerCluster.Default';
@import '../node_modules/leaflet.markercluster/dist/MarkerCluster';
.marker-cluster-small {
    background-color: rgba(154, 212, 99, 0.6); }
  
  .marker-cluster-small div {
    background-color: #777913; 
    color:#ffffff;}
  
  .marker-cluster-medium {
    background-color: rgba(156, 186, 224, 0.925); }
  
  .marker-cluster-medium div {
    background-color: rgb(37,79,107); 
    color:#ffffff;
  }
  
  .marker-cluster-large {
    background-color: rgba(253, 156, 115, 0.6); }
  
  .marker-cluster-large div {
    background-color: rgb(233,107,81); }
  
  /* IE 6-8 fallback colors */
  .leaflet-oldie .marker-cluster-small {
    background-color: #b5e28c; }
  
  .leaflet-oldie .marker-cluster-small div {
    background-color: #6ecc39; }
  
  .leaflet-oldie .marker-cluster-medium {
    background-color: #f1d357; }
  
  .leaflet-oldie .marker-cluster-medium div {
    background-color: #f0c20c; }
  
  .leaflet-oldie .marker-cluster-large {
    background-color: #fd9c73; }
  
  .leaflet-oldie .marker-cluster-large div {
    background-color: #f18017; }
  
  .marker-cluster {
    background-clip: padding-box;
    border-radius: 20px; }
  
  .marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;
    text-align: center;
    border-radius: 15px;
    font: 20px "Metropolis-Bold"; }
  
  .marker-cluster span {
    line-height: 30px; }
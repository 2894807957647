@import url(https://fonts.googleapis.com/css?family=Cormorant+Garamond:400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Muli:300,400,600,700,800);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css?family=Caveat);
 @font-face {
  font-family: 'Metropolis-Regular';
  src: url(/static/media/Metropolis-Regular.7ac6ee76.eot?#iefix) format('embedded-opentype'),  url(/static/media/Metropolis-Regular.f7b5e589.otf)  format('opentype'),
	     url(/static/media/Metropolis-Regular.dfd2012d.woff) format('woff'), url(/static/media/Metropolis-Regular.179a5d03.ttf)  format('truetype'), url(/static/media/Metropolis-Regular.933a38fb.svg#Metropolis-Regular) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Metropolis-ExtraBold';
    src: url(/static/media/Metropolis-ExtraBold.52a95912.eot?#iefix) format('embedded-opentype'),  url(/static/media/Metropolis-ExtraBold.d7eaa8ab.otf)  format('opentype'),
           url(/static/media/Metropolis-ExtraBold.f41d762d.woff) format('woff'), url(/static/media/Metropolis-ExtraBold.f86e4413.ttf)  format('truetype'), url(/static/media/Metropolis-ExtraBold.40ffeb76.svg#Metropolis-ExtraBold) format('svg');
    font-weight: normal;
    font-style: normal;
  }


@font-face {
    font-family: 'Metropolis-Bold';
    src: url(/static/media/Metropolis-Bold.3bf90079.eot?#iefix) format('embedded-opentype'),  url(/static/media/Metropolis-Bold.dea4998b.otf)  format('opentype'),
           url(/static/media/Metropolis-Bold.d580c2a6.woff) format('woff'), url(/static/media/Metropolis-Bold.4dd4c4c6.ttf)  format('truetype'), url(/static/media/Metropolis-Bold.1aa2876c.svg#Metropolis-Bold) format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
@font-face {
  font-family: 'Metropolis-SemiBold';
  src: url(/static/media/Metropolis-SemiBold.6b51e01b.eot?#iefix) format('embedded-opentype'),  url(/static/media/Metropolis-SemiBold.2556a4f7.otf)  format('opentype'),
	     url(/static/media/Metropolis-SemiBold.f1c1673d.woff) format('woff'), url(/static/media/Metropolis-SemiBold.e3789a5a.ttf)  format('truetype'), url(/static/media/Metropolis-SemiBold.0bf0b9a5.svg#Metropolis-SemiBold) format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Metropolis-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#eff5f6;
}

code {
  font-family: 'Metropolis-Regular';
}
.container{
  max-width:1100px;
  margin:0px auto;
  
}
header{ 
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 70px;
}
.landing-section{
  padding-top:200px;
  text-align:center;
  font-size:22px;
  font-weight: 600;
  max-width:670px;
  margin:0px auto;
}
.landing-section p{
  padding: 25px 0;
  color:#000000;
}
a{
  text-decoration:none;
}
.btn{
  border-radius:3px;
  padding:10px 35px;
  font-size: 12px;
  border:1px solid #231f20;
  height: 40px;
 
}
.btn.facebook{
  background:#3b5998 url(/facebook.png) no-repeat;
  background-position: 15px 10px;
  border-color:#3b5998;
  color:#fff;
}
.btn.facebook:hover{
  background:#27447f url(/facebook.png) no-repeat;
  background-position: 15px 10px;
}
.btn.google{
  background: #dc4e41 url(/google.png) no-repeat;
  background-position: 15px 10px;
  border-color:#dc4e41;
  color:#fff;
}
.btn.google:hover{
  background:#cb3528 url(/google.png) no-repeat;
  background-position: 15px 10px;
}
.form-control{
  height: 42px;
  font-size:12px;
  border:1px solid #e1e1e1;

}
.btn.btn-default{
  background: #004f6b;
  color:#fff;
}
/* .btn.btn-default:hover{
  background: #fff;
  color:#231f20;
} */
.btn.btn-border{
  background: #fff;
  color:#231f20;
}
.btn.btn-border:hover{
  background: #231f20;
  color:#fff;
}
.form-widget{
  background:#fff;
  border-radius:6px;
  box-shadow: 0px 5px 13px #e3e3e3;
  padding: 50px 50px 30px;
 
}
.form-widget.login-widget{
  max-width: 390px;
  margin:0px auto;
  min-height: 200px;
  font-size: 16px;
  margin-top:20px;

}
.table-striped tbody tr:nth-of-type(odd) {
  background-color:#fff;
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0,0,0,.05);
}
.form-widget.login-widget .btn{
  width:100%;
}
.form-widget.login-widget .or-txt{
  font-weight:bold;
  padding-top:15px;
}
.form-widget.login-widget .small{
  font-size:12px;

}

.form-widget.login-widget .need-ac{
  border-top:1px solid #ececec;
  position:relative;
  margin:0px -50px;
  text-align: center;
  font-size: 12px;
  padding-top: 20px;
}
.form-widget.login-widget .small a,
.form-widget.login-widget .need-ac a{
  color:#dc4e41;
  text-decoration:underline;
}
.form-widget.login-widget .small a:hover,
.form-widget.login-widget .need-ac a:hover{
  text-decoration: none;
}
.form-widget.login-widget.register-widget{
  max-width: 630px;
}
.form-widget.login-widget.register-widget label{
  font-size:12px;
}
.form-widget.login-widget.register-widget .signature{
  border:1px solid #000;
  border-radius: 6px;
  padding: 40px 0;
  max-width: 310px;
  margin: 0px auto;
  text-align: center;
  color:#9c9c9c;
}
.form-widget.login-widget.register-widget .disclouser{
  border:1px solid #e1e1e1;
  height: 100px;
  padding: 15px;
  overflow-y: scroll;
  border-radius: 6px;
  font-size: 12px;
  color:#9c9c9c;
  text-align: justify;

}
.form-widget.login-widget.register-widget .btn{
  width: auto;
  padding: 8px 80px;
}
.switch {
  position: relative;
  height: 32px;
  width: 102px;
  margin: 0px auto;

  background:#fff;
  border-radius: 3px;
  border:1px solid #000!important;
 
}

.switch-label {
  position: relative;
  z-index: 2;
  float: left;
  width: 50px;
  line-height: 26px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  color: #000;
  padding-top: 2px;
}
.switch-label:active {
  font-weight: bold;
}

.switch-label-off {
  padding-left: 0px;
}

.switch-label-on {
  padding-right: 0px;
}


.switch-input {
  display: none;
}
.switch-input:checked + .switch-label {
  font-weight: bold;
  color:#fff;
  transition: 0.15s ease-out;
  transition-property: color, text-shadow;
}
.switch-input:checked + .switch-label-on ~ .switch-selection {
  left: 50px;
  /* Note: left: 50%; doesn't transition in WebKit */
}

.switch-selection {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  display: block;
  width: 50px;
  height: 30px;
  border-radius: 3px;
  background-color: #000;
  transition: left 0.15s ease-out;
  color:#fff;
}

main{
  padding:40px 0;
}
h2{
  font-size: 24px;
  font-family: 'Cormorant Garamond', serif;

}
.navbar {
  padding: 0.4rem 1rem;
}

.navbar-nav li{
  margin-left:20px
}
.navbar-nav li a{
  color:#000000;
  font-size: 18px;
  font-weight: 600;
}
.navbar-nav li.active a{
  text-decoration: underline;
}

.calendar{
  background: url('/calendar.png') no-repeat right;
  
 }
 .navbar-nav li img.user{
  margin-right: 8px;
 }
 .navbar-nav .dropdown-menu {
    position: absolute;
    top: 44px;
    width: 200px;
    right: 0px;
 }
 .navbar-nav .dropdown{
   margin-left: 30px;

 }
 .navbar-nav .dropdown a:hover{
   text-decoration: none;
 }
 .navbar-nav .dropdown-menu{
   left:inherit;
   padding: 10px 20px;
 }
 .navbar-nav .dropdown-menu li{
   display: block!important;
   margin-left: 0px;
 }
 .navbar-nav .dropdown-menu li a{
   font-size: 11px!important;
 }
 .container.dashboard{
   max-width: 768px;
 }
 .dashboard h2{
   padding-top: 10px;
 }
 .dashboard .panel-body{
  background:#fff;
  border-radius:0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 12px 0;
  
 }
 .dashboard .table{
   margin-bottom: 0px;
 }
 .dashboard .table td, .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  line-height: 20px;
   font-weight: normal;
   font-size: 16px;
}
.dashboard .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  background: #004F6B;
  color:#fff;
  padding: 18px 35px;

}
.dashboard .table td{
  height: 71px;
  padding: 18px 35px;
}
.btn-row{
  margin-top: 5px;
}
.client-app{
  max-width: 780px;
}
.client-app .form-widget{
  max-width: 780px;
}
.client-app .form-widget label{
  font-size: 12px;
}
.client-app .form-widget .btn{
  padding: 6px 35px;
}
.form-widget .form-wrap{
  width: 280px;
}
.form-widget .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.form-widget .btn.btn-small{
  background: #fff;
  font-size:12px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(153, 153, 153, 0.2);
  border-radius: 3px;
  padding: 10px 25px;
  color:#000;
}
.form-widget .btn.btn-small:hover{
  border:1px solid #004f6b;
  background:#004f6b;
  color:#fff;
}
.form-widget .btn.btn-small i{
  font-size: 18px;
  margin-left: 10px;
}
.client-app .uploadimg{
  width:60px;
  float:left;
  margin-right: 20px;
}
.mtop{
  margin-top: 15px;
}
.form-widget .upload-btn-wrapper input[type=file] {
  font-size: 20px;
  position: absolute!important;
  left: 0;
  top: 0;
  opacity: 0;
}

.profile-widget{
 position: relative;
 padding: 50px 0;
}
.profile-widget .table td,
.profile-widget .table th{
 padding: .75rem;
 vertical-align: top;
 border-bottom: 1px solid #dee2e6!important;
 border-top: 0!important;
}
.profile-widget .panel-body{
 position: relative;
 margin: 20px 0;
}
.profile-widget .edit{
 padding: 0 50px;
}

/* Added on 09 Dec 2018 by Sajjad */

.navbar-header{min-height: 42px} 
.logoPlaceholder{text-align: center; padding: 25px 0px}
.backTo{padding: 15px 0px 0px 0px}


.form-panel{
  max-width:375px;
  margin:0px auto;
  margin-bottom:20px;
}
.form-panel h2{
  font-family: 'Cormorant Garamond', serif;
  font-size:24px;
  margin-top:10px;
}

.form-panel .form-widget .message{
  background:#004F6B;
  width:100%;
  height:120px;
  color:#fff;
  padding:0px 35px;
  font-size:16px;
  display: flex;
  align-items : center;
  width: 100%;
  justify-content : left;
  line-height:20px;
}
.form-panel .form-widget .form-section{
  padding: 20px 35px;
  min-height:280px;
}
.form-panel .form-widget .form-section label{
  font-size:14px;
  margin-bottom:0px;
}
.form-panel .form-widget .form-section .form-control{
  border:0px;
  border-radius:0px;
  border-bottom:1px solid #999;
  height:28px;
  padding:0px;
  outline: none;
}
.form-panel .form-widget .form-section .form-group.error-msg .form-control{
  border-bottom:1px solid #f44336;
  
}
.form-panel .form-widget .form-section .form-group.error-msg p{
  color:#f44336;
  font-size:10px;
}

.form-control:focus {
    outline:none;
}
.form-panel .form-widget{
  position:relative
}
.form-panel .form-widget .pagination{
  margin-bottom:0px;
  position:absolute;
  bottom:15px;
}
.form-panel .form-widget .pagination li{
  display:inline-block;
  border:0px;
}
.form-panel .form-widget .pagination li button{
  color:#BEB7B3;
  font-weight:800;
  font-size:14px;
  padding:0px;
  border:0px;
  border: 0px;
  background: none;
  padding: 0px;
  height: 26px;
}
.form-panel .form-widget .pagination li button:hover{
  background:none;
  color:#004F6B;
}
.form-panel .form-widget .pagination li .page-link.active{
  background:none;
  color:#004F6B;
}

.form-panel .form-widget .pagination li.left,
.form-panel .form-widget .pagination li.right{
  width:50px;
}
.form-panel .form-widget .pagination li.left{
  text-align: left;
}
.form-panel .form-widget .pagination li.right{
  text-align: right;
}
.form-panel .form-widget .btn-wrap{
  max-width:196px;
  margin:0px auto;
  text-align:center;
}
.form-panel .form-widget .btn-wrap .btn{
  margin-bottom:11px; 
  background: #FFFFFF;
 
  border-radius: 3px;
  font-size:12px;
  height:40px;
  padding:10px;
  color:#000;
  border: 1px solid #000000;
box-sizing: border-box;
box-shadow: 0px 2px 2px rgba(0, 79, 107, 0.5);
border-radius: 3px;
}
.form-panel .form-widget .btn-wrap .btn:hover{
  background:#004F6B;
  color:#fff;
  border:1px solid #004F6B;
}

.form-panel .form-widget .btn-wrap .btn.btn-secondary{
  background:#004F6B;
  color:#fff;
  border:1px solid #004F6B;
}
.form-panel .form-widget .btn-wrap .btn.btn-secondary:hover{
  color:#000;
  border:1px solid #bfbfbf;
  background:#fff;
}
.form-panel .form-widget .form-control.text-area{
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height:280px;
  margin-bottom: 15px;
  border-bottom:0px;
  padding:15px;
  overflow-y: scroll;
}
.form-panel .form-widget .form-control.text-area ul{
  padding-left: 30px;
}
.form-panel .form-widget .form-control.text-area h4{
  font-size:13px;
  font-weight: 800;
} 
.form-panel .form-widget .btn.signature-btn{
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.20), 0px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  font-size:12px;
  height:60px;
  padding:20px 15px;
  color:#000;
  border:1px solid #bfbfbf;
  background:#edf2f1;
}
.form-panel .form-widget .btn.btn-submit{
  width:120px;
}
.form-panel .form-widget .btn.signature-btn:hover{
  background:#004F6B;
  color:#fff;
  border:1px solid #004F6B;
}
.form-panel .form-widget .pagination li i{
  font-size:16px;
}
.form-panel .form-widget .pagination li.mid{
  width:208px;
  text-align:center;
  font-size:11px;
  color:#BEB7B3;
}
.form-panel .form-widget .pagination li.mid a{
  font-size:11px;
  font-weight:400;
}
.form-panel .progress-widget{
  max-width:375px;
  margin:0px auto;
  background:#F26C52;
  background:rgba(242,108,82, .3);
  -ms-background:rgba(242,108,82, .3);
  -webkit-background:rgba(242,108,82, .3);
  position:relative;
  height:5px;
}
.form-panel .progress-widget .bar{
  background:#F26C52;
  position:absolute;
  top:0px;
  left:0px;
  height:5px;
  z-index:99;
}
.form-panel .progress-widget .bar.step1{
  width:10%;
}
.form-panel .progress-widget .bar.step2{
  width:20%;
}
.form-panel .progress-widget .bar.step3{
  width:30%;
}
.form-panel .progress-widget .bar.step4{
  width:40%;
}
.form-panel .progress-widget .bar.step5{
  width:50%;
}
.form-panel .progress-widget .bar.step6{
  width:60%;
}
.form-panel .progress-widget .bar.step7{
  width:70%;
}
.form-panel .progress-widget .bar.step8{
  width:80%;
}
.form-panel .progress-widget .bar.step9{
  width:90%;
}
.form-panel .progress-widget .bar.step10{
  width:100%;
}

.form-panel .form-widget{
  background:#fff;
  border-radius:0px;
  box-shadow: 0px 5px 13px #e3e3e3;
  padding: 00px 00px 30px;
 
}
.form-panel .form-widget.login-widget{
  max-width: 375px;
  margin:0px auto;
  font-size: 16px;
  margin-top:20px;
}

.form-panel .form-widget .btn-emp{
  background: #004F6B;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  width: 196px;
  color:#fff;
  border:1px solid #004f6b;
}
.form-panel .form-widget .btn-emp:hover{
  background: #fff;
  border:1px solid #000;
  color:#000;
}
.form-panel .form-widget .sign-in-block{
  max-width: 100%;
}
.form-panel .form-widget.final-widget .message{
  font-size: 14px;
}
.form-panel .form-widget.final-widget .message p.small{
  font-size: 9px;
  line-height: 10px;
  margin: 0px;
}
.form-panel .form-widget.final-widget .payment-dialog{
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  font-size: 10px;
  padding: 42px;
  margin-bottom: 11px;
  cursor: pointer;
}
.form-panel .form-widget.final-widget .btn.signature-btn{
  background: #efedec;
   border: 1px solid #bfbfbf;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none;
  padding: 5px 15px;
  color: #000;
}
.form-panel .form-widget.final-widget .btn-wrap .btn-submit{
  width: 100%;

}
.form-panel .form-widget.edit-profile .message{
  font-size: 18px;
}
.form-panel .form-widget.edit-profile .message i{
  color:#F26C52;
  margin-left: 15px;
}
.form-panel .form-widget.edit-profile .info-wrap{
  width: 170px;
}
.form-panel .form-widget.edit-profile .info-wrap p{
  margin-bottom: 5px;
}
.form-panel .form-widget.edit-profile .info-wrap .ph-no span{
  background: #bfd3da;
  border-radius: 2px;
  font-size: 16px;
  padding: 0px 4px;
}
.form-panel .form-widget.edit-profile .info-wrap i{
  margin-left:5px;
  font-size: 16px;
}
.form-panel .form-widget.edit-profile .info-wrap i:hover{
  color:#F26C52;

}
.form-panel .form-widget.edit-profile .heading-wrap{
   font-size: 18px;
   border-bottom: 1px solid #000;
   margin-bottom: 10px;
}
.form-panel .form-widget.edit-profile .img-wrap{
  position: relative;
  width: 130px;
  margin: 0px auto;
}
.form-panel .form-widget.edit-profile .img-wrap .edit{
  position: absolute;
  top:0px;
  right: 0px;
  font-size: 16px;
  display: block;
  color:#333;
}
.form-panel .form-widget.edit-profile .img-wrap .edit input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 10px;
}

.form-panel .form-widget.edit-profile .img-wrap .edit i{color:#333;}
.form-panel .form-widget.edit-profile .img-wrap .edit i:hover{color:#F26C52;}

.form-panel .form-widget.edit-profile .btn{
  background: none;
  border: 0px;
  color:#ff0000;
  text-decoration: underline;


}
.form-panel .form-widget.edit-profile .agent-wrap{
  margin-top: 20px;
}

.form-panel .form-widget.edit-profile .agent-wrap .btn{
  background: #004F6B;
box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
border-radius: 3px;
  color:#fff;
  width: 150px;
  text-decoration: none;
}
.form-panel .form-widget.edit-profile .info-wrap i{
  color:#333;
}
.form-panel .form-widget.edit-profile .agent-wrap .info-wrap{
  margin-top: 15px;
}
.modal-header{
  background: #004F6B;
}
.modal-header .modal-title{
  line-height: 22px;
  font-size: 16px;
  font-family: 'Metropolis-Regular';
  color:#fff;
}
.modal-dialog {
  margin:8rem auto;
}
.modal-header,
.modal-content{
  border-radius: 0px;
}
.modal-content {
  box-shadow: 0px 5px 13px #e3e3e3;
  border: 0px;
}

.modal-body p{
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 14px;
}
.modal-body .small{
  margin-bottom: 3px!important;
  font-size: 12px!important;  
}
.modal-body .number{
  padding-left: 15px;
}
.full-wth-btn{
  width: 100%;
  color:#000;
}
.btn:hover{
  background: #004F6B;
  color:#fff;
}
tr a{
  color:#000;
  text-decoration: none;
}
.modal-backdrop {
  background-color: #fff;
  opacity: 1!important;
}
.btn.btn-close{
  background: #fff;
  color: #004F6B;
  font-size: 14px;
  font-weight: 700;
  border: 0px;
  padding: 0px 20px;
  height: 20px;
}
.btn.btn-close img{
  padding-left: 6px;
}
.close-btn-wrap {
  top:-20px;
  position: relative;
}



#myVideo{
    position: fixed;
    min-width: 100%; 
    min-height: 100%;
}
.landing-content{
    position: fixed;
    color: #f1f1f1;
    min-width: 100%; 
    min-height: 100%;
}
.btn.disabled, .btn:disabled {
  opacity: .65;
  cursor: not-allowed;
}

.inline-error-class{
  color: #ff0000;
  font-size:11px;
}

.btn.disabled, .btn:disabled {
  cursor: not-allowed;
  background: #ececec !important;
  color: #888 !important;
  border: 1px solid #ececec;
}
input[type="text"], textarea {
  outline: none;
  box-shadow:none !important;
 }
 .close {

  color: #fff;
  text-shadow:none;
  opacity: .5;
}
.navbar-nav.mr-auto, .navbar-nav.mx-auto {
  margin-right: inherit!important;
}
.navbar-nav.mr-auto, .navbar-nav.mx-auto {
  margin-left: auto!important;
}
.logo{
  width: 240px;
  margin-left: 15px;
}
.navbar-dark .navbar-toggler{
  margin-right: 2px;
}
.navbar{
  margin-top: -4px;
  z-index: 1!important;
}
.navbar-nav li {
  line-height: 39px;
}
.navbar-dark .navbar-toggler{
  background: #fff;
}
.bg-light{
  background: #fff!important;
}
.visible-xs-block{
  display: none!important;
}
.hidden-xs-block{
  display:block!important;
}
.form-panel .form-widget .form-control.text-area .form-check label{
  font-size: 12px;
  margin-bottom: 5px;
}
.form-panel .form-widget .form-control.text-area  .form-check{
  line-height: 24px;
}


.container-rd {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;

  -webkit-user-select: none;
  user-select: none;
  height: 40px;
  margin-bottom: 15px!important;
  background: #f1f1f1;
box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
border-radius: 3px;
color: #fff;
font-size: 12px;
}

/* Hide the browser's default radio button */
.container-rd input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.container-rd .txt{
  position: absolute;
  left: 0px;
  top:0px;
  color:#fff;
  z-index: 99;
  text-align: center;
  width: 100%;
  top:12px;
  font-size: 12px;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  background: #f1f1f1;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
}
/* On mouse-over, add a grey background color */
.container-rd:hover input ~ .checkmark {
  background: #004F6B;
box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
border-radius: 3px;
color: #000;
font-size: 12px;
}

/* When the radio button is checked, add a blue background */
.container-rd input:hover ~ .checkmark ,
.container-rd input:checked ~ .checkmark {
  background-color: #f1f1f1;
  border:0px solid #000;
}

.container-rd input:hover ~ .txt,
.container-rd input:checked ~ .txt {
  color:#fff
}
.duration-widget{
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
  border-radius: 2px;
  background: #efedec;
  text-align: center;
  width: 146px;
  height: 38px;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 12px;

}

.form-panel .form-widget .form-section .form-control:focus{
  border-bottom: 2px solid #004F6B!important;

}

/* START DASHBOARD DESIGN*/

.sidebar{
  float: left;
  width: 235px;
  background: #004F6B;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  height: 100%;
  position:relative;
  top:0px;
  z-index: 999;
  float: left;

}
.sidebar, .content{
  height: 100vh;
  position: relative;
  margin-top:-36px;
  margin-bottom:-60px;
}
.sidebar .sidebar-nav{
  padding: 30px 22px;
  margin: 0px;


}
.sidebar .sidebar-nav li{
  list-style: none;
  display: block;
  margin-bottom:6px;
}
.sidebar .sidebar-nav li a{
  color:#fff;
  font-size: 18px;
  font-weight: 800;
  padding:10px;
  text-decoration: none;
  display: block;
}
.sidebar .sidebar-nav li.active a,
.sidebar .sidebar-nav li a:hover,
.sidebar .sidebar-nav li a:focus{
  color:#fff;
  background: #27667d;
  border-radius: 5px;
  
}

.content{
  margin-left:235px;  
}
.content .work-section{
  padding: 0px 50px;
}
.content .work-section .dashboard h2{
  font-family: 'Cormorant Garamond', serif;
  font-size: 32px;
  padding-top: 0px;
  font-weight: 600;
  
}
.content .work-section .dashboard .table td{
  height: auto;
  padding: 12px 35px;
  
}
.content .work-section .dashboard .table th,
.content .work-section .dashboard .table td{
  font-size: 14px;
}
.content .work-section .dashboard .panel-body{
  min-height: 700px;
}
.content .work-section .dashboard .table td{
   border-top: 0px solid #dee2e6;
}
.content .work-section .dashboard .table-striped tbody tr:nth-of-type(even){
  background:#dedbd9;
}
.content .work-section .form-panel{
  padding-top: 150px;
}

.content .work-section .container-rd .txt{
  position: absolute;
  left: 0px;
  top:0px;
  color:#000;
  z-index: 99;
  text-align: center;
  width: 100%;
  top:12px;
  font-size: 12px;
}

/* On mouse-over, add a grey background color */
.content .work-section .container-rd input ~ .checkmark {
background: #004F6B;
box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
border-radius: 3px;
font-size: 12px;
}

/* When the radio button is checked, add a blue background */
.content .work-section .container-rd input:hover ~ .checkmark ,
.content .work-section .container-rd input:checked ~ .checkmark {
  background-color: #f1f1f1;
  border:1px solid #f1f1f1;
}

.content .work-section .container-rd input:hover ~ .txt,
.content .work-section .container-rd input:checked ~ .txt {
  color:#000
}
.auto-search-widget{
  padding: 0;
  margin: 0px;
}
.auto-search-widget li{
  list-style: none;  
}
.search-widget form{
  padding: 0px 30px;
}
.search{
  background: url(/blk-search.png) no-repeat left;
  padding-left: 25px!important;
}
.auto-search-widget li a{
  display: block;
  padding: 3px 10px;
  border-radius: 3px;
  text-decoration: none;
  color:#000;
}
.auto-search-widget li a:hover{
  background: #004F6B;  
  color:#fff;
}
.navbar .header-tag{
  font-size: 36px;
  font-family: 'Cormorant Garamond', serif;
}
.page-link{
  display: contents;
}
.form-panel.edit-application{
  padding-top: 0px!important;
  max-width: 100%;;
}
.form-panel.edit-application .form-widget.login-widget{
  width: 100%;
  max-width: 100%;;
}
.form-panel.edit-application .form-section{
  padding-bottom: 0px;
}
.form-panel.edit-application .workarea{
  min-height: 500px;
}
.btn-group{
  padding: 0px;
  margin: 0px;
  display: block;
}
.btn-group li{
  display: inline-block;
  list-style: none;
  width: 46%;
  margin-left: 4%;
}
.btn-group .btn-default{
  background: #004F6B;
box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
border-radius: 3px;
}
.modal-body.profile{
  padding: 25px;
  background: #edf2f1;
}
.modal-header .head {
   font-size: 24px;
   font-family: 'Cormorant Garamond', serif;
   font-weight: normal;
 
}
.profile .heading-wrap{
  font-size: 18px;
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
}
.profile .profile-widget{
  padding-top: 0px;
}
.profile .profile-widget .info-wrap{
  width: 330px;
}
.profile .profile-widget .heading-wrap.profile-heading{
  font-size: 20px;
  font-weight: 700;
}
.profile .table{
  margin-bottom: 10px;

}
.profile .table td{
  padding:3px 5px;
  border-top:0px solid #dee2e6;
  font-size: 13px;
  height: auto;
}
/* .profile .table-striped tbody tr:nth-of-type(odd) {
  background-color: #eed0c9;
} */
.profile .table-striped tbody tr:nth-of-type(even) {
  background:none;
}
.profile .contact-info{
  font-size: 14px;
}
.profile .contact-info a{
  color:#000;
}
.profile .contact-info a:hover{
  text-decoration: underline;
}
.profile .btn-close{
  border: 0px;
  background: none;
  color:#004F6B;
  padding: 10px;

}
.form-widget .upload-btn-wrapper.statement-btn{
  width: 100%;

}
.form-widget .upload-btn-wrapper.idproof-btn{
  width: 50%;

}
.text-center.m-top{
  margin:25px 0px;
}
.stripe_payment{
  background: #fbfbfb;
  padding: 20px;
  padding-bottom:1px;
  margin-bottom: 30px;
  border-radius: 10px;
}
.stripe_payment .form-control{
  background: none;
}
.stripe_payment .btn.btn-submit{
  width: 100%!important;
  background: #004F6B;
box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
border-radius: 3px;
}
.stripe_payment .btn.btn-submit:hover{
  border: 1px solid #000;
  color:#000;
  background: none;
}
th a{
  color:#fff;
}
th a:hover{
  color: #fff;
}
.client-modal .modal-body{
  background: #fff;
}
.client-modal .head{
 font-size: 16px;
 font-family: 'Metropolis-Regular';
}
.client-modal .light-txt{
  color:#BEB7B3;
}
.client-modal .modal-dialog {
  max-width: 585px;
}
.client-modal .ph-no{
  margin-left: 20px;
}
.client-modal .profile{
  padding: 35px;
}
.client-modal .modal-header {
   padding: 1rem 35px;
}
.client-modal .upload-btn-wrapper input[type=file] {
  font-size: 20px;
  position: absolute!important;
  left: 0;
  top: 0;
  opacity: 0;
}
.client-modal .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.client-modal .upload-btn-wrapper.statement-btn{
  width: 100%;
}
.client-modal .upload-btn-wrapper .btn{
  width: 100%;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(153, 153, 153, 0.2);
  border-radius: 3px;
  padding: 10px 25px;

}

.editorSidebar{width:65px}
.editorSidebar .sidebar-nav {
  padding: 30px 10px;
  margin: 0px;
}
.editorSidebar .sidebar-nav li a {
  padding: 10px;  
}
.editor-content{
  margin-left: 65px;
}
.form-panel .form-widget.final-widget .payment-dialog{
  cursor: pointer;
}


.form-panel .form-widget .pagination li .page-link.active{
 background:none;
 color:#004F6B;
}

div#gov_issued_id_cont:hover {
    background: #004f6b;
    height: 40px;
    border-radius: 4px;
}
div#gov_issued_id_cont {
   
    height: 40px;
   
}


div#gov_issued_id_cont:hover > .btn-small {
  background:transparent !important;color:#fff;
}
#bank_file_upload_container .btn.btn-small.delete,
#additional_file_upload_container .btn.btn-small.delete{
  padding: 0px;
  border: 0px;
  height: 0;
  width: auto;
  box-shadow: none;
  margin-top:12px;
  height: 20px;
}
#bank_file_upload_container .btn.btn-small.delete:hover,
#additional_file_upload_container .btn.btn-small.delete:hover{
  color:#000;
  background: none;
}
#bank_file_upload_container span.small,
#additional_file_upload_container span.small{
  margin-top:11px;
}
#additional_file_upload_container .row{
  margin: 0px;
  

}
#additional_file_upload_container .btn.btn-small {
   padding: 10px 0px;
   margin: 5px;
   box-shadow: 0px 2px 2px rgba(0, 79, 107, 0.5);
}
#bank_file_upload_container .btn.btn-small i,
#additional_file_upload_container .btn.btn-small i{
  margin: 0px;
}
.form-panel .form-widget .form-control.text-area .form-check-label .box{
  float: left;
  width: 22px;
  height: 22px;
}
.form-panel .form-widget .form-control.text-area .form-check {
  padding-left: 0px;
}
.form-panel .form-widget .form-control.text-area .form-check-label .box.blank{
  background: url(/blank-rec.png) no-repeat;
}
.form-panel .form-widget .form-control.text-area .form-check-label .box.fill{
  background: url(/fill-rec.png) no-repeat;
}
.form-panel .form-widget .form-control.text-area .info-txt{
  margin-left: 10px;
}

.form-check-label .box{
  float: left;
  width: 22px;
  height: 22px;
}
.form-check {
  padding-left: 0px;
}
.form-check-label .box.blank{
  background: url(/blank-rec.png) no-repeat;
}
.form-check-label .box.fill{
  background: url(/fill-rec.png) no-repeat;
}
.info-txt{
  margin-left: 10px;
}
.webcam-wrap{ 
  background: #A4A4A4;
  padding: 15px;
  height: 310px;
  color:#000;
  line-height: 14px;
  font-size: 11px;
  margin-bottom: 12px;
}
.webcam-wrap video {
  margin-top: 10px;
  border: 3px solid #F26C52;
  box-sizing: border-box;
  border-radius: 8px;
  background: #F26C52;
  height: 158px;
  min-width: 276px;
}

.form-widget .btn.btn-small.captrue-btn{
  background: url(/click.png) no-repeat;
  width: 50px;
  height: 50px;
  border: 0px;
  box-shadow: none;
  margin-top: 10px;
  text-indent: -999999px;
 border-radius: 50%;
 position: relative;
 left:50%;
 margin-left: -25px;

}
.form-widget .btn.btn-small.captrue-btn:hover{background: url(/click-hover.png) no-repeat;}
.webcam-wrap + img{
border-radius: 5px;
}
.react-date-picker__wrapper {
  display: flex;
  border: 0px!important;
    border-bottom: 1px solid #999!important;
    width: 278px;
}
.react-date-picker__wrapper:hover{
  border-bottom: 2px solid #004F6B;
}

.react-calendar__tile--active, .react-calendar__tile--hasActive {
  background: #004F6B!important;
  color: white;
}

.react-date-picker__wrapper{font-family: 'Muli', sans-serif!important; font-size: 12px;}
.react-calendar{font-family: 'Muli', sans-serif!important; font-size: 12px; max-width:250px;}
.react-date-picker__calendar-button.react-date-picker__button,
.react-date-picker__clear-button.react-date-picker__button{
  display:none;
}


.compnent h4{
  margin-top: 30px;
  font-style: normal;
font-weight: 800;
line-height: normal;
font-size: 18px;
}
.compnent .content{
  margin-left: 0px;
}
.compnent .content .work-section .dashboard .panel-body{
  min-height: auto;
}
.compnent .form-panel{
  max-width: 100%;
  margin-bottom: 60px;
}
th, td{
  position: relative;
}
.table-collapse{
  position: absolute;
  top:60px;
  left:-60px;
  z-index: 9;
  min-width: 300px;
}
.table-collapse.status-collapse{
  min-width: 212px;
  top:71px;
  left:0px;
}
.table-collapse.link-collapse{
  min-width: 300px;
  top: 40px;
  left: -220px;
}

.icon-wrap{
  width: 20px;
  height: 15px;
  margin-left: 6px;
  display: inline-block;
}
.icon-wrap.search{
  background: url(/search.png) no-repeat;
} 

.icon-wrap.search:hover,
.icon-wrap.search:focus,
.icon-wrap.search.active{
  background: url(/search-hover.png) no-repeat;
} 


.icon-wrap.status{
  background: url(/status.png) no-repeat bottom;
}
.icon-wrap.status:hover,
.icon-wrap.status:focus,
.icon-wrap.status.active{
  background: url(/status-hover.png) no-repeat bottom;
} 
.icon-wrap.link{
  background: url(/link.png) no-repeat bottom;
  margin-left: 0px;
}
.icon-wrap.link:hover,
.icon-wrap.link:focus,
.icon-wrap.link.active{
  background: url(/link-hover.png) no-repeat bottom;
} 
.box{
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 22px;
  z-index: 9999999 !important;
  color:#333;
  font-size: 12px;
}
.box ul{
  padding: 10px 0 0 0;
  margin-bottom: 0px;

}
.box ul li{
  list-style: none;
}
.box ul li a{
  color:#333;
  display: block;
  text-decoration: none;
  padding:2px 12px;
  border-radius: 3px;

}
.box ul li a span{
  color:#004F6B;
  font-size: 11px;
}
.box ul li a span {
  width: 35px;
}
.box ul li a:hover{
  background: #fcdad4;
}
.box input[type=checkbox] + label {
  display: block;
  cursor: pointer;
  font-size: 14px;
}

.box input[type=checkbox] {
  display: none;
}

.box input[type=checkbox] + label:before {
  content: "";
  border: 0.1em solid #999;
  display: inline-block;
  width: 24px;
  height: 24px;
  padding-left: 2px;
  padding-top:2px;
  margin-right: 13px;
  vertical-align: bottom;
  color: transparent;
  transition: .2s;
}

.box input[type=checkbox] + label:active:before {
  -webkit-transform: scale(0);
          transform: scale(0);
}

.box input[type=checkbox]:checked + label:before {
  content: url(/check.png);
}

.search-input{
  background: url(/blk-search.png) no-repeat;
  padding-left: 25px;
  padding-bottom: 4px;
  border: 0px;
  border-bottom: 1px solid #999;
  width: 100%;
  padding-right: 72px;
}
.form-panel.edit-application .workarea ul.editing-menu{
  float: left;
  width: 80px;
  margin: 0px;
  position: relative;
  margin-left: -20px;
  padding: 0px;
}
.form-panel.edit-application .workarea ul.editing-menu li{
  list-style: none;
  margin-bottom: 12px;
}
.form-panel.edit-application .workarea .editing-area{
  background: #c4c4c4;
  min-height: 674px;
  margin-bottom: 30px;
  margin-left: 70px;
}
.form-panel.edit-application .form-section {
  padding: 20px 56px;
  padding-bottom: 0px;
}
.btn.editor-btn{
  width: 180px!important;
  margin-left: 10px
  
}
.btn {
  border-radius: 3px;
  padding: 10px 20px;
}
.btn.btn-default {
  min-width: 120px;
}
.btn.signature-btn{
  width: 265px;
}
.table-component{
  height: 400px!important;
  padding: 10px;
  position: relative;
}
.table-component h2{
  color:#fff;

}
.loader-section{
  width: 60px;
  height: 60px;
  margin: 10px auto;
}
.loader-section svg{
  width: 60px;
  height: 60px;;
}
.form-check-label .box{
  padding:0px;
  box-shadow:none;
}
.signaturebox{
  font-family: 'Caveat', cursive;
  font-size: 34px;
  border:2px #d2d2d2 dotted;
  text-align:center;
  display: block;
  margin: 5px 0;
  padding:15px 5px;

}
.sign-box{
  font-family: 'Caveat', cursive;
  font-size: 28px;
}
tr a {
  display: contents;
}
tr a:hover{
  color:#000;
  text-decoration: none;

}
.apc-section .form-control{
  width: auto;
  display: inline;
}
.rental-application .form-control,
.form-section .form-control {
  border-radius: 0px;
 height: 34px;
  padding: 0px 5px;
  outline: none;
}
.rental-application,
.recipt {
  padding: 20px 0;
  background: #fff;
}
.rental-application{
  padding: 20px;
}
.recipt .table thead th,
.recipt .table td {
  vertical-align: top;
  border-bottom: 1px solid #000;
  background: #fff;
  color: #000;
  padding: 5px 10px;
  font-size: 12px;
}
.recipt .table thead th{
  vertical-align: top;
  border-top:0px;
}
.recipt .table thead th strong{
  font-size: 14px;
}
.recipt  .table tbody+tbody{
  border-top:0;
}
.rental-application label{
  font-size: 14px;
}
.rental-application .form-control{
  border:0px;
  border-bottom: 1px solid #999;
}
.rental-application .form-control:focus{
  border-bottom: 2px solid #004F6B;
}
@media (max-width:767px){
  .form-panel .form-widget .form-section {
    padding: 20px 20px;
}
.new_logo {
  text-align: center;
  margin-bottom: 0;
  padding: 0;
  margin-top: 35px;
}
.logo{
  width: 200px;
}

.form-panel .form-widget .message {
  padding: 0px 20px;
}
.navbar-nav{
  margin: 0px;
}
.navbar {
  padding: 0.4rem 0rem;
  padding: 0;
  margin-top: 0px;
}
.navbar-nav li{
  text-align: right;
  margin-left: 0px;
  margin-right: 0px;
  padding: 8px 15px;
  background: #B8CBC8;
}
header {
  height: 54px;
}
.navbar-nav li.visible-xs-block{
  display: block!important;
  background: #fff!important;
}
.hidden-xs-block{
  display:none!important;
}
.btn-row .pull-left,
.btn-row .pull-right{
  float: inherit;
}
.btn-row .pull-right{
  margin-top: 15px;
}
.form-panel .form-widget .form-control.text-area {
  height: auto;
  overflow-y: inherit;
}
.webcam-wrap video{
height: 175px;
min-width: 306px;
 
}
.profile .profile-widget .info-wrap {
  width: 250px;
}
}
@media (max-width:400px){

  .webcam-wrap video{
    height: 155px;
    min-width: 100%;
     
    }
 
  }
@media (max-width:362px){

.form-panel .form-widget .pagination li.mid {
  width: 154px;
}
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Metropolis-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#eff5f6;
}

code {
  font-family: 'Metropolis-Regular';
}
.container{
  max-width:1100px;
  margin:0px auto;
  
}
header{ 
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 70px;
}
.landing-section{
  padding-top:200px;
  text-align:center;
  font-size:22px;
  font-weight: 600;
  max-width:670px;
  margin:0px auto;
}
.landing-section p{
  padding: 25px 0;
}
a{
  text-decoration:none;
}
.btn{
  border-radius:3px;
  padding:10px 35px;
  font-size: 12px;
  border:1px solid #231f20;
  height: 40px;
 
}
.btn.facebook{
  background:#3b5998 url(/facebook.png) no-repeat;
  background-position: 15px 10px;
  border-color:#3b5998;
  color:#fff;
}
.btn.facebook:hover{
  background:#27447f url(/facebook.png) no-repeat;
  background-position: 15px 10px;
}
.btn.google{
  background: #dc4e41 url(/google.png) no-repeat;
  background-position: 15px 10px;
  border-color:#dc4e41;
  color:#fff;
}
.btn.google:hover{
  background:#cb3528 url(/google.png) no-repeat;
  background-position: 15px 10px;
}
.form-control{
  height: 42px;
  font-size:12px;
  border:1px solid #e1e1e1;

}
.btn.btn-default{
  background: #004f6b;
  color:#fff;
}
/* .btn.btn-default:hover{
  background: #fff;
  color:#231f20;
} */
.btn.btn-border{
  background: #fff;
  color:#231f20;
}
.btn.btn-border:hover{
  background: #231f20;
  color:#fff;
}
.form-widget{
  background:#fff;
  border-radius:6px;
  box-shadow: 0px 5px 13px #e3e3e3;
  padding: 50px 50px 30px;
 
}
.form-widget.login-widget{
  max-width: 390px;
  margin:0px auto;
  min-height: 200px;
  font-size: 16px;
  margin-top:20px;

}
.table-striped tbody tr:nth-of-type(odd) {
  background-color:#edf2f1;
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0,0,0,.05);
}
.form-widget.login-widget .btn{
  width:100%;
}
.form-widget.login-widget .or-txt{
  font-weight:bold;
  padding-top:15px;
}
.form-widget.login-widget .small{
  font-size:12px;

}

.form-widget.login-widget .need-ac{
  border-top:1px solid #ececec;
  position:relative;
  margin:0px -50px;
  text-align: center;
  font-size: 12px;
  padding-top: 20px;
}
.form-widget.login-widget .small a,
.form-widget.login-widget .need-ac a{
  color:#dc4e41;
  text-decoration:underline;
}
.form-widget.login-widget .small a:hover,
.form-widget.login-widget .need-ac a:hover{
  text-decoration: none;
}
.form-widget.login-widget.register-widget{
  max-width: 630px;
}
.form-widget.login-widget.register-widget label{
  font-size:12px;
}
.form-widget.login-widget.register-widget .signature{
  border:1px solid #000;
  border-radius: 6px;
  padding: 40px 0;
  max-width: 310px;
  margin: 0px auto;
  text-align: center;
  color:#9c9c9c;
}
.form-widget.login-widget.register-widget .disclouser{
  border:1px solid #e1e1e1;
  height: 100px;
  padding: 15px;
  overflow-y: scroll;
  border-radius: 6px;
  font-size: 12px;
  color:#9c9c9c;
  text-align: justify;

}
.form-widget.login-widget.register-widget .btn{
  width: auto;
  padding: 8px 80px;
}
.switch {
  position: relative;
  height: 32px;
  width: 102px;
  margin: 0px auto;

  background:#fff;
  border-radius: 3px;
  border:1px solid #000!important;
 
}

.switch-label {
  position: relative;
  z-index: 2;
  float: left;
  width: 50px;
  line-height: 26px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  color: #000;
  padding-top: 2px;
}
.switch-label:active {
  font-weight: bold;
}

.switch-label-off {
  padding-left: 0px;
}

.switch-label-on {
  padding-right: 0px;
}


.switch-input {
  display: none;
}
.switch-input:checked + .switch-label {
  font-weight: bold;
  color:#fff;
  transition: 0.15s ease-out;
  transition-property: color, text-shadow;
}
.switch-input:checked + .switch-label-on ~ .switch-selection {
  left: 50px;
  /* Note: left: 50%; doesn't transition in WebKit */
}

.switch-selection {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  display: block;
  width: 50px;
  height: 30px;
  border-radius: 3px;
  background-color: #000;
  transition: left 0.15s ease-out;
  color:#fff;
}

main{
  padding:40px 0;
}
h2{
  font-size: 24px;
  font-family: 'Cormorant Garamond', serif;

}
.navbar {
  padding: 0.4rem 1rem;
}

.navbar-nav li{
  margin-left:20px
}
.navbar-nav li a{
  color:#000000;
  font-size: 18px;
  font-weight: 600;
}
.navbar-nav li.active a{
  text-decoration: underline;
}

.calendar{
  background: url('/calendar.png') no-repeat right;
  
 }
 .navbar-nav li img.user{
  margin-right: 8px;
 }
 .navbar-nav .dropdown-menu {
    position: absolute;
    top: 44px;
    width: 200px;
    right: 0px;
 }
 .navbar-nav .dropdown{
   margin-left: 30px;

 }
 .navbar-nav .dropdown a:hover{
   text-decoration: none;
 }
 .navbar-nav .dropdown-menu{
   left:inherit;
   padding: 10px 20px;
 }
 .navbar-nav .dropdown-menu li{
   display: block!important;
   margin-left: 0px;
 }
 .navbar-nav .dropdown-menu li a{
   font-size: 11px!important;
 }
 .container.dashboard{
   max-width: 768px;
 }
 .dashboard h2{
   padding-top: 10px;
 }
 .dashboard .panel-body{
  background:#fff;
  border-radius:0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 12px 0;
  
 }
 .dashboard .table{
   margin-bottom: 0px;
 }
 .dashboard .table td, .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  line-height: 20px;
   font-weight: normal;
   font-size: 16px;
}
.dashboard .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  background: #004F6B;
  color:#fff;
  padding: 18px 35px;

}
.dashboard .table td{
  height: 71px;
  padding: 18px 35px;
}
.btn-row{
  margin-top: 5px;
}
.client-app{
  max-width: 780px;
}
.client-app .form-widget{
  max-width: 780px;
}
.client-app .form-widget label{
  font-size: 12px;
}
.client-app .form-widget .btn{
  padding: 6px 35px;
}
.form-widget .form-wrap{
  width: 280px;
}
.form-widget .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.form-widget .btn.btn-small{
  background: #fff;
  font-size:12px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(153, 153, 153, 0.2);
  border-radius: 3px;
  padding: 10px 25px;
}
.form-widget .btn.btn-small:hover{
  border:1px solid #004f6b;
  background:#004f6b;
  color:#fff;
}
.form-widget .btn.btn-small i{
  font-size: 18px;
  margin-left: 10px;
}
.client-app .uploadimg{
  width:60px;
  float:left;
  margin-right: 20px;
}
.mtop{
  margin-top: 15px;
}
.form-widget .upload-btn-wrapper input[type=file] {
  font-size: 20px;
  position: absolute!important;
  left: 0;
  top: 0;
  opacity: 0;
}

.profile-widget{
 position: relative;
 padding: 50px 0;
}
.profile-widget .table td,
.profile-widget .table th{
 padding: .75rem;
 vertical-align: top;
 border-bottom: 1px solid #dee2e6!important;
 border-top: 0!important;
}
.profile-widget .panel-body{
 position: relative;
 margin: 20px 0;
}
.profile-widget .edit{
 padding: 0 50px;
}

/* Added on 09 Dec 2018 by Sajjad */

.navbar-header{min-height: 42px} 
.logoPlaceholder{text-align: center; padding: 25px 0px}
.backTo{padding: 15px 0px 0px 0px}


.form-panel{
  max-width:375px;
  margin:0px auto;
  margin-bottom:20px;
}
.form-panel h2{
  font-family: 'Cormorant Garamond', serif;
  font-size:24px;
  margin-top:10px;
}

.form-panel .form-widget .message{
  background:#004F6B;
  width:100%;
  height:120px;
  color:#fff;
  padding:0px 35px;
  font-size:16px;
  display: flex;
  align-items : center;
  width: 100%;
  justify-content : left;
  line-height:20px;
}
.form-panel .form-widget .form-section{
  padding: 20px 35px;
  min-height:280px;
}
.form-panel .form-widget .form-section label{
  font-size:14px;
  margin-bottom:0px;
}
.form-panel .form-widget .form-section .form-control{
  border:0px;
  border-radius:0px;
  border-bottom:1px solid #999;
  height:28px;
  padding:0px;
  outline: none;
}
.form-panel .form-widget .form-section .form-group.error-msg .form-control{
  border-bottom:1px solid #f44336;
  
}
.form-panel .form-widget .form-section .form-group.error-msg p{
  color:#f44336;
  font-size:10px;
}

.form-control:focus {
    outline:none;
}
.form-panel .form-widget{
  position:relative
}
.form-panel .form-widget .pagination{
  margin-bottom:0px;
  position:absolute;
  bottom:15px;
}
.form-panel .form-widget .pagination li{
  display:inline-block;
  border:0px;
}
.form-panel .form-widget .pagination li button{
  color:#BEB7B3;
  font-weight:800;
  font-size:14px;
  padding:0px;
  border:0px;
  border: 0px;
  background: none;
  padding: 0px;
  height: 26px;
}
.form-panel .form-widget .pagination li button:hover{
  background:none;
  color:#004F6B;
}
.form-panel .form-widget .pagination li .page-link.active{
  background:none;
  color:#004F6B;
}

.form-panel .form-widget .pagination li.left,
.form-panel .form-widget .pagination li.right{
  width:50px;
}
.form-panel .form-widget .pagination li.left{
  text-align: left;
}
.form-panel .form-widget .pagination li.right{
  text-align: right;
}
.form-panel .form-widget .btn-wrap{
  max-width:196px;
  margin:0px auto;
  text-align:center;
}
.form-panel .form-widget .btn-wrap .btn{
  margin-bottom:11px; 
  background: #FFFFFF;
 
  border-radius: 3px;
  font-size:12px;
  height:40px;
  padding:10px;
  color:#000;
  border: 1px solid #000000;
box-sizing: border-box;
box-shadow: 0px 2px 2px rgba(0, 79, 107, 0.5);
border-radius: 3px;
}
.form-panel .form-widget .btn-wrap .btn:hover{
  background:#004F6B;
  color:#fff;
  border:1px solid #004F6B;
}

.form-panel .form-widget .btn-wrap .btn.btn-secondary{
  background:#004F6B;
  color:#fff;
  border:1px solid #004F6B;
}
.form-panel .form-widget .btn-wrap .btn.btn-secondary:hover{
  color:#000;
  border:1px solid #bfbfbf;
  background:#fff;
}
.form-panel .form-widget .form-control.text-area{
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height:280px;
  margin-bottom: 15px;
  border-bottom:0px;
  padding:15px;
  overflow-y: scroll;
}
.form-panel .form-widget .form-control.text-area ul{
  padding-left: 30px;
}
.form-panel .form-widget .form-control.text-area h4{
  font-size:13px;
  font-weight: 800;
} 
.form-panel .form-widget .btn.signature-btn{
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.20), 0px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  font-size:12px;
  height:60px;
  padding:20px 15px;
  color:#000;
  border:1px solid #bfbfbf;
  background:#edf2f1;
}
.form-panel .form-widget .btn.btn-submit{
  width:120px;
}
.form-panel .form-widget .btn.signature-btn:hover{
  background:#004F6B;
  color:#fff;
  border:1px solid #004F6B;
}
.form-panel .form-widget .pagination li i{
  font-size:16px;
}
.form-panel .form-widget .pagination li.mid{
  width:208px;
  text-align:center;
  font-size:11px;
  color:#BEB7B3;
}
.form-panel .form-widget .pagination li.mid a{
  font-size:11px;
  font-weight:400;
}
.form-panel .progress-widget{
  max-width:375px;
  margin:0px auto;
  background:#F26C52;
  background:rgba(242,108,82, .3);
  -ms-background:rgba(242,108,82, .3);
  -webkit-background:rgba(242,108,82, .3);
  position:relative;
  height:5px;
}
.form-panel .progress-widget .bar{
  background:#F26C52;
  position:absolute;
  top:0px;
  left:0px;
  height:5px;
  z-index:99;
}
.form-panel .progress-widget .bar.step1{
  width:10%;
}
.form-panel .progress-widget .bar.step2{
  width:20%;
}
.form-panel .progress-widget .bar.step3{
  width:30%;
}
.form-panel .progress-widget .bar.step4{
  width:40%;
}
.form-panel .progress-widget .bar.step5{
  width:50%;
}
.form-panel .progress-widget .bar.step6{
  width:60%;
}
.form-panel .progress-widget .bar.step7{
  width:70%;
}
.form-panel .progress-widget .bar.step8{
  width:80%;
}
.form-panel .progress-widget .bar.step9{
  width:90%;
}
.form-panel .progress-widget .bar.step10{
  width:100%;
}

.form-panel .form-widget{
  background:#fff;
  border-radius:0px;
  box-shadow: 0px 5px 13px #e3e3e3;
  padding: 00px 00px 30px;
 
}
.form-panel .form-widget.login-widget{
  max-width: 375px;
  margin:0px auto;
  font-size: 16px;
  margin-top:20px;
}

.form-panel .form-widget .btn-emp{
  background: #004F6B;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  width: 196px;
  color:#fff;
  border:1px solid #004f6b;
}
.form-panel .form-widget .btn-emp:hover{
  background: #fff;
  border:1px solid #000;
  color:#000;
}
.form-panel .form-widget .sign-in-block{
  max-width: 100%;
}
.form-panel .form-widget.final-widget .message{
  font-size: 14px;
}
.form-panel .form-widget.final-widget .message p.small{
  font-size: 9px;
  line-height: 10px;
  margin: 0px;
}
.form-panel .form-widget.final-widget .payment-dialog{
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  font-size: 10px;
  padding: 42px;
  margin-bottom: 11px;
  cursor: pointer;
}
.form-panel .form-widget.final-widget .btn.signature-btn{
  background: #efedec;
   border: 1px solid #bfbfbf;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none;
  color: #000;
}
.form-panel .form-widget.final-widget .btn-wrap .btn-submit{
  width: 100%;

}
.form-panel .form-widget.edit-profile .message{
  font-size: 18px;
}
.form-panel .form-widget.edit-profile .message i{
  color:#000;
  margin-left: 15px;
}

.form-panel .form-widget.edit-profile .message i:hover{
  color:#F26C52;
  margin-left: 15px;
}

.form-panel .form-widget.edit-profile .info-wrap{
  width: 170px;
}
.form-panel .form-widget.edit-profile .info-wrap p{
  margin-bottom: 5px;
}
.form-panel .form-widget.edit-profile .info-wrap .ph-no span{
  background: #bfd3da;
  border-radius: 2px;
  font-size: 16px;
  padding: 0px 4px;
}
.form-panel .form-widget.edit-profile .info-wrap i{
  margin-left:5px;
  font-size: 16px;
}
.form-panel .form-widget.edit-profile .info-wrap i:hover{
  color:#F26C52;

}
.form-panel .form-widget.edit-profile .heading-wrap{
   font-size: 18px;
   border-bottom: 1px solid #000;
   margin-bottom: 10px;
}
.form-panel .form-widget.edit-profile .img-wrap{
  position: relative;
  width: 130px;
  margin: 0px auto;
}
.form-panel .form-widget.edit-profile .img-wrap .edit{
  position: absolute;
  top:0px;
  right: 0px;
  font-size: 16px;
  display: block;
  color:#333;
}
.form-panel .form-widget.edit-profile .img-wrap .edit input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 10px;
}
.form-panel .form-widget.edit-profile .btn{
  background: none;
  border: 0px;
  color:#ff0000;
  text-decoration: underline;


}
.form-panel .form-widget.edit-profile .agent-wrap{
  margin-top: 20px;
}

.form-panel .form-widget.edit-profile .agent-wrap .btn{
  background: #004F6B;
box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
border-radius: 3px;
  color:#fff;
  width: 150px;
  text-decoration: none;
}
.form-panel .form-widget.edit-profile .info-wrap i{
  color:#333;
}
.form-panel .form-widget.edit-profile .agent-wrap .info-wrap{
  margin-top: 15px;
}
.modal-header{
  background: #004F6B;
}
.modal-header .modal-title{
  line-height: 22px;
  font-size: 16px;
  font-family: 'Metropolis-Regular';
  color:#fff;
}
.modal-dialog {
  margin:8rem auto;
}
.modal-header,
.modal-content{
  border-radius: 0px;
}
.modal-content {
  box-shadow: 0px 5px 13px #e3e3e3;
  border: 0px;
}

.modal-body p{
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 14px;
}
.modal-body .small{
  margin-bottom: 3px!important;
  font-size: 12px!important;  
}
.modal-body .number{
  padding-left: 15px;
}
.full-wth-btn{
  width: 100%;
  color:#000;
}
.btn:hover{
  background: #004F6B;
  color:#fff;
}
tr a{
  color:#000;
  text-decoration: none;
}
.modal-backdrop {
  background-color: #fff;
  opacity: 1!important;
}
.btn.btn-close{
  background: #fff;
  color: #004F6B;
  font-size: 14px;
  font-weight: 700;
  border: 0px;
  padding: 0px 20px;
  height: 20px;
}
.btn.btn-close img{
  padding-left: 6px;
}
.close-btn-wrap {
  top:-20px;
  position: relative;
}



#myVideo{
    position: fixed;
    min-width: 100%; 
    min-height: 100%;
}
.landing-content{
    position: fixed;
    color: #f1f1f1;
    min-width: 100%; 
    min-height: 100%;
}
.btn.disabled, .btn:disabled {
  opacity: .65;
  cursor: not-allowed;
}

.inline-error-class{
  color: #ff0000;
  font-size:11px;
}

.btn.disabled, .btn:disabled {
  cursor: not-allowed;
  background: #ececec !important;
  color: #888 !important;
  border: 1px solid #ececec;
}
input[type="text"], textarea {
  outline: none;
  box-shadow:none !important;
 }
 .close {

  color: #fff;
  text-shadow:none;
  opacity: .5;
}
.navbar-nav.mr-auto, .navbar-nav.mx-auto {
  margin-right: inherit!important;
}
.navbar-nav.mr-auto, .navbar-nav.mx-auto {
  margin-left: auto!important;
}
.logo{
  width: 240px;
  margin-left: 15px;
}
.navbar-dark .navbar-toggler{
  margin-right: 2px;
}
.navbar{
  margin-top: -4px;
  z-index: 1!important;
}
.navbar-nav li {
  line-height: 39px;
}
.navbar-dark .navbar-toggler{
  background: #fff;
}
.bg-light{
  background: #fff!important;
}
.visible-xs-block{
  display: none!important;
}
.hidden-xs-block{
  display:block!important;
}
.form-panel .form-widget .form-control.text-area .form-check label{
  font-size: 12px;
  margin-bottom: 5px;
}
.form-panel .form-widget .form-control.text-area  .form-check{
  line-height: 24px;
}


.container-rd {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;

  -webkit-user-select: none;
  user-select: none;
  height: 40px;
  margin-bottom: 15px!important;
  background: #f1f1f1;
box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
border-radius: 3px;
color: #fff;
font-size: 12px;
}

/* Hide the browser's default radio button */
.container-rd input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.container-rd .txt{
  position: absolute;
  left: 0px;
  top:0px;
  color:#000;
  z-index: 99;
  text-align: center;
  width: 100%;
  top:12px;
  font-size: 12px;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  background: #f1f1f1;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
}
/* On mouse-over, add a grey background color */
.container-rd:hover input ~ .checkmark {
  background: #f1f1f1;
box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
border-radius: 3px;
color: #000;
font-size: 12px;
}

/* When the radio button is checked, add a blue background */
.container-rd input:hover ~ .checkmark ,
.container-rd input:checked ~ .checkmark {
  background-color: #004F6B;
  border:1px solid #000;
}

.container-rd input:hover ~ .txt,
.container-rd input:checked ~ .txt {
  color:#fff
}
.duration-widget{
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
  border-radius: 2px;
  background: #efedec;
  text-align: center;
  width: 146px;
  height: 38px;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 12px;

}
.duration-widget li{
  display: inline-block;
  list-style: none;
  font-size: 18px;
  padding: 3px;

}
.duration-widget li a{
  text-decoration: none;
  color:#000;
  font-weight: 600;
  padding:3px 5px;
}
.duration-widget li a:focus,
.duration-widget li.active a,
.duration-widget li a:hover{
  background: #004F6B;
  border-radius: 4px;
  color:#fff;
}
.duration-widget {
  padding-left: 8px;
}
.duration-widget .container-rd{
  padding-left: 0px;
  width: 35px;
  height: 33px;
  float: left;
  margin: 0px 4px;
  background: none;
  box-shadow: none;
}
.duration-widget .container-rd .txt {
 top: 4px;
  font-size: 18px;
  font-weight: 900;

}
.duration-widget .checkmark {
  height: 32px;
  box-shadow: none;
  background: none;
  top:2px;
  border:0px;
}
.duration-widget .container-rd input:hover ~ .checkmark, 
.duration-widget .container-rd input:checked ~ .checkmark{
  border:0px;
  box-shadow: none;
}
.form-panel .form-widget .form-section .form-control:focus{
  border-bottom: 2px solid #004F6B;
}

/* START DASHBOARD DESIGN*/

.sidebar{
  float: left;
  width: 235px;
  background: #004F6B;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  height: 100%;
  position:relative;
  top:0px;
  z-index: 999;
  float: left;

}
.sidebar, .content{
  height: 100vh;
  position: relative;
  margin-top:-36px;
  margin-bottom:-60px;
}
.sidebar .sidebar-nav{
  padding: 30px 22px;
  margin: 0px;


}
.sidebar .sidebar-nav li{
  list-style: none;
  display: block;
  margin-bottom:6px;
}
.sidebar .sidebar-nav li a{
  color:#fff;
  font-size: 18px;
  font-weight: 800;
  padding:10px;
  text-decoration: none;
  display: block;
}
.sidebar .sidebar-nav li.active a,
.sidebar .sidebar-nav li a:hover,
.sidebar .sidebar-nav li a:focus{
  color:#fff;
  background: #27667d;
  border-radius: 5px;
  
}

.content{
  margin-left:235px;  
}
.content .work-section{
  padding: 0px 50px;
}
.content .work-section .dashboard h2{
  font-size: 32px;
  padding-top: 0px;
}
.content .work-section .dashboard .table td{
  height: auto;
  padding: 12px 35px;
  
}
.content .work-section .dashboard .table th,
.content .work-section .dashboard .table td{
  font-size: 14px;
}
.content .work-section .dashboard .panel-body{
  min-height: 700px;
}
.content .work-section .dashboard .table td{
   border-top: 0px solid #dee2e6;
}
.content .work-section .dashboard .table-striped tbody tr:nth-of-type(even){
  background:#dedbd9;
}
.content .work-section .form-panel{
  padding-top: 150px;
}

.content .work-section .container-rd .txt{
  position: absolute;
  left: 0px;
  top:0px;
  color:#000;
  z-index: 99;
  text-align: center;
  width: 100%;
  top:12px;
  font-size: 12px;
}

/* On mouse-over, add a grey background color */
.content .work-section .container-rd input ~ .checkmark {
background: #f1f1f1;
box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
border-radius: 3px;
font-size: 12px;
}

/* When the radio button is checked, add a blue background */
.content .work-section .container-rd input:hover ~ .checkmark ,
.content .work-section .container-rd input:checked ~ .checkmark {
  background-color: #004F6B;
  border:1px solid #004F6B;
}

.content .work-section .container-rd input:hover ~ .txt,
.content .work-section .container-rd input:checked ~ .txt {
  color:#fff;
}
.auto-search-widget{
  padding: 0;
  margin: 0px;
}
.auto-search-widget li{
  list-style: none;  
}
.search-widget form{
  padding: 0px 30px;
}
.search{
  background: url(/blk-search.png) no-repeat left;
  padding-left: 25px!important;
}
.auto-search-widget li a{
  display: block;
  padding: 3px 10px;
  border-radius: 3px;
  text-decoration: none;
  color:#000;
}
.auto-search-widget li a:hover{
  background: #004F6B;  
  color:#fff;
}
.navbar .header-tag{
  font-size: 36px;
  font-family: 'Cormorant Garamond', serif;
}
.page-link{
  display: contents;
}
.form-panel.edit-application{
  padding-top: 0px!important;
  max-width: 100%;;
}
.form-panel.edit-application .form-widget.login-widget{
  width: 100%;
  max-width: 100%;;
}
.form-panel.edit-application .form-section{
  padding-bottom: 0px;
}
.form-panel.edit-application .workarea{
  min-height: 500px;
}
.btn-group{
  padding: 0px;
  margin: 0px;
  display: block;
}
.btn-group li{
  display: inline-block;
  list-style: none;
  width: 46%;
  margin-left: 4%;
}
.btn-group .btn-default{
  background: #004F6B;
box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
border-radius: 3px;
}
.modal-body.profile{
  padding: 25px;
  background: #edf2f1;
}
.modal-header .head {
   font-size: 24px;
   font-family: 'Cormorant Garamond', serif;
   font-weight: normal;
 
}
.profile .heading-wrap{
  font-size: 18px;
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
}
.profile .profile-widget{
  padding-top: 0px;
}
.profile .profile-widget .info-wrap{
  width: 330px;
}
.profile .profile-widget .heading-wrap.profile-heading{
  font-size: 20px;
  font-weight: 700;
  
}
.profile .table{
  margin-bottom: 10px;

}
.profile .table td{
  padding:3px 5px;
  border-top:0px solid #dee2e6;
  font-size: 13px;
  height: auto;
}
/* .profile .table-striped tbody tr:nth-of-type(odd) {
  background-color: #eed0c9;
} */
.profile .table-striped tbody tr:nth-of-type(even) {
  background:none;
}
.profile .contact-info{
  font-size: 14px;
}
.profile .contact-info a{
  color:#000;
}
.profile .contact-info a:hover{
  text-decoration: underline;
}
.profile .btn-close{
  border: 0px;
  background: none;
  color:#004F6B;
  padding: 10px;

}
.form-widget .upload-btn-wrapper.statement-btn{
  width: 100%;

}
.text-center.m-top{
  margin:25px 0px;
}
.stripe_payment{
  background: #fbfbfb;
  padding: 20px;
  padding-bottom:1px;
  margin-bottom: 30px;
  border-radius: 10px;
}
.stripe_payment .form-control{
  background: none;
}
.stripe_payment .btn.btn-submit{
  width: 100%!important;
  background: #004F6B;
box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
border-radius: 3px;
}
.stripe_payment .btn.btn-submit:hover{
  border: 1px solid #000;
  color:#000;
  background: none;
}
th a{
  color:#fff;
}
th a:hover{
  color: #fff;
}
.client-modal .modal-body{
  background: #fff;
}
.client-modal .head{
 font-size: 16px;
 font-family: 'Metropolis-Regular';
}
.client-modal .light-txt{
  color:#BEB7B3;
}
.client-modal .modal-dialog {
  max-width: 585px;
}
.client-modal .ph-no{
  margin-left: 20px;
}
.client-modal .profile{
  padding: 15px 30px 35px;
}
.client-modal .modal-header {
   padding: 1rem 35px;
}
.client-modal .upload-btn-wrapper input[type=file] {
  font-size: 20px;
  position: absolute!important;
  left: 0;
  top: 0;
  opacity: 0;
}
.client-modal .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.client-modal .upload-btn-wrapper.statement-btn{
  width: 100%;
}
.client-modal .upload-btn-wrapper .btn{
  width: 100%;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(153, 153, 153, 0.2);
  border-radius: 3px;
  padding: 10px 25px;

}
.client-modal .upload-btn-wrapper .btn:hover{
  background: #004F6B;
    color: #fff;
    border: 1px solid #004F6B;
}

.editorSidebar{width:65px}
.editorSidebar .sidebar-nav {
  padding: 30px 10px;
  margin: 0px;
}
.editorSidebar .sidebar-nav li a {
  padding: 10px;  
}
.editor-content{
  margin-left: 65px;
}
.form-panel .form-widget.final-widget .payment-dialog{
  cursor: pointer;
}


.form-panel .form-widget .pagination li .page-link.active{
 background:none;
 color:#004F6B;
}

div#gov_issued_id_cont:hover {
    background: #004f6b;
    height: 40px;
    border-radius: 4px;
}
div#gov_issued_id_cont {
   
    height: 40px;
   
}


div#gov_issued_id_cont:hover > .btn-small {
  background:transparent !important;color:#fff;
}
#bank_file_upload_container .btn.btn-small.delete,
#additional_file_upload_container .btn.btn-small.delete{
  padding: 0px;
  border: 0px;
  height: 0;
  width: auto;
  box-shadow: none;
  margin-top:12px;
  height: 20px;
}
#bank_file_upload_container .btn.btn-small.delete:hover,
#additional_file_upload_container .btn.btn-small.delete:hover{
  color:#000;
  background: none;
}
#bank_file_upload_container span.small,
#additional_file_upload_container span.small{
  margin-top:11px;
}
#additional_file_upload_container .row{
  margin: 0px;
  

}
#additional_file_upload_container .btn.btn-small {
   padding: 10px 0px;
   margin: 5px;
   box-shadow: 0px 2px 2px rgba(0, 79, 107, 0.5);
}
#bank_file_upload_container .btn.btn-small i,
#additional_file_upload_container .btn.btn-small i{
  margin: 0px;
}
.form-panel .form-widget .form-control.text-area .form-check-label .box{
  float: left;
  width: 22px;
  height: 22px;
}
.form-panel .form-widget .form-control.text-area .form-check {
  padding-left: 0px;
}
.form-panel .form-widget .form-control.text-area .form-check-label .box.blank{
  background: url(/blank-rec.png) no-repeat;
}
.form-panel .form-widget .form-control.text-area .form-check-label .box.fill{
  background: url(/fill-rec.png) no-repeat;
}
.form-panel .form-widget .form-control.text-area .info-txt{
  margin-left: 10px;
}

.form-check-label .box{
  float: left;
  width: 22px;
  height: 22px;
}
.form-check {
  padding-left: 0px;
}
.form-check-label .box.blank{
  background: url(/blank-rec.png) no-repeat;
}
.form-check-label .box.fill{
  background: url(/fill-rec.png) no-repeat;
}
.info-txt{
  margin-left: 10px;
}
.webcam-wrap{ 
  background: #A4A4A4;
  padding: 15px;
  height: 310px;
  color:#000;
  line-height: 14px;
  font-size: 11px;
  margin-bottom: 12px;
}
.webcam-wrap video {
  margin-top: 10px;
  border: 3px solid #F26C52;
  box-sizing: border-box;
  border-radius: 8px;
  background: #F26C52;
  height: 158px;
  min-width: 276px;
}

.form-widget .btn.btn-small.captrue-btn{
  background: url(/click.png) no-repeat;
  width: 50px;
  height: 50px;
  border: 0px;
  box-shadow: none;
  margin-top: 10px;
  text-indent: -999999px;
 border-radius: 50%;
 position: relative;
 left:50%;
 margin-left: -25px;

}
.form-widget .btn.btn-small.captrue-btn:hover{background: url(/click-hover.png) no-repeat;}
.webcam-wrap + img{
border-radius: 5px;
}
.react-date-picker__wrapper {
  display: flex;
  border: 0px!important;
    border-bottom: 1px solid #999!important;
    width: 278px;
}
.react-date-picker__wrapper:hover{
  border-bottom: 2px solid #004F6B;
}

.react-calendar__tile--active, .react-calendar__tile--hasActive {
  background: #004F6B!important;
  color: white;
}

.react-date-picker__wrapper{font-family: 'Muli', sans-serif!important; font-size: 12px;}
.react-calendar{font-family: 'Muli', sans-serif!important; font-size: 12px; max-width:250px;}
.react-date-picker__calendar-button.react-date-picker__button,
.react-date-picker__clear-button.react-date-picker__button{
  display:none;
}
.react-date-picker__calendar{
  z-index: 999 !important
}

.compnent h4{
  margin-top: 30px;
  font-style: normal;
font-weight: 800;
line-height: normal;
font-size: 18px;
}
.compnent .content{
  margin-left: 0px;
}
.compnent .content .work-section .dashboard .panel-body{
  min-height: auto;
}
.compnent .form-panel{
  max-width: 100%;
  margin-bottom: 60px;
}
th, td{
  position: relative;
}
.table-collapse{
  position: absolute;
  top:60px;
  left:-60px;
  z-index: 9;
  min-width: 300px;
}
.table-collapse.status-collapse{
  min-width: 212px;
  top:71px;
  left:0px;
}
.table-collapse.link-collapse{
  min-width: 300px;
  top: 40px;
  left: -220px;
}

.icon-wrap{
  width: 20px;
  height: 15px;
  margin-left: 6px;
  display: inline-block;
}
.icon-wrap.search{
  background: url(/search.png) no-repeat;
} 
.icon-wrap.search:hover,
.icon-wrap.search:focus,
.icon-wrap.search.active{
  background: url(/search-hover.png) no-repeat;
} 

.icon-wrap.status{
  background: url(/status.png) no-repeat bottom;
}
.icon-wrap.status:hover,
.icon-wrap.status:focus,
.icon-wrap.status.active{
  background: url(/status-hover.png) no-repeat bottom;
} 
.icon-wrap.link{
  background: url(/link.png) no-repeat bottom;
  margin-left: 0px;
}
.icon-wrap.link:hover,
.icon-wrap.link:focus,
.icon-wrap.link.active{
  background: url(/link-hover.png) no-repeat bottom;
} 
.box{
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 22px;
  z-index: 99;
  color:#333;
  font-size: 12px;
}
.box ul{
  padding: 10px 0 0 0;
  margin-bottom: 0px;

}
.box ul li{
  list-style: none;
}
.box ul li a{
  color:#333;
  display: block;
  text-decoration: none;
  padding:2px 12px;
  border-radius: 3px;

}
.box ul li a span{
  color:#004F6B;
  font-size: 11px;
}
.box ul li a span {
  width: 35px;
}
.box ul li a:hover{
  background: #fcdad4;
}
.box input[type=checkbox] + label {
  display: block;
  cursor: pointer;
  font-size: 14px;
}

.box input[type=checkbox] {
  display: none;
}

.box input[type=checkbox] + label:before {
  content: "";
  border: 0.1em solid #999;
  display: inline-block;
  width: 24px;
  height: 24px;
  padding-left: 2px;
  padding-top:2px;
  margin-right: 13px;
  vertical-align: bottom;
  color: transparent;
  transition: .2s;
}

.box input[type=checkbox] + label:active:before {
  -webkit-transform: scale(0);
          transform: scale(0);
}

.box input[type=checkbox]:checked + label:before {
  content: url(/check.png);
}

.search-input{
  background: url(/blk-search.png) no-repeat;
  padding-left: 25px;
  padding-bottom: 4px;
  border: 0px;
  border-bottom: 1px solid #999;
  width: 100%;
}
.form-panel.edit-application .workarea ul.editing-menu{
  float: left;
  width: 80px;
  margin: 0px;
  position: relative;
  margin-left: -20px;
  padding: 0px;
}
.form-panel.edit-application .workarea ul.editing-menu li{
  list-style: none;
  margin-bottom: 12px;
}
.form-panel.edit-application .workarea .editing-area{
  background: #c4c4c4;
  min-height: 674px;
  margin-bottom: 30px;
  margin-left: 70px;
}
.form-panel.edit-application .form-section {
  padding: 20px 56px;
  padding-bottom: 0px;
}
.btn.editor-btn{
  width: 180px!important;
  margin-left: 10px
  
}
.btn {
  border-radius: 3px;
  padding: 10px 20px;
}
.btn.btn-default {
  min-width: 120px;
}
.btn.signature-btn{
  width: 265px;
}
.table-component{
  height: 400px!important;
  padding: 10px;
  position: relative;
}
.table-component h2{
  color:#fff;

}
.loader-section{
  width: 60px;
  height: 60px;
  margin: 10px auto;
}
.loader-section svg{
  width: 60px;
  height: 60px;;
}
.form-check-label .box{
  padding:0px;
  box-shadow:none;
}
.signaturebox{
  font-family: 'Caveat', cursive;
  font-size:28px;
  border:2px #d2d2d2 dotted;
  text-align:center;
  display: block;
  margin: 5px 0;
  padding:30px 5px;

}
.sign-box{
  font-family: 'Caveat', cursive;
  font-size:28px;
}
tr a {
  display: contents;
}
tr a:hover{
  color:#000;
  text-decoration: none;

}
.clientApp-grid th{
  min-width: 200px;
}
.styles_overlay__CLSq-{
  /*background: #fff;*/
  background: none;
}
.styles_modal__gNwvD{
  box-shadow: none;
}
.dashboard .table td {
  color:#000;

}
.dashboard .table td :hover{
  text-decoration: none;
}
.styles_closeButton__20ID4 {
  top: -8px;
}
.dashboard a{text-decoration: none}
.client-modal .profile {
  padding: 15px 30px 35px;
  min-height: 300px;
}
.modal-content {
  min-width: 600px;
}
.email-modal .modal-dialog{
  max-width: 400px;
}
.email-modal .modal-content{
  min-width: auto;
}
.email-modal .modal-body.profile{
  background: #fff;
}
.form-section .form-control{
  border:0px;
  border-radius:0px;
  border-bottom:1px solid #999;

}
.information{
  background: #f2f2f2;
  padding: 15px;
  font-size: 14px;
}
#additional_file_upload_container .row {

  margin: 0px -18px!important;
}
.alert {

  padding: 8px 15px;
   font-size: 14px;
   text-align: center;
}
.alert i{
  margin-right:5px;
}
#bank_file_upload_container .btn.btn-small.delete, 
#additional_file_upload_container .btn.btn-small.delete{
  height: 30px!important;
  padding: 6px!important;
  margin-top: 0px;
}
#bank_file_upload_container span.small, #additional_file_upload_container span.small {
  margin-top: 5px;
}
.form-widget .btn.btn-small.upload {
  letter-spacing: -.5px;
  height: 30px!important;
    padding: 6px!important;
}
.plaid-btn{
  margin-bottom: 11px;
    background: #FFFFFF;
    border-radius: 3px;
    font-size: 12px;
    height: 40px;
    padding: 10px;
    color: #000;
    border: 1px solid #000000!important;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 79, 107, 0.5);
    border-radius: 3px;
    width: 100%;
}
.plaid-btn:hover{
    background: #004F6B!important;
    color: #fff;
    border: 1px solid #004F6B!important;
}
.add-more-wrap{
  width: 120px;
  margin: 0px auto;
}

  .add-more-wrap .btn.add-btn {
   box-shadow: none!important;
    font-weight: 600!important;
    padding: 5px!important;
    height: 30px!important;
    border-color: #fff!important;
    color: #004F6B!important;
    background: none;
}
.add-more-wrap .btn.add-btn:hover{
   background: none!important;
}
.bSMPxA{
  font-size: 11px;
  text-align: center;
  min-width:250px;
}
.grid-warp{
  background: #fff;
  margin-top: 20px;
  padding: 20px 0;
  font-size: 13px;  
}
.grid-warp .head{
  font-weight: 600;
  text-transform: uppercase
  
}
.grid-warp .head span{
  font-weight: 800;
}
.grid-warp .col-sm-4{
  width: 33.33%;
  float: left;
}
.grid-warp .head,
.grid-warp .grid{
  border-bottom: 1px solid #000;
}
.grid-warp .grid .cell{
  padding:6px 0px;
}
#myAgent .modal-content,
.modal-sm .modal-content{
  min-width: 100%;
}



.modal-sm .info-wrap.pull-left{
  float:inherit;
}
.modal-sm .info-wrap{
  width: 120px;
  height: 120px;
  margin: 20px auto;
  border-radius: 50%;
  position: relative;

}
.modal-sm .img-wrap{
  height: 16px;
  width: 16px;
  position: absolute;
  top: -17px;
    right: -20px;
}
.modal-sm .img-wrap input[type=file] {
  font-size: 20px;
  position: absolute!important;
  left: 0;
  top: 0;
  opacity: 0;
}
.reset-btn{
   line-height: 45px;
   color: #000;
   text-decoration: none;
}
.reset-btn:hover{
  color:#004F6B;
}
.table-collapse.clientstatus-collapse {
  left: -40px;
}
.table-collapse.client-collapse {
  left: 20px;
}
@media (max-width:767px){
  .form-panel .form-widget .form-section {
    padding: 20px 20px;
}
.logo{
  width: 200px;
}

.form-panel .form-widget .message {
  padding: 0px 20px;
}
.navbar-nav{
  margin: 0px;
}
.navbar {
  padding: 0.4rem 0rem;
  padding: 0;
  margin-top: 0px;
}
.navbar-nav li{
  text-align: right;
  margin-left: 0px;
  margin-right: 0px;
  padding: 8px 15px;
  background: #B8CBC8;
}
header {
  height: 54px;
}
.navbar-nav li.visible-xs-block{
  display: block!important;
  background: #fff!important;
}
.hidden-xs-block{
  display:none!important;
}
.btn-row .pull-left,
.btn-row .pull-right{
  float: inherit;
}
.btn-row .pull-right{
  margin-top: 15px;
}
.form-panel .form-widget .form-control.text-area {
  height: auto;
  overflow-y: inherit;
}
.webcam-wrap video{
height: 175px;
min-width: 306px;
 
}
.profile .profile-widget .info-wrap {
  width: 250px;
}
.modal-content {
 min-width:auto;
}
}
@media (max-width:400px){

  .webcam-wrap video{
    height: 155px;
    min-width: 100%;
     
    }
 
  }
@media (max-width:362px){

.form-panel .form-widget .pagination li.mid {
  width: 154px;
}
}
.dashboard a{text-decoration: none}

.show_loader_on_click {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('/images/loader.gif') 50% 50% no-repeat rgb(249,249,249);
    opacity: .8;
}
.show_loader_on_click2{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: url('/images/loader.gif') 50% 50% no-repeat rgb(249,249,249);
  opacity: .8;
}
.content .work-section .dashboard .table th {min-width:200px; }
.content .work-section .dashboard .table th, .content .work-section .dashboard .table td.managerAppGrid {min-width:100px; }
.managerAppGrid {min-width: 100px!important;}
.managerGridCol {min-width: 152px!important}



.clGrid tr{cursor: pointer;}

.profile .table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
 }
 .profile .table-striped tbody tr:nth-of-type(even) {
  background-color: #fff;
 }
 .profile .table-striped tbody tr:hover {
  background-color: #eed0c9!important;
  cursor: pointer;
 }
.sidebarmanager{background: #F26C52; text-transform: uppercase}

.sidebarmanager .sidebar-nav li.active a, .sidebarmanager .sidebar-nav li a:hover, .sidebarmanager .sidebar-nav li a:focus{
   background: #d0472d;
}

.prev-Img{max-width:100%}


/* For the Toggle Swtich -- 15 Jan 2019 */

.switchToggle {
  position: relative;
  display: inline-flex;
  width: 40px;
  height: 15px;
  outline: none;
}
.switchToggle input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.sliderToggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #777913;
  transition: .4s;
  border-radius: 15px;
}
.sliderToggle:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 0px;
  bottom: -3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
  box-shadow:1px 1px 5px #888888;
}
input:checked + .sliderToggle {
  background-color: #BEB7B3;
}
input:focus + .sliderToggle {
  box-shadow: 0 0 1px #BEB7B3;
}
input:checked + .sliderToggle:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.agentDetailStatus {
  font-size:18px;
}
.agentDetailStatus span {color:#ccc; margin-left:10px;}
.agentDetailStatus .active {color:#000; margin-left: 10px;}

.icon-wrap.delete{
  height: 18px;
  background: url(/delete.png) no-repeat;
}

.icon-wrap.delete:hover,
.icon-wrap.delete:focus,
.icon-wrap.delete.active{
  background: url(/delete-hover.png) no-repeat;
} 

.content .templatesGrid .dashboard .panel-body{min-height: 200px;}
.agentRow {cursor: pointer;}

.rental-application .second-page{
  max-width: 400px;
  margin: 20px auto;
  text-align: center;
    padding: 130px 0;
  
}
.rental-application .second-page h3{
  margin-bottom: 80px;
}



.managerTemplates .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}
.managerTemplates .upload-btn-wrapper input[type=file] {
  font-size: 20px;
  position: absolute!important;
  left: 0;
  top: 0;
  opacity: 0; cursor: pointer;
}

.agentNameOnBoard ul {
  padding-top: 0px;
}

.agentNameOnBoard ul li {
  border-bottom: 1px solid #efefef;
  padding: 5px;
  cursor: pointer;
  text-align:left;
}

.agentNameOnBoard ul li:last-child {border-bottom:0px}

.agentNameOnBoard ul li:hover {
  background: #fcdad4;
  border-radius: 3px;
}


.sidebar{
  position: fixed;
  z-index: 0;
  padding-top:60px;
  margin-bottom: 0px;
  margin-top: 0px;
 
 }
 .content{
  margin-top: 0px;
 }



.table-responsive {
  overflow-x: initial!important;  
}

.content .work-section .dashboard .panel-body {
  min-height: auto;
  background: none;
  box-shadow: none;
}

.dashboard .table {
  margin-bottom: 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

::selection {
  color: #000;
  background: #bfd3da;
}

.modalTransparent .styles_overlay__CLSq- {
  background: none;
}
.modalTransparent .styles_modal__gNwvD { background: none;}

.styles_closeButton__20ID4 {
  top: 72px;
  right: 29px;
}
.styles_closeButton__20ID4 svg:not(:root) {
  stroke: #fff;
    stroke-width: 3px;
    stroke-linecap: butt;
    stroke-dasharray: 0;
}
.no-drag{cursor: no-drop!important}

.clearFilter{font-size: 10px; text-transform: uppercase; background:#dee2e6; padding:0px 8px ; color:#333; display: inline-block; border-radius: 3px;}
.clearFilter:hover{background:#787879; color:#fff}
a{ transition: 0.3s; color:#000}
.filterSearchReset {position: absolute; top:20px; right: 21px;}

.draggable span{display: flex}

.draggable {padding: 1px 5px; margin-bottom: 5px;}
.draggable:hover{background:rgb(242,108,82, 0.25); cursor: pointer; border-radius: 2px }

page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0cm;
}
page[size="A4"] {  
  width: 210mm;
  height: 297mm; 
}
.fixedHeader{position: fixed; width: 100%; z-index: 9999; top:0}

.content .work-section {margin-top: 50px;}

.profile .clientDetailPop tbody tr:nth-of-type(odd) {
  background: none!important;
}

.profile .clientDetailPop tbody tr:nth-of-type(even) {
  background: none!important;
}

.profile .clientDetailPop tbody tr:hover {
  background: rgb(242,108,82,0.25)!important;
  border-radius: 2px;
}

.profile .managerDetailPop tbody tr:nth-of-type(odd) {
  background: none!important;
}

.profile .managerDetailPop tbody tr:nth-of-type(even) {
  background: none!important;
}

.profile .managerDetailPop tbody tr:hover {
  background: rgb(190,183,179,0.75)!important;
  border-radius: 2px;
}

.nextBtnEditor {

  
  position:relative;

}
.nextBtnEditor::before {
content: '';
  background: url(/nextEditor.png)no-repeat;
  position: absolute;
  display: block;
  width:30px;
  height:20px;
  top:3px;
  left:20px;

}

.nextBtnEditor:hover:before {
  content: '';
    background: url(/nextEditor-hover.png)no-repeat;
    
  
  }

  .prevBtnEditor::before {
    content: '';
      background: url(/nextEditor.png)no-repeat;
      position: absolute;
      display: block;
      width:30px;
      height:20px;
      top:3px;
      left:0px;
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg);
    
    }
    
    .prevBtnEditor:hover:before {
      content: '';
        background: url(/nextEditor-hover.png)no-repeat;
        
      
      }
  


/*--------------START LISTING CSS--------------*/
.duration-widget li{
  display: inline-block;
  list-style: none;
  font-size: 18px;
  padding: 3px;

}
.duration-widget li a{
  text-decoration: none;
  color:#000;
  font-weight: 600;
  padding:3px 5px;
}
.duration-widget li a:focus,
.duration-widget li.active a,
.duration-widget li a:hover{
  background: #004F6B;
  border-radius: 4px;
  color:#fff;
}
.duration-widget {
  padding-left: 8px;
}
.duration-widget .container-rd{
  padding-left: 0px;
  width: 35px !important;
  height: 33px !important;
  float: left;
  margin: 0px 4px;
  background: none;
  box-shadow: none;
}
.duration-widget .container-rd .txt {
 top: 4px!important;
  font-size: 18px!important;
  font-weight: 900!important;
  color:#000!important;

}
.duration-widget .checkmark {
  height: 32px;
  box-shadow: none!important;
  background: none!important;
  top:2px;
  border:0px;
}
.duration-widget .container-rd input:hover ~ .checkmark, 
.duration-widget .container-rd input:checked ~ .checkmark{
  border:0px;
  box-shadow: none;
  background: #004F6B!important;
 
}
.duration-widget .container-rd input:hover ~ .txt,
.duration-widget .container-rd input:checked ~ .txt{
  color:#fff!important;
 
}

.w-bg{
  background: #fff;
  width: 100%;
  height: 100%;
  position: relative;

}
.w-bg::before{
  position: fixed;
  content:'';
  width: 100%;
  height: 100% ;
  display: block;
  bottom: 0px;
  background: #fff;
  z-index: -1;
}
.container.listing-container{
  max-width: 1120px;
}
.listing-landing{
  padding-top: 200px;

}
.listing-filter{
  background:#f8f7f6;
  border:1px solid #bfbfbf;
  padding:15px 0;
  height:120px;
  box-sizing: border-box;
  border-radius: 3px;
  margin: 30px 0;
  display: block;
}

.listing-filter h4.title{
  text-align:center;
  font-size:18px;
  font-weight: 700;
  border:1px solid transparent;
}
.listing-filter .panel_1,
.listing-filter .panel_2,
.listing-filter .panel_3,
.listing-filter .panel_4{
  float:left;
  
}
.listing-filter .panel_1{
  width:160px;
  margin-left:60px;
}
.listing-filter .panel_2{
  width:264px;
  margin-left:45px;
  margin-right:30px;
}
.listing-filter .panel_3{
  width:320px;
  margin-right:35px;
}
.listing-filter .panel_4{
  width:120px;
}
.listing-filter .input-range { 
  margin-top: 30px;
}
.listing-filter .input-range__label-container{
  background: #dbd7d4;
  border-radius: 1px;
  padding: 1px 3px;
  font-family: 'Metropolis-Regular';
  color:#333;
  font-size: 11px;
}
.refine-search{
  background: #f8f7f6 url(/refine-arrow.png) no-repeat right;
  border:1px solid #bfbfbf;
  padding: 15px 35px;
  box-sizing: border-box;
  border-radius: 3px; 
  font-size: 14px ;
  color:#333;
  background-position: 97% 8px;
  margin-bottom: 20px;

}
.listing-filter .input-range__track {
  background: #beb7b3;
}
.listing-filter .input-range__label--min .input-range__label-container,
.listing-filter .input-range__label--max .input-range__label-container{
  background: none;
  color:#BEB7B3;
  font-weight: 600;
  position: relative;

}
.listing-filter .input-range__label--max .input-range__label-container{
  color:transparent;
}
.listing-filter .input-range__label--max .input-range__label-container:before{
    content:'No Limit';
    position: absolute;
    right: 0px;
    top:0px;
    color:#BEB7B3;
    
}
.listing-filter .input-range__slider{
  background: #ffff;
  border:1px solid #004f6b;
}
.input-range__track--active {
  background: #004f6b!important;
}
.listing-filter .neighbor-filter {
   background: url(/combo-dropdown.png) no-repeat 96% 0;
   height:35px;
   overflow: hidden;
    border-radius: 2px;
   border-right: 1px solid rgba(0, 0, 0, 0.25);
}

.listing-filter .neighbor-filter select {
   background: transparent;
   height:35px;
   width: 120%; 
   border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 2px;
}

.listing-filter .neighbor-filter.black   { background-color: #efedec; }
.listing-filter .neighbor-filter.black select   { color: #000; }
.listing-filter .search-wrap{
  margin-top: 10px;
  text-align: center;
}
.listing-filter .duration-widget{
  padding: 0px;
  padding-left: 5px;
  width: 100%;
}
.listing-filter .duration-widget .container-rd{
  margin: 0px 1px;
}
.listing-filter a.advance-search-widget{
  font-size: 12px;
  color:#000;
  text-align: center;
  margin-top: 15px;
  display: block;
  margin: 0px auto;
  margin-top: 15px;
  width: 115px;
}
.listing-filter{
  position: relative;
}
.listing-filter .advance-search{
  background: #E4E1DC;
  mix-blend-mode: normal;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  min-height: 250px;
  position: absolute;
  width: 100%;
  top:120px;
  z-index: 99;
}
.listing-filter .advance-search .filter-block{
  padding: 10px 60px;
}
.listing-filter .advance-search .filter-block h5.title{
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
}

.listing-filter .advance-search .filter-block .form-group {
  display: block;
  margin-bottom: 0px;
}

.listing-filter .advance-search .filter-block .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.listing-filter .advance-search .filter-block .form-group label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  margin-left: 20px;
  margin-bottom: 2px;
}
.listing-filter .advance-search .filter-block .checkbox-inline{
  display: inline-block;
}
.listing-filter .advance-search .filter-block .form-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #333;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 3px;
}
.listing-filter .advance-search .filter-block .form-group input:checked+ label:before{
  background: #333;
}
.listing-filter .advance-search .filter-block .form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 9px;
  width: 4px;
  height: 12px;
  border: solid #E4E1DC;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}



h2{
  font-size: 34px;
  
}
.btn.btn-default {
  background: #004f6b;
  color: #fff;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
}
.btn.btn-default:hover{
  background: #f2f2f2;
  color: #000;
  border-color:#000;
}
.listing-result  a{
  color: #000;
  text-decoration: none;
}
.listing-result .list-frame{
  border: 3px solid #bfbfbf;
  border-radius: 2px;
  background: #fff;
  display:block;
  margin-bottom: 15px;
  transition: all .4s;

}
.listing-result .list-frame .img-wrap{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height:220px;
  overflow: hidden;
  background: no-repeat;
  background-size:cover;

}

.listing-result .list-frame .listing-info{
  padding:20px 28px;
  font-family: 'Cormorant Garamond', serif;
  position: relative;
}

.listing-result .list-frame .listing-info h3.address{
  font-size: 24px;
  font-family: 'Metropolis-Regular';
  margin-bottom: 20px;
}
.listing-result .list-frame .listing-info .area{
  font-size:16px;
  margin-bottom: 10px;
}
.listing-result .list-frame .listing-info .price{
  font-size:24px;
}
.listing-result .list-frame .listing-info .room-info{
  font-size:20px;
}
.listing-result .list-frame .listing-info p{
  margin: 0px;
}

.listing-result .list-frame:hover{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.listing-result .map-section{
  border: 2px solid #bfbfbf;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  max-height: 630px;
}
#ListingModal .modal-content{
  max-width: auto;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Cormorant Garamond', serif;
}
#ListingModal .modal-dialog {
  max-width: 750px;
}
#ListingModal  .modal-header {
 height: 120px;
  background: #fff;
  color:#333;
  display: block;
  padding: 30px 25px;
}
#ListingModal  .modal-header p{
  margin: 0px;
}
#ListingModal  .modal-header h3{
  font-family: 'Metropolis-Regular';
  font-size: 22px;
}
#ListingModal  .modal-header .price{
  font-size: 30px;
}
#ListingModal .listing-banner{
  margin-bottom: 20px;
  position: relative;
  margin-left: -30px;
  margin-right: -30px;
}
#ListingModal .modal-body{
  padding: 30px;
  padding-top: 0px;
}
#ListingModal .modal-body h4{
  font-family: 'Metropolis-Regular';
  font-size: 16px;
  border-bottom: 1px solid #BEB7B3;
  padding-bottom: 5px;
}
#ListingModal .modal-body .amenities-box ul{
  padding: 0px;
  margin-bottom: 40px;
}
#ListingModal .modal-body .amenities-box li{
  list-style: none;
  display:inline-block;
  background: url(/list-arrow.png) no-repeat left;
  padding-left: 10px;
  margin-right: 15px;
  font-size: 12px;
}
#ListingModal .modal-body .discription-box{
  margin-bottom: 15px;
}
#ListingModal .modal-body .discription-box p{
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}
#ListingModal .listed-box .info{
  float: left;
  width: 110px;
  font-size: 14px;
 
}
#ListingModal .listed-box h4{
  margin-bottom: 25px;
}
#ListingModal .listed-box .info p.name{
  font-size: 16px;
}

#ListingModal .modal-dialog {
  margin: 2rem auto;
}
#ListingModal .map-wrap{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1px;
  border: 2px solid #bfbfbf;
  overflow: hidden;
  height: 200px;
}
#ListingModal .incentive-box{
  margin-bottom: 30px;
}
#ListingModal .img-edit{
  position: absolute; 
  top:20px;
  right: 20px;
}
#ListingModal .img-edit i{
  font-size: 30px;
}
#ListingModal .incentive-box p{
  font-size: 12px;
}
#ListingModal .btn-group-section{
  padding: 0px;
}
#ListingModal .btn-group-section li{
  margin-bottom:5px;
  list-style: none;
}
#ListingModal .btn-group-section li:last-child{
  margin-top: 15px;
}
#ListingModal .btn-group-section .btn{
  width: 100%;
  font-family: 'Metropolis-Regular';
  font-size: 11px;
}
#ListingModal .btn-group-section .btn.apply-btn{
  background: #777913;
  border:1px solid #777913;
  color:#fff;
}
#ListingModal .btn-group-section .btn.apply-btn:hover{
  background: #f2f2f2;
  color:#777913;
}
#ListingModal .col-sm-3{
  padding-left: 0px;
}
#ListingModal .carousel .control-dots,
#ListingModal .carousel .carousel-status,
#ListingModal .carousel .thumbs-wrapper{
  display: none;
}
#ListingModal .carousel .slide .legend{
  border:1px solid rgba(0, 0, 0, 0.5);
  background: rgba(255,255,255,.5);
  border-radius: 3px;
  color:#000;
  font-family: 'Metropolis-Regular';
  width: 182px;
  padding: 14px 10px;
  opacity: 1;
  left: inherit;
  right:30px;
  bottom: 45px;
}
#ListingModal  .carousel .control-next.control-arrow {
  background: url(/next.png) no-repeat center!important;
  width: 55px!important;
  opacity: 1;
}
#ListingModal  .carousel .control-prev.control-arrow {
  background: url(/prev.png) no-repeat center!important;
  width: 55px!important;
  opacity: 1;
}

#ListingModal .carousel .control-arrow:before{
  opacity: 0;
}
#ListingModal .carousel .slide .legend img{
  width:20px;
  margin-right:5px;
}
.cl-btn{
  background: none;
  border: 0px;
  padding: 0px;
  font-family: 'Metropolis-Regular';
  font-size: 14px;
  color:#004F6B;
  font-weight: 800;
}
.cl-btn:hover{
  background: none;
  color:#004f6b;
  
}
.content .work-section .dashboard .agent-listing tbody tr:nth-of-type(even){
  background: #E4E1DC;
  border: 1px solid rgba(51, 51, 51, 0.5);
  border-radius: 1px;
}
.content .work-section .dashboard .table.agent-listing td,
.content .work-section .dashboard .table.agent-listing thead th {
    padding: 12px 25px;
}
.btn.btn-small{
  border-radius: 3px;
  padding: 2px 20px;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
  color: #fff;
  height: auto;
}
.btn.btn-big{
  padding: 10px 20px;
  color:#fff;
  height: 40px;;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
  
}
.btn.btn-small.btn-expire{
background: #F26C52;
border:1px solid #F26C52;
}
.btn.btn-small.btn-expire:hover{
  color:#f26c52;
  background: none;
}
.btn.btn-big.btn-expire{
  background: #F26C52;
  border:1px solid #F26C52;
  }
.btn.btn-big.btn-expire:hover{
    color:#f26c52;
    background: none;
  }
  .btn.btn-big.btn-relist{
    background: #777913;
    border:1px solid #777913;
    }
  .btn.btn-big.btn-relist:hover{
      color: #777913;
      background: none;
    }
  .btn.btn-big.btn-relist-inactive{
      color: #777913;
      background: none;
    } 
    .btn.btn-big.btn-renthop{
      background: #0f79f3;
      border:1px solid #0f79f3;
      }
    .btn.btn-big.btn-renthop:hover{
        color: #0f79f3;
        background: none;
      }
    .btn.btn-big.btn-renthop-inactive{
        color: #0f79f3;
        background: none;
      } 

.btn.btn-small.btn-relist{
  background: #777913;
  border:1px solid #777913;
  }
.btn.btn-small.btn-relist:hover{
    color:#777913;
    background: none;
  }
.editsmall{
  font-size: 16px;
  color:#000;
  margin-left: 10px;
}
.editsmall:hover{
  color:#F26C52;
}


.editsmallicon{
  font-size: 16px;
  color:#FFFFFF;
  margin-right: 50px;
}
.editsmallicon:hover{
  color:#F26C52;
}
.availabel-txt, .unfurnish-txt{
  font-family: 'Metropolis-Regular';
}
.availabel-txt {
  font-size: 16px!important;
}
.brokerspan{
  background: #333333;
  border-radius: 4px;
  color:#fff;
  width: 19px;
  height: 20px;
  text-align: center;
}
.icon-wrap.avaliable{
  background: url(/avaliable.png) no-repeat;
  height: 21px;
  position: relative;
  top:4px;
} 
.fixedHeader{
  position: fixed;
  z-index: 99;
  width: 100%;
}
.content .work-section .dashboard{
  margin-top: 60px;
}
.rental-application.listing-creator-section{
  background:none;
  padding: 0;
}
.listing-creator-section {
  margin: 0px 30px;
}
.listing-creator-section .listing-block{
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 18px 32px 5px;
  margin: 12px 0px;
}
.listing-creator-section .listing-block h4{
  font-weight: 800;
  font-size: 18px;
  color: #333333;
}
.listing-creator-section .listing-block form{
  padding: 10px;
}
.listing-creator-section .listing-block ul.btn-inline{
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}
.listing-creator-section .listing-block ul.btn-inline li{
  display: inline-block;
  list-style: none;
  margin-right: 40px;
}
.listing-creator-section .listing-block ul.btn-inline .btn{
  border: 1px solid rgba(0,0,0,.25);
  box-sizing: border-box;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  width: 207px;
  height: 40px;
  color:#333;
  background: rgba(184,203,200, .25);
}
.listing-creator-section .listing-block ul.btn-inline .btn:hover{
  background: #004F6B;
  border:1px solid #004F6B;
  color:#fff;
}
.listing-creator-section .listing-block label{
  margin-bottom: 0px;
  margin-top: 20px
}
.listing-creator-section .listing-block .form-control{
  height: 25px;
}
.listing-creator-section .listing-block .form-control.size{
  width: 200px;
  padding-right: 40px;
}
.listing-creator-section .listing-block .form-inline{
  display: block;
}
.listing-creator-section .listing-block .form-inline .form-group{
  margin-bottom: 15px;
  margin-right: 20px;
  display: inline-block;
  position: relative;
}
.listing-creator-section .listing-block .form-inline .form-group label{
  text-align: left;  
  display: block;
}
.listing-creator-section .listing-block .form-inline .form-group .rightbedge,
.listing-creator-section .listing-block .form-inline .form-group .leftbedge{
  position: absolute;
  bottom: 2px;
}
.listing-creator-section .listing-block .form-inline .form-group .leftbedge{
  left:-10px;
}
.listing-creator-section .listing-block .form-inline .form-group .rightbedge{
  right: 0;
}


.check-block .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.check-block .form-group label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 2px;
}
.check-block .checkbox-inline{
  display: inline-block;
}
.check-block .form-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #333;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 3px;
  bottom: 2px;
}
.check-block .form-group input:checked+ label:before{
  background: #333;
}
.check-block .form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 8px;
  width: 5px;
  height: 12px;
  border: solid #E4E1DC;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.listing-creator-section .listing-block .carousel{
  margin-bottom: 20px;
}
.listing-creator-section .listing-block .carousel .slide{
  background: #fff;
  border:0px;
  padding-bottom: 20px;

}
.listing-creator-section .listing-block .add-image-block{
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 200px;
  background:url(/add-img.png) no-repeat center;
  display: block;
}
.listing-creator-section .listing-block .col-sm-9{
  padding-right: 0px;
}
.listing-creator-section .listing-block .carousel.carousel-slider .control-arrow {
  margin-bottom: 20px;
}
.listing-creator-section .listing-block .carousel .control-prev.control-arrow,
.listing-creator-section .listing-block .carousel .control-next.control-arrow {
  width: 16px!important;
  opacity: 1;
}
.listing-creator-section .listing-block .carousel .control-next.control-arrow {
  background: url(/next.png) no-repeat center!important;

}
.listing-creator-section .listing-block .carousel .control-prev.control-arrow {
  background: url(/prev.png) no-repeat center!important;
}
.listing-creator-section .listing-block .carousel .slider-wrapper {
  padding: 0px 8px;
}
.carousel .control-arrow:before, 
.carousel.carousel-slider .control-arrow:before{
  display: none!important;
}
.listing-creator-section .listing-block .slider-frame{
   position: relative;
  width: 31%;
  margin-right: 2.33%;
  display: inline-block;
  overflow: hidden;
}
.listing-creator-section .listing-block .slider-frame img{
  margin-left:10px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 200px;
  width:auto
}
.listing-creator-section .listing-block .slider-frame .close-thumb{
  position: absolute;
  width: 20px;
  height: 20px;
  top:10px;
  right:5px;
  display: block;
  z-index: 99;
  text-indent: -999999px;
  background: url(/close-img.png) no-repeat;

}
.listing-creator-section .listing-block .neighbor-filter {
  background: url(/combo-dropdown.png) no-repeat 96% 0;
  height:35px;
  overflow: hidden;
   border-radius: 2px;
  border-right: 1px solid rgba(0, 0, 0, 0.25);
}

.listing-creator-section .listing-block .neighbor-filter select {
  background: transparent;
  height:35px;
  width: 120%; 
  border: 1px solid rgba(0, 0, 0, 0.25);
 box-sizing: border-box;
 border-radius: 2px;
}

.listing-creator-section .listing-block .neighbor-filter.black   { background-color: #efedec; }
.listing-creator-section .listing-block .neighbor-filter.black select   { color: #000; }
.listing-creator-section .listing-block .search-wrap{
 margin-top: 10px;
 text-align: center;
}
.listing-filter.box .input-range{
  max-width: 250px;
  margin: 0px auto;
  margin-top: 25px;
}
#buildingModal .detail-box{
  margin-bottom: 15px;
}
#buildingModal .detail-box h3.title{
  line-height: normal;
  font-size: 16px;
  color: #000000;
  border-bottom:1px solid #000; 
  padding-bottom: 10px; 
}
#buildingModal .detail-box .info{
  padding-left: 8px;
  font-size: 14px;
  line-height: 18px;

}
#buildingModal .detail-box.landload-box h3.title{
  font-size: 24px;
  margin-bottom: 20px
}
#buildingModal .detail-box.landload-box .info{
  font-size: 22px;
}
#buildingModal .detail-box.landload-box .info .check-block{
  padding-top: 20px;
}
#buildingModal .detail-box.landload-box h3.title name{
  font-size: 18px;
  float:right;
  position: relative;
  top:5px;
}
#buildingModal .modal-header {
  border-radius: 2px;
  color:#fff;
  font-size: 24px;
  font-family: 'Cormorant Garamond', serif;
}
#buildingModal .modal-dialog {
  max-width: 1000px;
}

/*--------------START LISTING CSS--------------*/
@media (max-width:1200px){
  .content .work-section .dashboard .table.agent-listing td,
  .content .work-section .dashboard .table.agent-listing thead th {
    padding: 14px 15px;
}
}

@media (max-width:1080px){
  .listing-landing {
    padding-top: 100px;
}
  .listing-filter{
    height: auto;
  }
  .listing-filter .panel_1,
  .listing-filter .panel_2,
  .listing-filter .panel_3,
  .listing-filter .panel_4{
    float: inherit;
    width: 85%;
    margin: 10px auto;
    
  }
  .listing-filter .duration-widget {
    padding: 0px;
    padding-left: 5px;
    width: 160px;
    margin: 0px auto;
}
.sidebar .sidebar-nav {
  padding: 30px 14px;
}
.sidebar .sidebar-nav li a span{
  display: none;
}
.sidebar {
  width: 80px;
}
.sidebar .sidebar-nav li a {
  padding: 10px 14px;
}
.content {
  margin-left: 80px;
}
.content .work-section {
  padding: 0px 15px;
}
.navbar .header-tag {
  font-size: 24px;
  }

}



/*------------START FRONT END CSS-----------------*/
.FrontHeader{
  position:fixed;
  height: 122px;
  width: 100%;
  background: #fff;
  z-index: 999;
}
.FrontHeader .navbar-dark .navbar-nav .nav-link{
  color: #000;
}

.FrontHeader .navbar-nav.mid-nav{
  text-align: center;
  left: 50%;
  position: relative;
  margin-left: -200px;
  
}
.FrontHeader .navbar a.btn.btn-default{
  color:#fff;
  height: auto;
  padding: 10px 20px;
  font-weight: normal;
}

.FrontHeader .navbar a:hover{
  color:#004f6b;
  text-decoration: underline;
}
.FrontHeader .navbar a.btn.btn-default:hover{
  color:#000;
  text-decoration:none;
}
.FrontHeader .logo{
  width: 300px;
  position: absolute;
  left: 0;
  top:28px;
}
.FrontEnd p.title{
  font-family: 'Cormorant Garamond', serif;
  font-style: italic;
  font-size: 40px;
}

.FrontEnd .container.workbody{
  max-width: 880px;
  margin: 0px auto;
  padding: 200px 15px;
  z-index: 9;
  position: relative;
}
.FrontEnd h1{
  font-family: 'Cormorant Garamond', serif;
  border-bottom: 1px solid #000;
  font-size: 60px;
  font-style: italic;
  padding-bottom: 15px;
}
.FrontEnd .experience-section{
  padding:30px 70px;
}
.FrontEnd .experience-section .R_map{
  border:1px solid #000;
  border-radius: 3px;
  margin-bottom: 40px;
}
.FrontEnd .experience-section .R_map img{
  width: 100%;
  border-radius: 3px;
}
.FrontEnd .experience-section .location{
  margin-bottom: 40px;
  margin-left: 20px;
}
.FrontEnd .experience-section .location p{
  line-height: 18px;
}
.FrontEnd a{
  color:#000;  
}
.FrontEnd .location-section{
  padding:0;
}
.FrontEnd h2.title{
  font-size: 34px;
  font-family: 'Metropolis-Regular';
  text-transform: uppercase;
  font-weight: 700;
}
.FrontEnd .experience-section.location-section .location {
  margin-bottom: 60px;
  margin-left: 0px;
}
.footer-nav{
  border-top:1px solid #000;
  margin: 0px 40px;
}
.footer-nav .nav{
  display: block;
  text-align: center;
}
.footer-nav .nav li{
  display:inline-block;
  font-size: 24px;

} 
.footer-nav .nav li:first-child{
  margin-right: 80px;
}
.footer-nav .nav li:last-child{
  margin-left: 80px;
}
.footer-nav .nav li.active a,
.footer-nav .nav li a:hover{
  text-decoration: underline;
}
.FrontEnd footer{
  background: #004F6B;
  width: 100%;
  color:#fff;
  bottom: 0;
  z-index: 99;
  position: relative;
 
}
.FrontEnd footer a{
  color:#fff!important;
}
.FrontEnd footer .container{
  max-width: 700px!important;
  padding:15px 0 1px 0!important;
  
 }
 .FrontEnd footer li{
   list-style: none;
   display: inline-block;
 }
 .FrontEnd footer .left-nav{
   font-size: 12px;
   float: left;
 }
 .FrontEnd footer .left-nav li{
   margin-right: 30px;
 }
 .FrontEnd footer .right-nav{
   float: right;
   font-size: 24px;
 }
 .new_logo {
  padding-top: 50px;
}
 .FrontEnd footer .right-nav li{
   padding-left: 30px;
 }
 .FrontEnd footer .left-nav,
 .FrontEnd footer .right-nav{
   margin-top: 18px;
 }
 .FrontEnd footer .right-nav a:hover{
   color:#000!important;
 }
 .FrontEnd footer .logo{
   width: 92px;
   height: 95px;
   background: url(/wht-logo.png) no-repeat;
   position:absolute;
   left:50%;
   margin-left:-46px;
   top:130px !important;
 }
 p.text_1 {
  margin-top: 30px;
}
 .fixed-page{
  position: relative;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
   top:170px;
   height: 100vh;
   width: 100%;
   background: #fff;
 }
 .ourteam-section .search-section{
   margin-bottom: 20px;
 }
 .ourteam-section .search-section .search-block input{
   border-radius: 0px;
   border: 0px;
   border-bottom: 1px solid #a4a4a4;
   background: url(/search.svg) no-repeat bottom left;
    padding: 5px;
   padding-left: 30px;
   background-position: 1px;
   max-width: 350px;
  
 }
 .ourteam-section .team-block{
   margin-bottom: 40px;

 }
 .ourteam-section .team-block .team-img{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   height: 190px;
  margin-bottom: 5px;
  width: 100%;
 }
 .ourteam-section .team-block .team-info p{
   margin: 0px;
   font-size: 14px;
 }
 .ourteam-section .team-block .team-info p.name{
   font-size: 24px;
   font-family: 'Cormorant Garamond', serif;
 }
 .ourteam-section .team-block .team-info p.client-title{
   font-weight: 200;
 }
 .ourteam-section .team-block .team-info p.email{
   font-size: 12px;
 }
 .agent-detail-section .frame{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 229px;
  width: 229px;
  margin-bottom: 5px;
  float: left;
 }
 .agent-detail-section .agent-info{
   margin-left: 265px;
 }
 .agent-detail-section .agent-info p.name{
  font-family: 'Cormorant Garamond', serif;
  font-size: 32px;
  font-weight: bold;
  line-height: 35px;
  margin-bottom: 0px;

 }
 .agent-detail-section .agent-info .pull-right{
   margin-top: 20px;
 }
 .agent-detail-section .agent-info p.clienttitle{
   font-size: 20px;
   font-weight: 200;
 }
 .agent-detail-section .agent-info p.phnumber,
 .agent-detail-section .agent-info p.email{
   font-size: 26px;
   text-align: right;
   line-height: 20px;
 }
 .agent-detail-section .agent-info p.phnumber{
   font-weight: 300;
   margin-top: 5px;
 }
 .agent-detail-section p.discription{
  font-family: 'Cormorant Garamond', serif;
  font-weight: normal;
  line-height: normal;
  text-align: justify;
  font-size: 16px;
  margin: 30px 0;
 }
 .agent-listing {
   margin: 60px 0;
 }
 .agent-listing .list-frame .img-wrap{
   height: 176px;
 }
 .agent-listing .list-frame .listing-info {
  padding: 15px;
 }
 .agent-listing h2.listing-title{
  font-family: 'Cormorant Garamond', serif;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 32px;
  text-align: center;
  border-bottom: 1px solid #000;
  margin-bottom: 20px;
  padding-bottom: 10px;
 }
 .FrontHeader .navbar-dark .navbar-toggler {
  position: absolute;
  right: 5px;
  top: -1px;
}
.FrontHeader .navbar-collapse {
  margin-top: 35px;
  padding-bottom: 20px;
}
.FrontHeader .navbar-nav li{
  padding:0px 15px;
}

.conSnapScroll {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  
  height: 100vh;
  
  
}

.scrollarea{
  scroll-snap-align: start;
  height: 110vh;
 


}
.scrollarea .listing-landing{
  padding-top: 300px;
}
.scrollarea .workbody{
  padding-top: 140px!important;
}
.career-section .banner-wrap{
  height: 70vh;
  overflow: hidden;
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left;
}
.career-section .banner-wrap .caption{
  font-size: 60px;
  font-weight: 900;
  padding: 20px;
  max-width: 600px;
  line-height: 60px;
  background: rgba(255,255,255,.5);
  margin-left: 120px;
}
.career-section .slogan-section{
  font-family: 'Cormorant Garamond', serif;
  font-size: 34px;
  padding: 60px 0;
  line-height: 34px;
  font-style: italic;
  font-weight: 100;
  height:30vh;
}
@media (max-width:1060px){
  .scrollarea .listing-landing {
    padding-top: 140px;
}
.career-section .banner-wrap{
  height: 430px;
}
.career-section .banner-wrap .caption{
  font-size: 40px;
  padding: 20px;
  max-width: 450px;
  line-height:40px;
  margin-left: 30px;
}
.career-section .slogan-section{
  font-size: 25px;
  padding: 30px 0;
  line-height: 25px;
}
}
 @media (max-width:900px){
.footer-nav .nav li{
  font-size: 20px;
}
.footer-nav .nav li:first-child{
  margin-right: 30px;
}
.footer-nav .nav li:last-child{
  margin-left: 30px;
}
.agent-detail-section .agent-info p.name {
  font-size: 35px;
}
}
 @media (max-width:767px){
.FrontEnd h1 {
  font-size: 30px;
  line-height: 24px;
}   
.FrontEnd .experience-section {
  padding: 30px 0px;
}
.FrontEnd .experience-section .location {
  margin-bottom: 20px;
  margin-left: 20px;
}
.footer-nav {
  margin: 0px 0px;
}
.FrontEnd footer .left-nav,
.FrontEnd footer .right-nav{
   display: none;
}

.FrontEnd footer .logo {
  width: 92px;
  height: 95px;
  background: url(/wht-logo.png) no-repeat;
  position: relative;
  left: 50%;
  margin-left: -46px;
  top: -100px;
  margin-top: -100px !important;
}
.FrontEnd footer .logo{
    position: relative !important;
}
p.text_1 {
  margin-top: 135px;
  padding: 10px;
}
.footer-nav .nav li:first-child{
    margin-right: 0px;
}
.footer-nav .nav li:last-child{
    margin-left: 0px;
}
.FrontEnd h2.title {
  font-size: 24px;
}
.agent-detail-section .frame { 
  height:180px;
  width: 180px;
}
.agent-detail-section .agent-info {
  margin-left: 190px;
}
.FrontHeader{
  height:60px;
}
.FrontHeader .logo {
  width: 200px;
  top: -10px;

}
.FrontHeader .navbar {
  margin-top: 15px;
}
.FrontEnd p.title {
  font-size: 30px;
  line-height: 24px;
}
.FrontHeader .navbar-nav.mid-nav{
  left:0px;
}
.scrollarea .listing-landing {
  padding-top: 80px;
}
.scrollarea .workbody {
  padding-top: 80px!important;
}
.career-section .banner-wrap{
  height: 330px;
}
.career-section .banner-wrap .caption{
  font-size:25px;
  padding:10px;
  max-width: 100%;
  line-height:25px;
  margin-left: 10px;
}
.career-section .slogan-section{
  font-size:18px;
  padding: 20px 0;
  line-height:20px;
}
}
 @media (max-width:480px){
.footer-nav .nav li {
    font-size: 12px;
}
.nav-link {
  display: block;
  padding: .5rem .5rem;
}
.search-section .pull-left,
.search-section .pull-right{
  float: none;
  text-align: center;
}
.agent-detail-section .frame { 
  height:250px;
  width: 250px;
  float:none;
  margin: 0px auto;
  margin-bottom: 20px;
}
.agent-detail-section .agent-info {
  margin-left: 0px;
  text-align: center;
}
.agent-detail-section .agent-info p.phnumber, .agent-detail-section .agent-info p.email {
  text-align: center;
}
.agent-detail-section .agent-info p.phnumber{
  margin-top: 0;
}
}

/*------------END FRONT END CSS-----------------*/

.linkingFix{
  display: inline-block!important;
   margin-right: 3px;
}
.mapdiv{
  width:100%!important;
}
.auto-search-widget li a.active {
  background: #004F6B;  
  color:#fff;
}
.nextBtnEditor.active:before {
  content: '';
  background: url(/nextEditor-hover.png)no-repeat;
}
.prevBtnEditor.active:before {
  content: '';
  background: url(/nextEditor-hover.png)no-repeat;  
} 
.FrontEnd .experience-section.location-section .mapdiv{
  min-height: 700px;
}

.homecurtain:before,
.homecurtain:after{
  display: block;
  content: '';
  z-index: 1;
  width: 200px;
  height: 100vh;
  position: fixed;
  top:0px;
  background: url(/curtains.png) repeat-y;
}
.homecurtain:before{
  left:0px;
  
}
.homecurtain:after{
  right: 20px;
}

.curtain:before,
.curtain:after{
  display: block;
  content: '';
  z-index: 1;
  width: 200px;
  height: 100vh;
  position: fixed;
  top:0px;
  background: url(/curtains.png) repeat-y;
}
.curtain:before{
  left:0px;
  
}
.curtain:after{
  right: 0px;
}
#scrollCont{
  background: #fff;
  z-index: 2;
  position: relative;
}

.workarea{
  position: relative;
  z-index: 99;
}
#application_form_section_two .btn-small{
  border:1px solid rgba(0,0,0,.75);
  color:#000;
}
#application_form_section_two .btn-small:hover{
  color:#fff;
}
.table.address-table tbody td,
.table.address-table thead th{
  padding: 15px 15px!important;
}
.table.address-table .action-grid{
  min-width: 280px!important;
}
.content .work-section .dashboard .panel-body{
  overflow: hidden;
  padding: 0px;
}
@media (max-width:1400px){
.homecurtain:after,
.homecurtain:before,
.curtain:before,
.curtain:after{
  width: 100px;

}
.scrollarea{
  height: 110vh;
}
}
@media (max-width:1200px){
.homecurtain:after,
.homecurtain:before,
.curtain:before,
.curtain:after{
  display: none;

}
}
@media (max-width:1024px){
.FrontEnd h1 {
  font-size: 30px;
  line-height: 30px;
}
}
@media (max-width:767px){
  .listing-filter .advance-search {
    top: 360px;
  }
.FrontEnd .container.workbody{
  z-index: 1;
}
.FrontEnd .container.workbody.ourteam-section{
  padding-top: 80px;
}
}


/*------START REQUEST SCHEDULE CSS 22FEB 2019------*/
.requestBox .modal-dialog{
  max-width: 375px;
}
.requestBox .modal-content{
  min-width: auto;

}
.requestBox label{
  font-size: 12px;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 0px;
}

.requestBox .form-control{
  border:0px;
  border-radius:0px;
  border-bottom:1px solid #999;
  height:28px;
  padding:0px;
  outline: none;
  font-weight: bold;
  font-size: 16px;
  color:#000;
}
.requestBox .form-control::-webkit-input-placeholder{
  color:#000;
}
.requestBox .form-control::placeholder{
  color:#000;
}
.requestBox .cl-btn{
  outline: none!important;
  height: 20px;
}
.requestBox .light-text{
  opacity: .5;
}
.requestBox p.title{
  font-size: 12px;

}
.requestBox .modal-title{
  padding: 2px 15px;
}
.requestBox .form-group{
  margin-top: 20px;
  margin-bottom: 10px;
}
.requestBox .modal-body{
  padding: 15px 30px;
}
.scheduling-section .table strong{
  font-size: 16px;
  display: block;
}
.scheduling-section .table td{
  font-size:14px;
  position: relative;
}
.scheduling-section .table td ul.menu{
  position: absolute;
  right: 15px;
  top:14px;
  padding: 10px 18px 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 12px;
 
}
.scheduling-section .table td ul.menu li{
  list-style: none;
  font-size: 12px;
  line-height: 18px;

}
.scheduling-section .table td ul.menu li a{
  display: block;
}
.scheduling-section .table td ul.menu li a:hover{
  text-decoration: underline;
}
.scheduling-section .table td .footnav{
  position:absolute;
  width: 10px;
  height: 16px;
  bottom: 16px;
  right: 20px;
  background: url(/nav-dot.png) no-repeat

}
.dashboard .table td, .table th{
  background: #fff;
}
hr{
  border-color: #000;
  margin-top: 0px;
}
#CallModal .btn.btn-default,
#cancelSchudling .btn.btn-default{
  min-width: auto;
  width: 100%;
}
#cancelSchudling .btn.btn-small {
  font-size: 12px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 10px 25px;
  width: 100%;
  color:#000;
}
#cancelSchudling .btn.btn-small:hover{
  background: #004F6B;
  color:#fff;

}
.btn.btn-small{
  color:#000;
}


@media (max-width:1400px){
  .homecurtain:after,
  .homecurtain:before,
  .curtain:before,
  .curtain:after{
    width: 100px;
  
  }
  .scrollarea{
    height: 110vh;
  }
  }
  @media (max-width:1200px){
  .homecurtain:after,
  .homecurtain:before,
  .curtain:before,
  .curtain:after{
    display: none;
  
  }
  }
  @media (max-width:1024px){
  .FrontEnd h1 {
    font-size: 30px;
    line-height: 30px;
  }
  }
  @media (max-width:767px){
    .listing-filter .advance-search {
      top: 360px;
    }
  .FrontEnd .container.workbody{
    z-index: 1;
  }
  .FrontEnd .container.workbody.ourteam-section{
    padding-top: 80px;
  }
  }

  .fixedHeader {
    position: fixed;
    z-index: 9999;
    width: 100%;
}
/*------START REQUEST SCHEDULE CSS 22FEB 2019------*/
.requestBox .modal-dialog{
  max-width: 375px;
}
.requestBox .modal-content{
  min-width: auto;

}
.requestBox label{
  font-size: 12px;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 0px;
}

.requestBox .form-control{
  border:0px;
  border-radius:0px;
  border-bottom:1px solid #999;
  height:28px;
  padding:0px;
  outline: none;
  font-weight: bold;
  font-size: 16px;
  color:#000;
}
.requestBox .form-control::-webkit-input-placeholder{
  color:#000;
}
.requestBox .form-control::placeholder{
  color:#000;
}
.requestBox .cl-btn{
  outline: none!important;
  height: 20px;
}
.requestBox .light-text{
  opacity: .5;
}
.requestBox p.title{
  font-size: 12px;

}
.requestBox .modal-title{
  padding: 2px 15px;
}
.requestBox .form-group{
  margin-top: 20px;
  margin-bottom: 10px;
}
.requestBox .modal-body{
  padding: 15px 30px;
}
.scheduling-section .table strong{
  font-size: 16px;
  display: block;
}
.scheduling-section .table td{
  font-size:14px;
  position: relative;
}
.scheduling-section .table td ul.menu{
  position: absolute;
  right: 15px;
  top:14px;
  padding: 10px 18px 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 12px;
 
}
.scheduling-section .table td ul.menu li{
  list-style: none;
  font-size: 12px;
  line-height: 18px;

}
.scheduling-section .table td ul.menu li a{
  display: block;
}
.scheduling-section .table td ul.menu li a:hover{
  text-decoration: underline;
}
.scheduling-section .table td .footnav{
  position:absolute;
  width: 10px;
  height: 16px;
  bottom: 16px;
  right: 20px;
  background: url(/nav-dot.png) no-repeat

}
.dashboard .table td, .table th{
  background: #fff;
}
hr{
  border-color: #000;
  margin-top: 0px;
}
#CallModal .btn.btn-default,
#cancelSchudling .btn.btn-default{
  min-width: auto;
  width: 100%;
}
#cancelSchudling .btn.btn-small {
  font-size: 12px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 10px 25px;
  width: 100%;
  color:#000;
}
#cancelSchudling .btn.btn-small:hover{
  background: #004F6B;
  color:#fff;

}
.btn.btn-small{
  color:#000;
}

.content .work-section .dashboard .panel-body {
  overflow: inherit;
}

.styles_closeButton__20ID4{display: none}


/* Start for Resources Page 27 Feb 2019 */
.fixed-side-nav{
  position: absolute;
  bottom:0px;
}
.resources-container{
  max-width: 630px;
  margin: 30px 0px 30px 15px;
}
.resources-container h3{
font-weight: bold;
line-height: normal;
font-size: 18px;
border-bottom:1px solid #000;
}
.resources-container .btn-box .btn{
  width: 100%;
  margin-bottom: 20px;
}
.btn.btn-small.btn-expire,
.btn.btn-small.btn-relist {
  color: #fff;
}
.content .work-section .dashboard .agent-listing tbody td{
  background: none;
}
.content .work-section .dashboard .table td{
  background: none;
}
.content .work-section .dashboard .agent-listing tbody tr:nth-of-type(even){
  background: #E4E1DC;
  border: 1px solid rgba(51, 51, 51, 0.5);
  border-radius: 1px;
}
.content .work-section .dashboard .agent-listing tbody tr:nth-of-type(odd){
  background: #fff;

}

.file-upload.add-image-block {
  position: relative;
  display: inline-block;
}

.file-upload.add-image-block .file-upload__label {
  display: block;
  position: absolute;
  width:100%;
  height: 100%;  
  cursor: pointer;

}
    
.file-upload.add-image-block .file-upload__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width:0;
    height: 100%;
    opacity: 0;
}
.add-txt-panel{
  font-size: 12px;
  margin: 5px 0;
}
.add-txt-panel a{
  text-decoration: underline;
  color:#000;
}
#termModal li,
#fcraModal li{
  line-height: 15px;
  margin-bottom: 15px;

}
@media (max-width:1400px){
.listing-filter{
  max-width: 830px;
  margin: 0px auto;
  margin-top: 40px;
}
  .listing-filter .panel_1 {
    margin-left: 30px;
}
.listing-filter .panel_3 {
  width: 200px;
  margin-right: 20px;
}
.listing-filter .panel_2 {
  width: 200px;
}
}

@media (max-width:1200px){
  .listing-filter{
    max-width: 720px;
    margin: 0px auto;
    margin-top: 40px;
  }
    .listing-filter .panel_1 {
      margin-left: 10px;
  }
  .listing-filter .panel_3 {
    width: 180px;
    margin-right: 20px;
  }
  .listing-filter .panel_2 {
    width: 180px;
    margin-left: 18px;
    margin-right: 20px;
  }
  }
  @media (max-width:1080px){
  .listing-filter{
    height: auto;
  }
  .listing-filter .panel_1,
  .listing-filter .panel_2,
  .listing-filter .panel_3,
  .listing-filter .panel_4{
    float: inherit;
    width: 85%;
    margin: 10px auto;
    
  }
  .listing-filter .duration-widget {
    padding: 0px;
    padding-left: 5px;
    width: 160px;
    margin: 0px auto;
}
.listing-creator-section .listing-block .add-image-block,
.listing-creator-section .listing-block .slider-frame img {
  height: 130px;
}
}

/* Start for Sales 1 March 2019*/
#ListingModal{
  z-index: 9999;
}
.react-tabs {
  padding: 20px 30px;
}
.react-tabs__tab{
  text-transform: uppercase;
  font-size: 24px;
  padding: 6px 0px!important;
  margin-left:60px;
}
.react-tabs__tab-list{
  border: 0px!important;
}
.react-tabs__tab--selected{
  background: none!important;
  border:0px!important;
  border-bottom: 1px solid #000!important;
  border-radius: 0px!important;
}
.react-tabs__tab-list{
  margin-bottom: 30px!important;
}

.listing-creator-section .listing-block ul.btn-inline.sell-inline-btn li{
  margin-right: 30px;
}
.listing-creator-section .listing-block ul.btn-inline.sell-inline-btn .btn{
  width: 100px;
  padding:10px 0px;
}
.sale-info-section .form-group{
  width: 110px;
}
.sale-info-section .form-group .form-control.size{
  width: 112px;
}
.react-tabs__tab-panel .agent-listing{
  margin-top: 0px;
}

/* End for Sales 1 March 2019*/

/* Manager /Agent Showings  4 March 2019*/
a svg .create-showing, a svg .blk-icon{
  transition: 200ms;
  transition-timing-function: ease-in-out;
  -webkit-transition: 200ms;
  -webkit-transition-timing-function: ease-in-out;
}
a svg:hover .blk-icon{ 
  fill: #F26C52!important;
}
a svg:hover .create-showing{
  fill:#333;
}
.Cr-showing-btn{
  margin-right:50px;
  margin-top: 10px; 
}
#CreateShowing{
  z-index: 9999;
}
.content .work-section .dashboard .agent-listing.showingtable tbody tr:nth-of-type(odd){
  background: #E4E1DC;
}
.content .work-section .dashboard .agent-listing.showingtable tbody tr:nth-of-type(even){
  background: #fff;
}
.content .work-section .dashboard .agent-listing.showingtable tbody tr.danger{
  background: #FCDAD4;
}
.content .work-section .dashboard .agent-listing.showingtable tbody tr.success{
  background: #D5E5C4;
}
.content .work-section .dashboard .table.agent-listing td, .content .work-section .dashboard .table.agent-listing thead th {
  padding: 16px 16px;
}

.add-txt-panel a,
.sc-gZMcBi.daZGjV a{
  text-decoration: underline!important;
}
.sc-gZMcBi.daZGjV a:hover,
.add-txt-panel a:hover{
  color:#004F6B;
}
.scheduling-section .table td .footnav{
  cursor: pointer;
  width: 30px;
  background-position: 15px 0px;

}

.table-collapse.status-collapse {
  min-width: 240px;
  top: 46px;
}

.table-collapse {

  top: 45px;

}

@media (max-width: 1400px){
.listing-filter {
   
    margin: 0px auto;
    
    margin-bottom:30px;
}}

#addBuilding.modal-content{
  border:0px;
}
#addBuilding .modal-body{
  font-family: 'Metropolis-Regular';
  padding: 0px;

}
#addBuilding .listing-creator-section{
  margin: 0px;
}
#addBuilding .listing-creator-section .listing-block{
  margin: 0px;
  padding-bottom:20px;

}
#addBuilding .modal-dialog {
  max-width: 950px;
}
#ListingModal .excListing{
  background: #F26C52;
  border-radius: 2px;
  font-size: 14px;
  color:#fff;
  padding: 2px 10px;
  text-align: center;
  font-family: 'Metropolis-Regular';
}

#ListingModal .openListing{
  background: #056e02;
  border-radius: 2px;
  font-size: 14px;
  color:#fff;
  padding: 2px 10px;
  text-align: center;
  font-family: 'Metropolis-Regular';
}
#ListingModal .listing-update{
  font-family: 'Metropolis-Regular';
  font-weight: 700;
  font-size: 14px;
}
.table-collapse{
  top:48px;
}
.table-collapse.quickpost {
  left: -650px;
  width: 800px;
  top:46px;
  
}
.table-collapse.quickpost .listing-creator-section .listing-block .slider-frame{
  width: 100%;
}
.table-collapse.quickpost .listing-creator-section .listing-block .carousel .slider-wrapper{
  padding: 0px;
}
.table-collapse.quickpost .listing-creator-section .listing-block .add-image-block {
  height: 180px;
}
.table-collapse.quickpost .listing-creator-section .listing-block .slider-frame img{
  height: 182px;
}
.table-collapse.quickpost .listing-block{
  margin:0;
}
.table-collapse.quickpost .listing-creator-section{
  margin: 0px;;
}

.add-new-building .listing-creator-section .listing-block .neighbor-filter.black{
  background-color: #fff;
  border:0px;
  margin-left: 20px;
}
.add-new-building .listing-creator-section .listing-block .neighbor-filter select{
  border:0px;
  border-bottom: 1px solid #999;
  border-radius: 0px!important;
}

.sidebar-nav span{margin-left:9px;}

.content .work-section .dashboard .table.agent-listing td {padding:10px 16px}

.react-tabs {
  padding: 20px 0px;
}

.react-tabs__tab-list li:first-child {margin-left:30px;} 

.content .work-section .dashboard .table.agent-listing tr td:first-child,
tr td:first-child {
    padding-left:32px;
}

.content .work-section .dashboard .table.agent-listing tr td:first-child,
tr td:last-child {
    padding-right:32px;
}

.content .work-section .dashboard .table.agent-listing th:first-child{
    padding-left: 32px;
}

.content .work-section .dashboard .table.agent-listing th:last-child {
    padding-right: 32px;
}
.content .work-section .dashboard .table td {
  
  padding: 12px 15px;
}
.dashboard .table thead th { padding: 18px 15px;}
.editsmall i:hover{color:#F26C52!important}

.clientDashboard .table thead th {
  padding: 18px 35px;
}
.content .work-section .dashboard .table.agent-listing tr td:first-child {padding-left:32px!important}
.content .work-section .dashboard .table.agent-listing tr td:last-child {padding-right:32px!important}

.agent-a4-section{
  height: 78vh;
  overflow: hidden;
}
.agent-a4-section .editing-area{
  height:60vh;
  overflow: auto;
}
.a4-footer{
  padding: 20px 40px 0 30px
}
.content .work-section .dashboard .table.agent-listing thead th:first-child {padding-left:32px!important}
.content .work-section .dashboard .table.agent-listing thead th:last-child {padding-right:32px!important}

.dashboard .table thead th:first-child {padding-left:32px!important}
.dashboard .table thead th:last-child {padding-right:32px!important}

.agent-a4-section{
  height: 78vh;
  overflow: hidden;
}
.agent-a4-section .editing-area{
  height:60vh;
  overflow: auto;
}
.a4-footer{padding: 20px 40px 0 30px}
.resendLink{text-transform: uppercase;display: block; font-size: 12px}
.a4-footer{
  padding: 20px 40px 0 30px
}

.noticeAlert { -webkit-animation: css 0s 20s forwards; animation: css 0s 20s forwards; }

@-webkit-keyframes css  { to { visibility: hidden; height: 0px; } }

@keyframes css  { to { visibility: hidden; height: 0px; } }

.sidebar {
  z-index: 1;
}

.dashboard .table tr td:first-child {padding-left:32px!important}
.dashboard .table tr td:last-child {padding-right:32px!important}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 10px;
  text-overflow: '';
}
.noticeAlert { -webkit-animation: css 0s 6s forwards; animation: css 0s 6s forwards; }

@keyframes css  { to { visibility: hidden; height: 0px; } }

.listing-creator-section .listing-block .neighbor-filter select {width:100%}
.check-block .form-group {margin-left: 10px;}

#priceEdit{
  z-index: 9999;
  background: #FFFFFF;
}
#bedbathsqEdit{
  z-index: 9999;
  background: #FFFFFF;
}
#incentiveEdit{
  z-index: 9999;
  background: #FFFFFF;
}
#amenitiesEdit{
  z-index: 9999;
  background: #FFFFFF;
}
#descriptionEdit{
  z-index: 9999;
  background: #FFFFFF;
}
#dateEdit{
  z-index: 9999;
  background: #FFFFFF;
}
#furnishedEdit{
  z-index: 9999;
  background: #FFFFFF;
}
#imageEdit{
  z-index: 9999;
  background: #FFFFFF;
}
#editVR{
  z-index: 9999;
  background: #FFFFFF;
}
#furnishedEdit{
  z-index: 9999;
  background: #FFFFFF;
}
#salesEdit{
  z-index: 9999;
  background: #FFFFFF;
}
#marketingEdit{
  z-index: 9999;
  background: #FFFFFF;
}
#phoneEdit{
  z-index: 9999;
  background: #FFFFFF;
}
#contactEdit{
  z-index: 9999;
  background: #FFFFFF;
}
#emailEdit{
  z-index: 9999;
  background: #FFFFFF;
}
#noteEdit{
  z-index: 9999;
  background: #FFFFFF;
}
.form-panel .form-widget .form-control.text-area {
  padding-bottom: 0px;
}
#terms_and_condition_two{margin-right:7px;}
.sc-gZMcBi{height:26px;}

.scrollarea .listing-landing{
  padding-top: 250px;
}
.btn.btn-big.btn-expire {
  background: #F26C52;
  border: 1px solid #F26C52;
}
 .list-filter{
  border-bottom: 1px solid #A7A7A7;
  border-right: 0px!important;
  cursor: pointer;
}
.boxes {
  margin: auto;
  padding: 20px;
  background: #EDF2F1;
  position: absolute;
  border:1px solid #C1C5C4;
  z-index: 9999;
}

/*.sidebar{
  background:url(/bg_sidebar.png) center!important;
  box-shadow:3px 6px 0px rgba(0, 0, 0, .5)
}

.sidebar .sidebar-nav li a{
  color:#000;
}*/
.sc-gZMcBi{
  padding-top:5px;
}
#acceptSchudling .btn.btn-default{
  min-width: auto;
  width: 100%;
}
#acceptSchudling .btn.btn-small {
  font-size: 12px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 10px 25px;
  width: 100%;
  color:#000;
}
#acceptSchudling .btn.btn-small:hover{
  background: #004F6B;
  color:#fff;

}


#cancelAvailableSchedule .btn.btn-default{
  min-width: auto;
  width: 100%;
}
#cancelAvailableSchedule .btn.btn-small {
  font-size: 12px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 10px 25px;
  width: 100%;
  color:#000;
}
#cancelAvailableSchedule .btn.btn-small:hover{
  background: #004F6B;
  color:#fff;

}

#ReAssignShowing .btn.btn-default{
  min-width: auto;
  width: 100%;
}
#ReAssignShowing .btn.btn-small {
  font-size: 12px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 10px 25px;
  width: 100%;
  color:#000;
}
#ReAssignShowing .btn.btn-small:hover{
  background: #004F6B;
  color:#fff;

}



#cancelAvailableSchedule .btn.btn-small:hover{
  background: #004F6B;
  color:#fff;

}

#ReAssignShowing .btn.btn-default{
  min-width: auto;
  width: 100%;
}
#ReAssignShowing .btn.btn-small {
  font-size: 12px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.42), 0px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 10px 25px;
  width: 100%;
  color:#000;
}
#ReAssignShowing .btn.btn-small:hover{
  background: #004F6B;
  color:#fff;

}
.mobilebtn {
  background: #FFFFFF;
    font-size: 12px;
  box-shadow: 0px 2px 2px rgba(153, 153, 153, 0.2);
  border-radius: 3px;
  padding: 10px 25px;
    border: 1px solid rgba(0, 0, 0, 0.75);
}
.strip-bg{
  background:url(/bg_sidebar.png) center;
  min-height:calc(100vh - 68px);  
  background-attachment: fixed;
}

body{
  background: #fff;
}	
/*Checkboxes styles*/
.boxes input[type="checkbox"] { display: none; }

.sidebar {
  z-index: 1;
}

.form-panel .form-widget .form-control.text-area {
  padding-bottom: 0px;
}
.boxes input[type="checkbox"] + label {
  display: block;
  position: relative;
  padding-right: 35px;
  margin-bottom: 10px; 
  color: #000;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  display: block;
  width:100%;
}

.boxes input[type="checkbox"] + label:last-child { margin-bottom: 0; }

.boxes input[type="checkbox"] + label:before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  background: #074F6A;
  border-radius: 50%;
  right: 0;
  top: 0;
  opacity: 0;
  transition: all .12s, border-color .08s;
}

.boxes input[type="checkbox"]:checked + label:before {
  opacity: 1;
}

.boxes label{
  float: left;
}
.splitter-layout{
  padding-left: 30px;
  overflow:initial!important;
  height: 100vh!important;
}
.sidebar-nav span {
  margin-left: 9px;
  top: -3px;
  position: relative;
  display: inline-block;
  padding: 16px;
  padding-top: 0px;
  max-width: 25%;
  vertical-align: top;
}

.sidebar{
  background: none;
  background: url(/bg_sidebar.png) center;
  box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.25);
}
.sidebar .sidebar-nav li.active a, .sidebar .sidebar-nav li a:hover, .sidebar .sidebar-nav li a:focus{
    color: #333;
    background:rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
.splitter-layout .box{
  box-shadow: none!important;
  display: inline-block;
  padding: 16px;
  padding-top: 0px;
  max-width: 25%;
  vertical-align: top;
}
.splitter-layout .list-frame .img-wrap{
  height: 200px;
}

.mobilebtn {
  background: #FFFFFF;
    font-size: 12px;
  box-shadow: 0px 2px 2px rgba(153, 153, 153, 0.2);
  border-radius: 3px;
  padding: 10px 25px;
    border: 1px solid rgba(0, 0, 0, 0.75);
}

.form-panel .form-widget .pagination li i {
  font-size: 14px;
}

.page-link .sc-gZMcBi {
  margin-top: 6px;
}
.splitter-layout .map-section{
  margin: 16px 30px;
  margin-top: 0px;
}

.splitter-layout > .layout-splitter{
  width: 3px!important;
  background: #ccc!important;
  box-shadow: 4px 4px 8px #ccc;

}
.sidebar .sidebar-nav li a {
  color: #333;
  font-size: 18px;
  font-weight: 800;
  padding: 10px;
  text-decoration: none;
  display: block;
}

.svgicon{
  fill:#333;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

#editlandlordname{
  z-index: 9999;
  background: #FFFFFF;
}

#editcontact{
  z-index: 9999;
  background: #FFFFFF;
}
.listing-result .list-frame .star-block{
  display: block;
  width: 34px;
  height: 32px;
}
.listing-result .list-frame .star-block.active .star, 
.listing-result .list-frame .star-block:hover .star{
  fill:#F26C52;
}

.scrollarea .listing-landing {
  padding-top: 230px;
}
.star-block svg{
  float: right;
  position: relative;
  top:-2px;
  right: 5px;
}
.star-block.active{
  background: none;
  color: #000;
  border-color:#000;
}
.star-block.active .star,
.star-block:hover .star{
  fill:#F26C52;
}

.listing-result{
  position: relative;
   max-width: 1400px;
   margin: 0px auto;
   display: block;
   height: 100vh;
}
.listing-result .splitter-layout{
 left: 0px;
 top:0px;
}
.splitter-layout .box{
 width: 50%;
 max-width: 50%;
 min-width: 320px;
}
.layout-pane::-webkit-scrollbar-track
{
  background-color: #F5F5F5;
  border-radius: 4px;
}

.layout-pane::-webkit-scrollbar
{
	width: 6px;
  background-color: #F5F5F5;
  border-radius: 4px;
}

.layout-pane::-webkit-scrollbar-thumb
{
  background-color: #004f6b;
  border-radius: 4px;
}

.standardTemp header {height:135px!important}
.standardTemp table {line-height: 16px!important}

.agentlistinner {height:auto!important}
.standardTemp header {height:135px!important}
.standardTemp table {line-height: 16px!important}


@media screen and (max-device-width: 992px) and (orientation: landscape) {
  .landing-section {
    padding-top: 20%;
  }
}
.sidebar-nav span {padding:0;}

#buildingModal .modal-header {padding:10px 28px;}
#buildingModal .modal-body {padding:28px;}
#buildingModal .detail-box.landload-box h3.title {text-transform: capitalize}
.listing-creator-section .listing-block .slider-frame .add-img{height: 198px;}
 @media (max-width: 1080px){.listing-creator-section .listing-block .slider-frame .add-img{height: 128px;}}
.sidebar .sidebar-nav li a {
  color: #333;
  font-size: 18px;
  font-weight: 800;
  padding: 10px 10px 7px;
  text-decoration: none;
  display: block;
  line-height: 25px;
}
.sidebar .sidebar-nav {
  padding: 30px 22px;
  margin: 0px;
  width: 100%;
}

@media (max-width: 992px){
 .splitter-layout .box{ 
   width: 100%;
   max-width: 100%;
}
}
/*
@media (max-width: 1500px){
.splitter-layout .box {
  max-width: 49%;
  padding: 12px;
  min-width:49%;
}
}
#buildingamenities{
  z-index: 9999;
  background: #FFFFFF;
}
#defaultapptemplate{
  z-index: 9999;
  background: #FFFFFF;
}
#reqadditionaldoc{
  z-index: 9999;
  background: #FFFFFF;
}
#editbuildingnotes{
  z-index: 9999;
  background: #FFFFFF;
}



.listing-creator-section .listing-block .slider-frame .add-img{
  height: 198px;
}

@media (max-width: 1080px){
  .listing-creator-section .listing-block .slider-frame .add-img{
    height: 128px;
  }
}
@media (max-width: 850px){
  .splitter-layout .box {
    max-width: 49%;
    padding: 12px;
  }
  }
  */
.listingDiv{
  margin: 6px 345px;

}



/*------START REQUEST SCHEDULE CSS 22FEB 2019------*/
#CreateShowing .modal-dialog{
  max-width: 375px;
}
#CreateShowing .modal-content{
  min-width: auto;

}
#CreateShowing .modal-header{
  height: 52px;
  border:0px;
  background:#004F6B;
  padding: 15px 28px;
}
#CreateShowing label{
  font-size: 12px;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 0px;
  font-family: 'Metropolis-Regular';
}

#CreateShowing .form-control{
  border:0px;
  border-radius:0px;
  border-bottom:1px solid #999;
  height:28px;
  padding:0px;
  outline: none;
  font-weight: bold;
  font-size: 16px;
  color:#000;
  font-family: 'Metropolis-Regular';
  background: none;
}
#CreateShowing .form-control::-webkit-input-placeholder{
  color:#000;
}
#CreateShowing .form-control::placeholder{
  color:#000;
}
#CreateShowing .cl-btn{
  outline: none!important;
  height: 20px;
  background: none!important;
  border:0px!important;
}
#CreateShowing .light-text{
  opacity: .5;
}
#CreateShowing p.title{
  font-size: 12px;

}
#CreateShowing .form-group{
  margin-top: 10px;
  margin-bottom: 10px;
}
#CreateShowing .modal-body{
  padding: 15px 30px;
}
#CreateShowing .close{
  font-size: 42px;
  line-height: 30px;
  position: absolute;
  top:20px;
  right:30px;
  padding: 0px;
 
}

.rental-application.listing-creator-section .boxes{
  width: 92%;
  padding-top: 0px;
}
.rental-application.listing-creator-section .listing-block .neighbor-filter{
  border-right: 0px;
  padding-left: 10px;
}
.rental-application.listing-creator-section .listing-block .neighbor-filter.black{
  padding-left: 0px;
}
.rental-application.listing-creator-section .listing-block .neighbor-filter select{
  border:0px;
  background: #fff;
  border-bottom: 1px solid #999;
}


header .getstart {line-height: 18px;}
header.FrontHeader{
  padding: 0px;
  padding-left: 30px;
  padding-right: 30px;
  
}
header.FrontHeader .navbar-nav li{
  margin-left: 0px;
  
}
header.FrontHeader  .navbar-nav li a{
  font-size: 16px;
}
.FrontHeader .navbar-nav li {
  padding: 0px 10px;
}
@media (max-width:1350px){
  .FrontHeader .navbar-nav li {
    padding: 0px 5px;
  }
}
@media (max-width:1240px){
  header.FrontHeader{
    padding: 0px;
    padding-left: 10px;
    padding-right: 0px;
    
  }
}
.listingDiv{
  margin: 6px 345px;
}


#editbuildingnotes .modal-dialog,
#editlandlordname .modal-dialog,
#editcontact .modal-dialog,
#buildingamenities .modal-dialog,
#defaultapptemplate .modal-dialog,
#reqadditionaldoc .modal-dialog {margin-top:18%!important}
.scrollarea .listing-landing {
  padding-top: 200px;
}
.markercluster-map{
  width: 100%!important;
  margin-bottom: 0px!important;
  
}
.splitter-layout .box{
  max-width: auto;
  width: auto;
}
.splitter-layout .layout-pane.layout-pane-primary{
  display: grid;
  position: relative;
  min-width: 320px;
}

.splitter-layout .layout-pane{
  min-width: 600px;
  max-width: 1000px;
}
.splitter-layout .layout-pane.layout-pane-primary{
  min-width: 320px!important;
  padding-right: 15px;
}
.splitter-layout .layout-pane .modal-content{
  min-width: 100%;
}
.splitter-layout .layout-pane #ListingModal .modal-dialog{
  max-width: 100%;
  margin: 0px;
}
.splitter-layout .layout-pane #ListingModal .col-sm-9{
  padding-left: 0;
}
.splitter-layout .layout-pane #ListingModal .col-sm-3{
  padding-right: 0px;
}
.listing-result .splitter-layout .list-frame{
  border: 0px;
}
.splitter-layout .box{
  height: 100%;
    width: 100%;
    min-width: 100%;
    max-width: 100%;;
    margin: 0;
    padding: 0;
    border: 3px solid #d8d8d8;
    background-color: #fff;
    background-image: url(https://static.triplemint.com/jupiter/58c33b8â€¦/static/images/light-pattern.png);
    background-repeat: repeat;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
    position: relative;
}
.listing-result .splitter-layout .list-frame .img-wrap{
  width: 100%;
  height: 45%;
  position: absolute;
  top: 0;
  background-position: center!important;
  left: 0;
}
.listing-result .splitter-layout .list-frame .listing-info{
  width: 100%;
  position: absolute;
  height: 55%;
  bottom: 0;
  left: 0;
}
.splitter-layout  .block{
    display: grid;
    grid-auto-rows: 360px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 20px;   
    position: relative;
   
}
.listing-filter .advance-search{
  z-index: 9999;
}


#mobileListpopup {position: absolute; z-index: 9999; top:0; left: 0; display: none;}
#mobileListpopup .modal-dialog {margin: 0!important}
.mobileCloseBtn {display: none; position: absolute; top:0px; right:10px; z-index: 9999;}
.carousel .slide {
   max-height: 435px;
}
.boxes {
  z-index: 99999;
}
#searchr .modal-content{
  min-width: 100%;
}
#searchr .modal-dialog{
  max-width: 800px;
}
#searchr .listing-creator-section .listing-block{
  border: 0px;
  padding: 0px;
}
#searchr .listing-creator-section {
  margin: 0px 15px;
}
#searchr .quickpost + .btn{
  margin-left:15px;
}

#ListingModal.modal{
  
  overflow: auto !important;
}


#ListingModal.modal::-webkit-scrollbar {
  width: 0px;
  overflow: auto !important;
}

#ListingModal.modal::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

#ListingModal.modal::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

#ListingModal.modal ::-webkit-scrollbar-thumb {
  background:rgba(255,0,0,0.1) !important; 
  
}
#openMsg,#openMsg_r,#closeMsg_r,
#closeMsg{

  margin: 5px 2px;
  float:left;
}
#closeMsg, #closeMsg_r{
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.hidden{
  display: none;
}
@media (max-width:767px){
  .splitter-layout{
    padding-left: 0px;
  }
  .splitter-layout > .layout-splitter + .layout-pane{
    display: none;
  }
  .splitter-layout .box{
    border:0px;
  }
  .splitter-layout .layout-pane.layout-pane-primary{
    padding: 0px;
    margin: 0px;
    
  }
  .splitter-layout > .layout-splitter{
    display: none;
  }
  .desktop-view{
    display: none;
  }
  .mobileCloseBtn {display: block;}
  .carousel .slide {
    
    max-height:210px!important;
    
}
#mobileListpopup {display: block;}

#mobileListpopup  #ListingModal .modal-header {
    height: auto;
      padding: 15px;
}
#mobileListpopup  #ListingModal .modal-header .text-right{
  text-align: left!important;
}
#mobileListpopup #ListingModal .modal-body {
  padding: 15px;
  padding-top: 0px;
}
.listing-filter .input-range__label--max .input-range__label-container:before {
  right: 14px;
}
.hidden{
  display: block;
}
}

.icon-wrap.inactive{
  background: url(/inactive.png) no-repeat;
  height: 21px;
  position: relative;
  top:4px;
} 
@media (max-width:767px){ 

  .conSnapScroll{overflow-y: inherit!important;}
}
/* ----------- Non-Retina Screens ----------- */
@media screen 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .scrollarea .listing-landing {
      padding-top: 150px;
  }
}
  .duration-widget .container-rd input:focus ~ .checkmark{
    background: none!important;
  }
  .duration-widget .container-rd input:checked ~ .checkmark{
    background: #004f6b!important;
  }
  .duration-widget .container-rd input:focus ~ .txt {
    color:#000!important;
  }
  .duration-widget .container-rd input:checked ~ .txt{
    color:#fff!important;
  }


  #ListingModal .listing-banner {
    margin-top: 20px;
}

.listing-banner .carousel .slide {
  max-height: 400px;
}

.listing-banner .carousel .slide img {
  width: auto;
  max-height: 400px;
}
  .listing-filter .advance-search .filter-block .checkbox-inline {
    display: inline-block;
    width: 185px;
  }
.duration-widget input[type="checkbox"] { display: none; }

.duration-widget input[type="checkbox"] + label {
  display: block;
  position: relative;
  padding-left:0px;
  margin-bottom: 20px;
  color: #000;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  width: 32px;
  height: 32px;
  margin: 2px 2px 2px 3px;
  float: left;

  
 
}
.duration-widget input[type="checkbox"] + label .txt{
  position: absolute;
  top:1px;
  left: 11px;
  z-index: 99999;
  font-weight: 700;
}

.duration-widget input[type="checkbox"] + label:last-child { margin-bottom: 0; }

.duration-widget input[type="checkbox"] + label:before {
  content: '';
  display: block;
  width: 32px;
  height: 32px;
  border:0px solid #6cc0e5;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: .6;
  transition: all .12s, border-color .08s;
}

.duration-widget input[type="checkbox"]:checked + label:before {
  width: 32px;
  height: 32px;
  top:0px;
  left:0px;
  border-radius: 0;
  opacity: 1;
  border-radius: 4px;
  background: #004F6B;
 
}
.duration-widget input[type="checkbox"]:checked + label .txt{
  color:#fff;
}

#cityList {
  max-height: 475px;
  overflow-y: auto;
}
.listing-result .list-frame .star-block{
  position: absolute;
  bottom: 60px;
  right:25px;
}
.react-datepicker__time-container {
  width: 100px!important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{width:100px!important}

.cityStateZip {margin-top:20px; padding:0px!important;}
.cityStateZip .city {padding:0px!important; margin-right:10px;max-width: 86px}
.cityStateZip .state {padding:0px!important; margin-right:10px;max-width: 86px}
.cityStateZip .zip {padding:0px!important; margin-right:0px;max-width: 86px}
.duration-widget input[type=checkbox]+label .txt {
  z-index: 999; 
}
.additionalImg{margin:5%; width:90%}
.successMsgUpload{
  background: #004f6b;
  color: #fff;
   text-align: center;
  font-size: 12px;
  padding: 12px;
  border-radius: 4px;
  margin-bottom:15px;
  }
.docNameHead{margin-bottom:5px!important; text-align: left; font-size: 14px;}
@media (max-width: 767px){
.FrontHeader .navbar-nav.mr-auto {
    left: 0px;
}
.FrontHeader .navbar-collapse {
  margin-top: 44px;
  
}
.navbar-nav.mr-auto, .navbar-nav.mx-auto {
  margin-left: -5px!important;
  padding-left: 5px;
}
header.FrontHeader {
  padding: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
.mr-auto li:last-child{padding-bottom: 5px}
.FrontHeader:before{
  display: none;
}
}

/*MS_426* 09May 2019*/

.FrontHeader:before{
  position: absolute;
  width: 100%;
  height: 25px;
  content: '';
  display: block;
  z-index: 11;
  left: 0px;
  bottom:-25px;
  background: url(/frontheaderbg.png) bottom repeat-x;
  border: 1px solid rgba(0,0,0,.5);
}
@media (max-width: 767px){
.FrontHeader:before{
  display: none;
}
}


 /* MS_340_v3*/
.dashboard-wrap .pay-btn{
  max-width: 300px;
  margin: 0px auto;
  display: block;
}
.styles_overlay__CLSq-{
  padding: 0px;
}

 @media (max-width: 1050px){
.navbar-nav li{
    margin-left: 12px;
}
.navbar-nav li a {
    font-size: 13px;
}
.logo{
  width: 180px;
  margin-left: 0px;
  margin-top: 6px;
}
}
 @media (max-width: 767px){
.logo{
    width: 200px;
    margin-left: 15px;
    margin-top: 0px;
  }
.navbar{
    z-index: 999!important;
}
.navbar-nav li {
 margin-left: 0px;
 padding: 5px 15px
}
.navbar-nav li a {
  font-size: 18px;
}
.list-popup .listing-banner .carousel .slide img{
    width: 100%;
}
.list-popup #ListingModal .listing-banner {
  margin-bottom: 20px;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}
}




/*MS390*/
.listing-filter.agent-filter .panel_1{
  display: inline;
  padding: 10px 0;
  margin:0 10px;
  width: auto;
  min-width: 113px;
}
.listing-filter.agent-filter .panel_1 .neighbor{
  min-width: 900px;
}
.listing-filter.agent-filter .panel_1 .neighbor .react-tabs{
  padding: 0px;
}
.listing-filter.agent-filter .panel_1 .neighbor .react-tabs__tab-list li{
  font-size: 14px;
}
.listing-filter.agent-filter .panel_1 .neighbor .react-tabs__tab-list li:first-child {
  margin-left: 0px;
}

.listing-filter.agent-filter .panel_1 .neighbor .check-box{
  padding-left: 0px;

}
.listing-filter.agent-filter .panel_1 .neighbor .check-box .form-group label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  margin-left: 0px;
  margin-bottom: 2px;
}
.listing-filter.agent-filter .panel_1 .neighbor .checkbox-inline{
  display: inline-block;
}
.listing-filter.agent-filter .panel_1 .neighbor .check-box .form-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #333;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 3px;
  opacity: 1;
}
.listing-filter.agent-filter .panel_1 .neighbor .check-box .form-group input:checked+ label:before{
  background: #333;
}
.listing-filter.agent-filter .panel_1 .neighbor  .check-box .form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 9px;
  width: 4px;
  height: 12px;
  border: solid #E4E1DC;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.listing-filter.agent-filter .panel_1 .neighbor .react-tabs__tab-panel .form-group{
  display: inline-block;
}


.listing-filter.agent-filter .panel_1 .neighbor .react-tabs__tab-panel .react-tabs__tab{
  margin-left: 0px;
  font-size: 14px;
 text-transform: capitalize;
 padding: 0px!important;
 margin-right: 10px;
 position: relative;
 top:-8px;


}
.listing-filter.agent-filter .panel_1 .neighbor .react-tabs__tab-panel .react-tabs__tab .form-group {
  margin-bottom: 0px;
}

.listing-filter.agent-filter .panel_1 .neighbor .react-tabs__tab-panel .react-tabs__tab .form-group label{
  padding-right: 10px;
  font-size: 14px;
  text-transform: capitalize;
}
.listing-filter.agent-filter .panel_1 .neighbor .react-tabs__tab-panel--selected .form-group{
  margin-bottom: 2px;
  font-size: 14px;
  display: block;

}
.listing-filter.agent-filter .panel_1 .neighbor .check-box .form-group{
  display: inline-block;
}
.listing-filter.agent-filter .panel_1 .neighbor .check-box .form-group label{
  display: inline;
  margin-right: 0px;
  padding-right: 0px;
  width: 24px;
}
.listing-filter.agent-filter .panel_1 .neighbor .check-box .form-group input:checked + label:after{
  top:6px;
}

.react-tabs__tab--selected .listing-filter.agent-filter .panel_1 .neighbor .react-tabs__tab-panel .form-group input:checked+ label:before{
  background: #333;
}
.listing-filter.agent-filter .panel_1 .neighbor .react-tabs__tab-panel .form-group input:checked + label:after {
  content: '';
  display: block;
 
}

.innertab .react-tabs__tab{
  border:0px;
  border-bottom: 1px solid transparent;

}
.listing-filter.agent-filter .count{
  font-size: 12px;
}
.pricelist{
  width: 300px;
}
.common-box ul,
.pricelist ul{
  padding: 0px; 
  margin-top: 15px;
}
.common-box li,
.pricelist li{
  list-style: none;
}

.listing-filter.agent-filter .panel_1 .neighbor.common-box{
  min-width: 250px;
}

.show-only{
  position: relative;
  top:-15px;
  left: 12px;
}

.commoncheck input[type="checkbox"] {
  display: none;
}
.commoncheck .form-group label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  margin-left: 0px;
  margin-bottom: 2px;
}

.commoncheck .form-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #333;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 3px;
  opacity: 1;
}
.commoncheck .form-group input:checked+ label:before{
  background: #333;
}
.commoncheck  .form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 9px;
  width: 4px;
  height: 12px;
  border: solid #E4E1DC;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.neighbor-filter.list-filter{
  font-size: 14px;
  background-size: 15px;
}

.boxes input[type="checkbox"] + label:before{
   top:2px;
}
.listing-filter.agent-filter .panel_1 .price-list li{
  cursor: pointer;
  position: relative;
  display: block;
}
.listing-filter.agent-filter .panel_1 .price-list li:before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  background: #074F6A;
  border-radius: 50%;
  right: 0;
  top: 3px;
  opacity: 0;
  transition: all .12s, border-color .08s;
}
.listing-filter.agent-filter .panel_1 .price-list li.active:before{
  opacity: 1;
}
.listing_count .commoncheck .form-group label {
  font-size: 18px;
  font-weight: bold;
}
.listing_count  .commoncheck .form-group input:checked + label:after{
  top: 8px;
}
.ziptag ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.ziptag ul li {
	display: inline-block;
	margin: 0 .3em .3em 0;
	padding: 0;
}
.ziptag ul li {
  position: relative;
	display: inline-block;
	max-width: 100px;
	padding: 3px 18px 3px 12px;
	background-color: #fff;
	border: 1px solid #004f6b;
	border-radius: 3px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	color: #333;
	font-size: 13px;
	text-decoration: none;
	transition: .2s;
}
.ziptag ul li.active ,
.ziptag ul li:hover {
	background-color: #004f6b;
	border: 1px solid #004f6b;
	color: #fff;
}
.closeTagZip {position: absolute; display:block; font-size: 10px;; color:#004f6b; right:5px; top: 5px; width:10px; height:10px}

.ziptag ul li:hover .closeTagZip{font-size: 10px; font-weight: bold; color: #fff}

@media (max-width: 1420px){
  .listing-filter.agent-filter {
    max-width: 1000px;
  }
  .listing-filter.agent-filter .panel_1{
    min-width: 110px;
    font-size: 14px;
  }
  .listing-filter.agent-filter .panel_1.price-box{
    min-width: 75px;
  }
  .listing-filter.agent-filter .panel_1.media-box{
    min-width: 80px;
  }
}
@media (max-width: 1320px){

  .listing-filter.agent-filter .panel_1{
    min-width: 100px;
    font-size: 12px!important;
    letter-spacing: -1px;
  }

}
@media (max-width: 1200px){
  .listing-filter.agent-filter {
    height: auto;
  }
  .listing-filter.agent-filter .panel_1{
    min-width:30%;
  }
  .show-only{
    top:14px;
  }
  .listing-filter.agent-filter .panel_1 .neighbor {
    min-width: 800px;
}
.listing-filter.agent-filter .panel_1.price-box{
  min-width: 30%;
}
.listing-filter.agent-filter .panel_1.media-box{
  min-width: 30%;
}
}
@media (max-width: 767px){
    .listing-filter.agent-filter .panel_1{
    min-width:28%;
  }
}
@media (max-width: 550px){
  .listing-filter.agent-filter .panel_1{
  min-width:95%;
}
}

/*End MS_390*/

.ass-blk {
  width: 12px;
  height: 14px;
   display: inline-block;
   text-indent: -999999px;
   position: relative;
   top:2px;
   background-size: 100%;

}
.ass-blk.blank{
  background: url(/null.png) no-repeat;

}
.ass-blk.asce{
  background: url(/asce.png) no-repeat;
  
}
.ass-blk.dece{
  background: url(/dece.png) no-repeat;
}
/*MS-445*/
@media (max-width: 767px){
#ListingModal .carousel .slide .legend{
   width: 60px;
    padding: 14px 8px;
    right:20px;
  }
  #ListingModal .carousel .slide .legend .hidden-xs{
    display: none;
  }
}

/*MS-446*/

@media (max-width: 767px){
  #mobileListpopup .map-wrap{
    display: none;
  }
}

 
/*MS_438*/
.exp-content{
  font-size: 24px;
  margin: 20px 0 10px;
 
}
.exp-content p{
  margin-bottom: 0px;
}

.disableClick{pointer-events: none;
  opacity: 0.1;}
/*MS-432*/
.incentive-box {
  padding: 0px 15px;
}
.incentive-box .col-sm-4,
.incentive-box .col-sm-3,
.incentive-box .col-sm-2{
  padding: 0px;
  
}
.incentive-box p,
.incentive-box h2{
  padding-right: 6px;
}
.FrontEnd .experience-section {
  padding: 30px;
}

/*MS 425 */


.listing-result .splitter-layout .list-frame .carousel.carousel-slider {
  width: 100%;
  height: 45%;
  position: absolute;
  top: 0;
  background-position: center!important;
  left: 0;
  }
  .listing-result .splitter-layout .carousel .carousel-status,
  .listing-result .splitter-layout .carousel .control-dots,
  .listing-result .splitter-layout .list-frame .carousel .thumbs-wrapper{
  display: none;
  }
  
  .listing-result .splitter-layout .list-frame .carousel .control-next.control-arrow {
  background: url(/next.png) no-repeat center!important;
  width: 55px!important;
  opacity: 1;
  }
  .listing-result .splitter-layout .list-frame .carousel .control-prev.control-arrow {
  background: url(/prev.png) no-repeat center!important;
  width: 55px!important;
  opacity: 1;
  }
  .listing-result .splitter-layout .list-frame .slider  li a{
    padding: 0px;
  }
/* END MS 425 */


@media (max-width: 767px){
#ListingModal .carousel .slide .legend {
    
    padding: 16px 20px;
   
}}



/* MS 467*/
.styles_modal__gNwvD{
  padding: 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.styles_modal__gNwvD .modal-header{
  padding-left: 35px;
}
.styles_modal__gNwvD .modal-body{
  padding: 30px 35px;
}
.styles_modal__gNwvD .modal-body label{
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0px;
}
.styles_modal__gNwvD .modal-body .form-control{
  border-radius: 0px;
  background: none;
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  padding: 5px 0;
  height: 30px;
}
.styles_modal__gNwvD .modal-body .form-horizontal .col-sm-4 + .col-sm-4{
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  
}
.styles_modal__gNwvD .modal-body .form-horizontal .react-datepicker-wrapper{
  width: 100%;
}
.styles_modal__gNwvD .modal-body .form-group{
  margin-bottom: 5px;
}
.styles_modal__gNwvD .modal-body .btn.cl-btn.disabled,
.styles_modal__gNwvD .modal-body .btn.cl-btn:disabled{
  background: none!important;
  border:0px;
  color:#004F6B!important;
}
.styles_overlay__CLSq- {
  z-index: 9999;
}
/* End MS 467*/



/* MS 474*/
.bg-light .navbar-nav li a{
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
}

/* END MS 474*/

/* MS 473*/
header .mid-nav{
  max-width: 238px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  margin-left: -119px;
}
.FrontHeader .navbar-nav.mid-nav {
  max-width: -webkit-fill-available;
}
@media (max-width: 1080px){
.listing-filter .advance-search {
    top: 360px;
}
#scrollCont {
  z-index: 99;
}
}
@media (max-width: 992px){
header{
  height: 54px;
}
header .logo{
  margin-left: 15px;
} 
header .mid-nav{
  max-width: 100%;
  overflow: inherit;
  position:relative;
  left: 0%;
  margin-left: 0px;
} 
.FrontHeader {
    height: 60px;
}  
header.FrontHeader {
    padding: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
.FrontHeader:before{
  display: none;
}
.FrontHeader .logo {
  width: 200px;
  top: 2px;
  left: 15px;
}
.FrontHeader .navbar-dark .navbar-toggler {
  top: 18px;
}
.navbar-nav li {
  text-align: right;
  margin-left: 0px;
  margin-right: 0px;
  padding: 8px 15px;
  background: #B8CBC8;
}
.FrontHeader .navbar-nav.mid-nav{
  left: 0px;
  margin-left: 0px;
}
.FrontHeader .navbar-collapse {
  margin-top: 58px;
}
.navbar{
  padding: 0px;
}
.conSnapScroll {
  overflow-y: inherit!important;
}
.scrollarea {
  height: auto;
}
.scrollarea .workbody {
  padding-top:60px!important;
}
}
@media (max-width: 767px){
  .scrollarea .listing-landing {
    padding-top: 100px;
}
  .iconSchdule{text-align: center}
  .FrontHeader .logo {
    width: 200px;
    top: -10px;
    left: 0px;
  }
  .FrontHeader .navbar-dark .navbar-toggler {
    top: 0px;
  }
  .FrontHeader .navbar-collapse {
    margin-top: 44px;
  }
}
/* END MS 473*/
.active + label:before {opacity: 1!important;} 
/* End MS 467*/



/*  MS 390*/

#BathroomList{
  min-width: 100px;
  padding: 15px;
}
.listing-filter.agent-filter {
  z-index: 999;
}
.listing-filter.agent-filter .neighbor.common-box .scroll-list{
  max-height: 476px;
  padding:0 15px;
  overflow-y: auto;
  position: relative;
  margin: 0px -20px;
}
.listing-filter.agent-filter .neighbor.common-box .scroll-list.maxheight{
  max-height: 240px;
}
.listing-filter.agent-filter .neighbor .location-listwidget .form-group{
  display: inline-block!important;
  min-width: 31%;
  margin-right: 2%;
}
.listing-filter .neighbor-filter:focus{
  background: none;
}
.listing-filter.agent-filter  .neighbor.common-box .ziptag li{
  margin-bottom: -1px;
}
.boxes input[type="checkbox"]:checked + label:before {
  opacity: 0;
}
.boxes input[type="checkbox"]:checked.active + label:before {
  opacity: 1;
}

.inputChecks input[type="checkbox"]:checked + label:before {
  opacity: 1;
}

.dropdownUpArrow{
  background: url(/combo-dropdown.png) no-repeat 96% 0;  
}


/*Start: 27 May */
.react-tabs .react-tabs__tab--selected::after{
  display: none;
}
.react-tabs .react-tabs__tab:focus{
  box-shadow: none;
}
/*End  MS 390*/
/*24 May 209 MS 340 V4 */
.conSnapScroll{
  overflow-y: inherit;
}
@media (max-width: 1400px){
  .splitter-layout .block {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

}
.listing-result .splitter-layout .list-frame .listing-info{
  padding-top: 10px;
}
}
@media (max-width: 1200px){

.listing-result .splitter-layout .list-frame .listing-info{
  padding-top: 20px;
}
}
.scrollarea {
  height: 100vh;
}
/*End MS 340 V4 */
/*Start: MS_477*/
.conSnapScroll{
  overflow-y: inherit;
}
.scrollarea {
  height: 100vh;
}
.splitter-layout .layout-pane.layout-pane-primary .carousel .slide img{
  height: -webkit-fill-available;
  width: auto;
}
.splitter-layout .layout-pane.layout-pane-primary  .box ul li a:hover{
  background: #000000;
}
#mobileListpopup {position: absolute;z-index: 9999; top:0; left: 0; display: none; }
#mobileListpopup .modal-dialog {margin: 0!important}
.mobileCloseBtn {display: block; position: absolute; top:0px; right:10px; z-index: 9999;}
.listing-result{
  margin-bottom: 60px;
}
.splitter-layout .map-section{
  height: 100vh;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  height: 630px;
}
.listing-banner .carousel .slide .no-img{
  width: 100%!important;
  max-height: auto;
}
/*End: MS_477*/


/*27 May*/

.scrollarea {
  height: 120vh;
  
}

.neighborhoodDD input[type="checkbox"]:checked + label:before {
 opacity: 1;
}

.splitter-layout .map-section {
  height: 96vh;
}

.poppreviewImage .imageNoimage{width:100%!important}

/* END 27 May*/
#cityListRent {
  max-height: 475px;
  overflow-y: auto;
}


/* MS 488*/

.splitter-layout .layout-pane .markercluster-map{
  position: relative;
}
.splitter-layout .layout-pane .markercluster-map .cover-wrap{
  position: absolute;
  top:0px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.50);
  z-index: 99999;
  color:#fff;
	display: flex;
	align-items : center;
	justify-content : center;

}
.splitter-layout .layout-pane .markercluster-map .cover-wrap .btn.btn-default{
  background: #fff;
  border:1px solid #fff;
  color:#333;
  font-size: 16px;
  height: 54px;
  padding: 15px 40px;
  
}
.splitter-layout .layout-pane .markercluster-map .cover-wrap .btn.btn-default:hover{
  background: #004F6B;
  color:#fff;
}
.sidebar {
  background: #fff url(/bg_sidebar.png) 50%;
}
.download
{
padding-left: 5px;
}
.splitter-layout .layout-pane.layout-pane-primary {
  padding-right: 0px;
}


/*START: MS-504*/
.styles_modal__gNwvD main{
  padding: 0px;
}
.mobileCloseBtn {
  left: 10px;
  top: -5px;
}
.modal-backdrop, .modal-backdrop.fade.in{
  opacity: 0!important;
  }
@media (max-width: 767px){
  .mobileCloseBtn {
    right: 10px;
    top: -5px;
  }
}
/*END: MS-504*/
.FrontHeader .navbar-nav.mid-nav {
  max-width: -webkit-fill-available;
  max-width: -moz-available;
}


/*START: MS-514*/
.box ul li a.view-full{
  width: 100%;
  height: 20vh;
	display: flex;
	align-items : center;
	width: 100%;
	justify-content : center;
}
.box ul li a.view-full img{
  width: 100%!important;
  height: auto!important;
}
.scrollarea .goto {
  text-align: center;
  position: absolute;
  bottom: 60px;
  text-transform: uppercase;
  font-size: 24px;
  width: 100%;
  height: 100px;
  z-index: 1!important;
  }
.scrollarea {
  height: 100vh;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide{
  background: #D7D7D7;
}
.splitter-layout .layout-pane.layout-pane-primary .box ul li a:hover{
  background: #D7D7D7;
}
.splitter-layout .layout-pane .markercluster-map .cover-wrap{
  z-index: 9999;
}

@media (max-width: 1200px){
  .scrollarea {
    height: 110vh;
  }
  .scrollarea .goto{
       bottom:12vh;
  }
  .scrollarea .goto img{
    width: 80px;
    margin-top: -10px;
  }
}
@media (max-width: 767px){
.scrollarea .goto {
   font-size: 15px;
   bottom:4vh;
}
.scrollarea .goto img{
  width: 40px;
  margin-top: -10px;
}
}
@media (max-width: 380px){
  .scrollarea .listing-landing {
  padding-top: 100px;
  
  }
  .FrontEnd p.title {
  font-size: 24px;
  line-height: 24px;
  }
  }

@media screen and (max-device-width: 767px) and (orientation: landscape) {
 .scrollarea {
      height: 170vh;
  }

}

/*END: MS-514*/
.deleteBtnUpload {font-size: 14px}
.deleteBtnUpload span {text-decoration: underline; cursor: pointer; color: #F26C52}
.deleteBtnUpload span:hover {text-decoration: none; cursor: pointer; color: #F26C52}
.successMsgUpload {padding: 0; background: none; margin-bottom: 5px;}
.successMsgUpload button {
  
  background: #004f6b!important;
  color:#fff!important;
  border: 0px!important;
  
}  

.ReactModal__Overlay.ReactModal__Overlay--after-open{
  z-index: 9999!important;
}
.ReactModal__Content.ReactModal__Content--after-open.app_modal{
  max-width: 300px;
  margin: 0px auto;
  height: 85px;
  margin-top:120px;
  box-shadow: 0px 5px 13px #e3e3e3;
  border:0px!important;
  padding: 20px;
  background: #fff;
}



/*START: MS_522*/
.modal-header.top-header .topclose{
  position: absolute!important;
  left: 15px;
  top:-30px;
}

.box ul li a.view-full{
  height: auto;
}
.box ul li a.view-full img{
  position: relative;
  top:0px;
  width: 100%;
  height: -webkit-fill-available!important;
}
.splitter-layout .block {
  grid-auto-rows: 426px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
 
}
.listing-result .splitter-layout .list-frame .carousel.carousel-slider {
  height: 55%; 
}
.listing-result .splitter-layout .list-frame .listing-info {
   height: 45%;
}

@media screen and (min-device-width:800px) and (max-device-width: 900px) {
.scrollarea .listing-landing {
    padding-top: 80px;
}
.scrollarea {
  height: 200vh;
}
}

@media screen and (min-device-width: 600px) and (max-device-width: 767px) {
  .scrollarea .listing-landing {
    padding-top: 80px;
}
.scrollarea {
  height:180vh;
}
.scrollarea .goto {
  font-size: 15px;
  bottom: -8vh;
}
.listing-filter {
  max-width: 400px;
}
}
@media (max-width: 767px){
  .scrollarea .goto img {
    width: 40px;
    height: 44px;
    margin-top: -10px;
}
.scrollarea .goto {
    font-size: 15px;
    bottom: 0vh;
}
.listing-filter{
  margin-bottom: 2px;
}
.scrollarea .listing-landing {
  padding-top:72px;
}
}

@media screen and (max-device-width: 767px) and (orientation: landscape) {
  .scrollarea {
       height: 184vh;
   }
   .FrontEnd p.title {
    font-size: 25px;
 }
 .listing-filter {
    padding-top: 0;
  }
  .FrontEnd p.title {
    line-height: 11px;
  }
}
 
/*END: MS_522*/
/*START: MS-564*/
@media (max-width: 767px){
#mobileListpopup{
  display: block;
}
}
/*END: MS-564*/
@media (max-width: 767px){
  #mobileListpopup {
    display: block;
  }
  .mobileCloseBtn {
    left: 6px;
  }
  #mobileListpopup #ListingModal .modal-header h3 {
    margin-top: 10px;
  }
  }


/*MS-532*/
.table.table-striped.agent-listing th{
  vertical-align: middle;
}
.content .work-section .dashboard .table th, .content .work-section .dashboard .table td.managerAppGrid {
  min-width: 95px;
}
@media (max-width: 1500px){
.content .work-section .dashboard .table.agent-listing th{
    padding: 16px 5px!important;
}
.content .work-section .dashboard .table.agent-listing td {
    padding: 10px 5px!important;
}
.content .work-section .dashboard .table th, .content .work-section .dashboard .table td.managerAppGrid {
  min-width: 90px;
}
}
@media (max-width: 1320px){
.content .work-section .dashboard .table.agent-listing th{
    padding: 16px 0px!important;
  }
.content .work-section .dashboard .table.agent-listing td {
  padding: 10px 0px!important;
}
.content .work-section .dashboard .table th, .content .work-section .dashboard .table td.managerAppGrid {
  min-width: 100px;
}
}
/*MS-532*/

@media (max-width: 767px){
#mobileListpopup {
  display: block;
}
.mobileCloseBtn {
  left: 6px;
}
#mobileListpopup #ListingModal .modal-header h3 {
  margin-top: 10px;
}
}
/*START: MS-527*/
.modal-open .modal {
  background: transparent!important;
}
/*START: MS-527*/
  
  
/*START: MS-555*/
.FrontHeader .logo {
  width: 425px;
  position: absolute;
  left: 0;
  top: 24px;
}
.navbar {
  margin-top: -1px;
}
header {
  height: 84px;
}
.logo{
  width: 425px;
}
.FrontHeader .navbar-nav li:last-child{
  padding-right: 0px;
}
.FrontHeader .navbar-nav li a{
  padding:0px!important;
}
.FrontHeader .navbar-nav li img.user {
  margin-right: 0px;
}
@media (max-width: 1250px){
.FrontHeader .logo {
    width: 350px;
    top:30px;
  }
.logo{
  width: 240px;
}
header {
  height: 60px;
}
}
@media (max-width: 992px){
header {
    height: 54px;
}
.FrontHeader .logo {
  width: 260px;
  top: 5px;
}
}
@media (max-width: 767px){
  .FrontHeader .logo {
    top: -8px;
  }
}
/*END: MS-555*/

/*START: MS-556*/
.FrontHeader .navbar-expand-lg .navbar-nav .nav-link  {
  padding: 0px;
}
header.FrontHeader .navbar-nav li:last-child{
  padding-right: 0px;
}
.navbar-nav li img.user{
  margin-right: 0;
}
.FrontHeader .navbar-collapse {
  margin-top: 35px;
}

.FrontHeader .navbar-nav li a.btn.btn-default{
  padding:10px 20px!important;
}

/*END: MS-556*/



/*MS-465*/
.feedTab .react-tabs__tab-list li:first-child{
  margin-left: 0px;
}
.feedTab .react-tabs__tab-list{
  margin-bottom: 10px !important;
  
}


/*START: MS-577*/


/*START: MS-539*/
.carousel .control-disabled.control-arrow {
  opacity: 1!important;
  cursor: inherit;
  display:block!important;
}
.carousel.carousel-slider .control-arrow {

  z-index: 999999;
}
/*END: MS-577*/
/*END: MS-539*/

.content .work-section .dashboard .table.agent-listing thead th, .content .work-section .dashboard .table th{
  white-space: nowrap;
}




/*START: MS-580*/
.emailpopup .form-section {
  min-height: auto!important;
  padding-top:0px!important;
  padding-bottom: 20px!important;
}
.emailpopup .modal-content{
  border:2px solid rgba(0, 0, 0, 0.2);
}
.emailpopup  .cl-btn {
 width: auto!important
}
.emailpopup  .modal-body.profile{
  padding-bottom: 20px;
  padding-top:10px;
}
/*End: MS-580*/


/*Start: MS-539*/
@media (max-width: 1250px){
.logo {
    width: 230px;
    margin-top: 5px;
}
}
/*End: MS-539*/
/*START: MS-549*/
#ListingModal .incentive-box h4{
  font-size: 15px;
}
#ListingModal .incentive-box .textWrap{
  word-break: break-word;
}
#ListingModal .incentive-box .textWrap p{
  line-height: 12px;
  padding-right: 0px;
}
/*END: MS-549*/
  /*Start: MS-508*/
  .agentprofile{
    margin-bottom: 20px;
  }
.agentprofile .agentprofile-wrap{
    position: relative;
  }

.agentprofile .agentprofile-wrap{
    padding: 20px;
    border:1px solid #ccc;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.20);
  }

.agentprofile .agentprofile-wrap .table{
    box-shadow: none;
  }
.agentprofile .agentprofile-wrap .form-control{
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.1), 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.agentprofile .agentprofile-wrap  .table.infotable tr{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .agentprofile .agentprofile-wrap  .table.infotable tr:last-child{
    border: 0px;
  }
.agentprofile .agentprofile-wrap  .table.infotable td:first-child{
    padding-left: 0px!important;
  }
  .agentprofile .agentprofile-Edit{
    position: relative;
    margin-left: 60px;
    top:-40px;
  }
.agentprofile .agentprofile-Edit .agentprofile-img{
    width:100px;
    height: 100px;
    overflow: hidden;
    background-size: cover !important;
  }

.agentprofile .agenteditwrap{
  border:0;
  box-shadow: none;
}

.agentprofile .profile-edit{
  top:0;
  margin-left:0px;
  text-align: center;
}

.agentprofile .profile-edit .agentprofile-img{
  margin: 0px auto;
} 
.upload-btn-wrapper.edit-upload{
  overflow: hidden;
  height: 40px;
  position: relative;
  margin-bottom: 20px;
}
.upload-btn-wrapper.edit-upload .btn{
  box-shadow: none;
}
.upload-btn-wrapper.edit-upload .form-group{
  z-index: 1px;
  top:0px;
  height: 40px;
  position: absolute;
  opacity: 0;
}
.agentprofile .profile-edit .delete{
  color:#004F6B;
  font-size: 14px;
  font-weight: bold;
}
.agentprofile .profile-edit .delete:hover{
  text-decoration: underline;
}
.agentprofile .profile-edit .user-btn-group{
  padding: 0px;
  margin: 0px;
  max-width: 220px;
}
.agentprofile .profile-edit .user-btn-group li{ 
  list-style: none;
  margin-bottom: 15px;
}
.agentprofile .profile-edit .user-btn-group .btn{
  width: 100%;
}
.agentprofile-wrap.agenteditwrap .col-md-3,
.agentprofile-wrap.agenteditwrap .col-md-9{
  padding-left: 0px;
}
.agentprofile .agenteditwrap .text-area,
.agentprofile .agentprofile-wrap .form-control{
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.1), 0px 3px 10px rgba(0, 0, 0, 0.1)!important;
  border-radius: 3px;
}
.agentprofile .agenteditwrap .text-area{
  height: 80px;
}
.agentprofile .visible{
  display:none;
}
.agentprofile .hidden{
  display: block;
}
.agentprofile{
  border-top:1px solid  rgba(0, 0, 0, 0.2);
  padding-top:30px;
}
.agentprofile.view-profile{
  border-top:0px;
  padding-top:0px;
}
@media (max-width: 992px){
  .agentprofile .visible{
    display:block;
  }
  .agentprofile .hidden{
    display: none;
  }
  .agentprofile{
    margin-top: 30px;
  }
  .agentprofile .profile-edit{
    padding: 20px 0;
  }
}
@media (max-width: 767px){
  .agentprofile .profile-edit .user-btn-group {
    margin: 0px auto;
    margin-top: 10px;
}
}
  /*END: MS-508*/


  /*Start: MS-606*/
.listing-filter .advance-search {
    z-index: 99999;
}
.carousel.carousel-slider .control-arrow {
  z-index: 9999;
}
  /*End: MS-606*/

/*Start: MS-607*/
.listing-section {
  font-family: 'Cormorant Garamond', serif;
}

.listing-section .listing-header{
  color: #333;
  display: block;
}

.listing-section .listing-header h3 {
  font-family: 'Metropolis-Regular';
  font-size: 30px;
}

.listing-section .listing-header p {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
}

.listing-section .listing-header .listing-price{
  font-size: 40px;
}

.listing-section .listing-slider .carousel .control-next.control-arrow {
  background: url(/next.png) no-repeat center!important;
  width: 55px!important;
  opacity: 1;
}

.listing-section .listing-slider .carousel .control-prev.control-arrow {
  background: url(/prev.png) no-repeat center!important;
  width: 55px!important;
  opacity: 1;
}

.listing-section .listing-details {
  padding: 30px 25px;
}


.listing-section .listing-details h4 {
  font-family: 'Metropolis-Regular';
  font-size: 18px;
  border-bottom: 1px solid #BEB7B3;
  padding-bottom: 5px;
}

.listing-section .listing-details p {
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  font-family: 'Cormorant Garamond', serif;
}

.listing-section .listing-details .listing-discription {
  margin-bottom: 15px;
}

.listing-section .listing-details .listing-amenities ul {
  padding: 0px;
  margin-bottom: 40px;
}

.listing-details .listing-info .listing-info-img{
  margin-bottom: .5rem;
}

.listing-section .listing-details .listing-amenities li {
  list-style: none;
  display: inline-block;
  background: url(/list-arrow.png) no-repeat left;
  padding-left: 10px;
  margin-right: 15px;
  font-size: 12px;
}

.listing-section .listing-details .listing-discription th{
  text-align: right;
  padding-right: 20px;
  width: 110px;
}

.listing-section .listing-details .listing-info{
  border: 1px solid #BEB7B3;
  padding:30px;
  max-width: 220px;
  margin: 0px auto;
  margin-bottom: 40px;
}

.carousel .carousel-status{
  display: none;
}
@media (max-width: 767px){
.listing-section .listing-details {
  padding: 30px 0px;
}
.listing-section .listing-header h3 {
  font-size: 24px;
}
.listing-section .listing-header .listing-price {
  font-size: 30px;
}
.listing-section .listing-header .text-right{
  text-align: left!important;
}
}

/*End: MS-607*/


/*Start: MS-640*/
.react-tabs__tab-panel--selected main{
  padding-top: 0px;
}
.listing-creator-section .listing-block select.form-control {
  height: 25px!important;
}
.listing-creator-section .listing-block label {
    margin-top: 10px;
}
.rental-application .form-control.alert-control {
   border-bottom: 2px solid #ff0000;
}
.check-block .form-group .alert label:before {
  border: 2px solid #ff0000;

}
/*End: MS-640*/
/*Start of Date 02-07-2019*/
.listing-section .after-slider-section{
  background:rgba(155, 155, 155, 0.13);
  padding: 30px 25px;
}
.listing-section .after-slider-section span{
  border:1px solid #BEB7B3;
  margin-right: 12px; 
  display: inline-block;
  padding: 3px 8px;
  font-size: 12px;
  text-transform: uppercase;
}

.listing-section .after-slider-section .view-map{
  text-align: right;
}

.listing-section .after-slider-section .view-map ul{
  margin: 0;
}

.listing-section .after-slider-section .view-map ul li{
  list-style: none;
  display: inline-block;
  padding-right: 20px;
  font-size: 12px;
  text-transform: uppercase;
  vertical-align: middle;
}

.listing-section .after-slider-section .view-map ul li a:hover{
  text-decoration: none;
   opacity: .6;
}

.listing-section .after-slider-section .view-map ul li a img{
  padding-right: 5px;
}

.listing-detail-form{
  font-family: 'Cormorant Garamond', serif;
}

.listing-detail-form input{
  height: 34px;
}
.listing-detail-form textarea{
  min-height: 80px;
}

.listing-section .listing-details .listing-info{
  position:absolute;
  top:-15%;
  background:#fff;
}  

@media (max-width: 767px){
  .listing-section .listing-details .listing-info{
    position:unset;
  }
  .listing-section .after-slider-section{
    padding: 15px 0;
    text-align: center;
  }
  
  .listing-section .after-slider-section .view-map ul{
    padding: 5px 0;
  }
}
/*End of Date 02-07-2019*/
.form-panel .form-widget .form-section select.form-control {
  background: #ffffff url(/combo-dropdown.png) no-repeat 98% -3px; padding-left:0px;
}
.form-group .neighbor-filter select.form-control {
  background: #ffffff url(/combo-dropdown.png) no-repeat 98% -3px  !important;
  height: 35px !important;
}

/*End: MS-607*/

/*Start: MS-590*/
.application-approval .modal-content{
  min-width: 550px!important;
}

.application-approval .application-approval-confirm{
    text-align: right;
    margin-top: 10px;
    padding-right: 10px;
}

.application-approval .application-approval-confirm .btn.btn-default{
  margin-right: 5px;
}
.application-approval .approval-be-sure {
  text-align: right;
}
.application-approval .application-details{
  padding: 20px;
}

.application-approval .application-details p{
  line-height: 20px;
  margin-top:18px;
}
.application-approval .application-details .application-approval-footer{
  margin-top: 50px;
  line-height: 16px;
}
.approve-wrap{
  cursor: pointer;
}

/*End: MS-590*/


/*Start: MS-645*/
.splitter-layout .box .tag{
  position: absolute;
  top:0px;
  left: 0px;
  width: 80px;
  height: 80px;
  z-index: 9;
  background: url(/no-feetag.png) no-repeat;
  background-size: 100%;

}
/*End: MS-645*/
/*Start: MS-596*/
.boxes input[type="checkbox"] + label{
  line-height: 16px;
  margin-bottom:8px!important
}
.boxes input[type="checkbox"] + label:before{
  top: 1px;
}
.boxes{
  padding-right: 4px;
}
.FrontHeader .navbar a.btn.btn-default {
  padding: 10px 20px!important;
}
/*End: MS-596*/

/*Start: MS-573*/

.react-tabs__tab-panel .agent-listing{
  margin-bottom: 80px;
}
/*End: MS-573*/

/*Start: MS-575*/
html{
  top:auto!important;
}
/*End: MS-575*/


/*Start: MS-661*/
.adminlogin-panel{
  margin-top: 100px;
}
/*End: MS-661*/


/*START: MS-577*/



.splitter-layout .box{
  overflow: hidden;
}
.react-multi-carousel-list {
  display: block;
  align-items: center;
  overflow: hidden;
  position: absolute!important;
  background: #d7d7d7;
  top: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  height: 55%;
  padding: 0px;

}
.react-multi-carousel-list ul{
  padding: 0px;

}
.react-multi-carousel-list li a{
  padding: 0px!important;
}
.react-multi-carousel-list li img{
  width: 100%!important;
}

.react-multiple-carousel__arrow{
  background: none!important;
  text-shadow: 0px 0px 2px #000000;
}
.react-multiple-carousel__arrow::before {
  font-size:26px;

}
.react-multi-carousel-dot-list{
  display: none!important;
}
#ListingModal .listing-banner{
  height: 350px;
  overflow: hidden;
}
#ListingModal .react-multi-carousel-list, #ListingModal .react-multi-carousel-list li, #ListingModal .react-multi-carousel-list .imgFramePreview {
  height: 400px; 
}
#ListingModal .react-multi-carousel-list .imgFramePreview {
  background:no-repeat;
  background-size:contain;
  background-position:center center;
}
#ListingModal .react-multi-carousel-list img{
  width: 100%;
  max-height: 400px;
  
}
#ListingModal .react-multi-carousel-list .legend{
  border: 1px solid rgba(0,0,0,.5);
  background: hsla(0,0%,100%,.5);
  border-radius: 3px;
  color: #000;
  font-family: Muli,sans-serif;
  width: 182px;
  padding: 14px 10px;
  opacity: 1;
  left: inherit;
  right: 30px;
  bottom: 12px;
  position: absolute;
}

#ListingModal .react-multi-carousel-list .legend img {
  width: 20px!important;
  margin-right: 5px;
}



@media (max-width: 767px){
  #mobileListpopup #ListingModal .listing-banner{
      height: 310px;
     
    }
    #mobileListpopup  #ListingModal .react-multi-carousel-list {
      height: 310px;
    }
    #mobileListpopup #ListingModal .react-multi-carousel-list img{
      width: 100%;
      height: inherit;
      
    }
    #ListingModal .react-multi-carousel-list .legend {
     
      right: 12px;
      bottom: 50px;
     
  }
  }
  
/*END: MS-577*/

/*Start Manual: MS-650*/
.manual-section .divtopmargin{
	margin-top: 10%;
}
.manual-section .left .right{
	position: fixed;
}
.manual-section .leftdiv::-webkit-scrollbar {
    width: 0;
}
.manual-section .left a{
	color: white;
}
.manual-section .leftdiv{
	position:fixed;
	height:75vh;
	overflow-y: scroll;
  margin-top: 100px;
  right: 0;
}
.manual-section #myScrollspy{
	overflow: hidden;
  background:#fff url(/curtains.png) fixed;
	box-shadow: -2px 0px 1px rgba(0, 0, 0, 0.25);
  padding-right:15px;
}
.manual-section h4, .manual-section h2, .manual-section h5, .manual-section h1{
  text-transform: uppercase;
}
.manual-section .right{
	background: #fff;
	color: #000;
	overflow: hidden;
}
.manual-section .right a{
	color: #004F6B;
	text-decoration:underline;
	word-break: break-word;
}
.manual-section .nav.nav-pills .nav-item ul{
	padding-left:20px!important;
}
.manual-section .nav.nav-pills .nav-item ul li a{
	font-size:13px;
}

.manual-section .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
   color: #333!important;   
   background:rgba(0,0,0,.25)!important;
   transition:2.5s linear!important;
	 /*background:none;*/
}

.manual-section .nav-pills a.nav-link.subdiv{
   color: #333!important;   
   background:rgba(0,0,0,.25)!important;
   transition:2.5s linear!important;
   min-height: 0px;
}

.manual-section .leftdiv .nav-link{
	color:#333;
	font-weight:800;
	text-transform:uppercase;
}
.manual-section .bg-light{
	background:#fff!important;
	box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
}
.manual-section .leftdiv .nav-link:hover{
	color:#666060;
}
.manual-section .maindiv{
	height: 100vh;
}
.manual-section .subdiv{
  min-height: 100vh;
  padding-bottom: 80px;
}
.manual-section .blockquote{
    background:#004f6b;
    border-left-color: #01729A;
    border-left-width: 9px;
    border-left-style: solid;
    padding: 20px 45px 20px 26px;
    margin-bottom: 30px;
    margin-top: 20px;
    width: 100%;
    color: #fff!important;
}
.manual-section .blockquote a{
	color: #fff;
}
.manual-section .logo{
	width: 300px;
}
.manual-section .nestiolifont{
	font-size: 1.5rem!important;
}
/* searchbar start */
.manual-section .finder {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  height: 50px;
  background: #fff;
  font-size: 14px;
  border-bottom: 1px solid #e5e5e5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 5px;
  transition: bottom 0.15s ease-out;
}

.manual-section .finder.active {
  bottom: 0;
}

.manual-section .finder-input {
  flex-grow: 1;
  width: 0;
  height: 40px;
  border: none;
  padding: 8px;
  outline: none !important;
}

.manual-section .finder-input.not-found {
  color: #e7353f;
}

.manual-section .btn-finder {
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 0;
}
.manual-section .collapse{
  display: none;
}
.manual-section .collapse.show{
  display: flex;
  width: 100%;
}
.manual-section .finder-input,
.manual-section .btn-finder:not(:last-child) {
  margin-right: 5px;
}

.manual-section .finder-count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
  border-right: 1px solid #e5e5e5;
  margin-right: 5px;
  padding-right: 20px;
  white-space: nowrap;
}

.manual-section .btn{
  background: rgba(0,0,0,0.12)!important;
}
.manual-section h2{
  font-family: 'Metropolis-Regular';
}
.manual-section h5{
  font-weight: 600;
}
.manual-section .btn-finder,
.manual-section .finder-count {
  flex-shrink: 0;
  flex-grow: 0;
}

.manual-section .highlight {
  background:#6695B6;
}

.manual-section .highlight.active {
  background:#ff0000;
}

.manual-section .pd-top{
	padding-top:0;
}

.manual-section .content{
  height: auto;
  margin-left:0px;
}
.manual-section .btn:hover{
  background: #004F6B!important;
}
.manual-section .navbar-nav li{
  background: none!important;
}

.manual-section .leftdiv .nav-link{margin-left:15px;}
.manual-section .btn {
  background: none!important;
  border:0px;
}

@media screen and (max-width: 600px) {
  .manual-section .introduction{
    margin-top: 1.5rem;
  }
  .manual-section .logo{
    width: 250px;
  }
}

@media screen and (max-width: 320px) {
  .manual-section h2{
    font-size: 1.5rem!important;
  }
  .manual-section .introduction{
    margin-top: 4.5rem;
  }
  .manual-section .logo{
    width: 200px;
  }
  .manual-section .display-3, .display-4{
    font-size:2.5rem!important;
  }
  .manual-section .blockquote{
    font-size: 1.10rem!important;
  }
  .highlightme { background-color:#FFFF00; }
}


/*End Manual: MS-650*/


#contacttoemail{
  z-index: 9999;
}
#contacttoemail .modal-body{
  padding:20px 30px;
}
#contacttoemail .modal-header{
  padding-left: 30px;
}
.application-approval .application-details {
  padding:0 20px 20px;
  border:1px solid #888;
  font-size: 13px;
  box-shadow: 2px 2px 4px rgba(0,0,0,.2);
}
#contacttoemail input{
  border:0px;
  border-bottom: 1px solid #999;
  font-size: 13px;
}
#contacttoemail .contact-info p{
  font-size: 13px;
}
#contacttoemail  .application-approval-confirm{
  padding-right: 0px!important;
  position: relative;
  right: -5px;
}
#contacttoemail .contact-info .checkbox {
  position: relative;
  top: -65px;
}
#contacttoemail .contact-info label{
  margin-left: 8px;
}
#contacttoemail .modal-header button.close{
  opacity: 1;
  font-weight: normal;
}
#contacttoemail .modal-content{
  min-width: 100%!important;
}
#contacttoemail .modal-dialog{
  margin-top: 40px;
}
.react-multiple-carousel__arrow{top:45%!important}
.react-multi-carousel-track{display:-webkit-box!important}




/* Education Page Start */
.education-section .container{
  font-family: 'Cormorant Garamond', serif;
  max-width: 640px;
  margin: 0;
}

.education-section h3{
  font-family: 'Muli', sans-serif!important;
  padding: 20px 0 10px;
  font-size: 24px;
  font-weight: 900;
 }

 .education-section .post-date span{
  font-weight: bold!important;
  font-family: 'Muli', sans-serif!important;
 }

 .education-section h4{
  font-weight: bold!important;
  font-family: 'Muli', sans-serif!important;
  font-size: 20px;
 }

 .education-section i{
  font-size: 18px;
 }

 .education-section p{
  font-family: 'Metropolis-Regular';
  font-size: 13px;
 }


 .education-section .edu-top .form-control{
   height: 40px!important;
   box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.20)!important;
   border-radius: 3px;
   border:1px solid #A8A8A8;
   font-family: 'Metropolis-Regular';
 }
 .education-section .education-sliders{
   margin-top:10px;
 }
 .education-section .education-sliders .slick-prev,
 .education-section .education-sliders .slick-next{
   top:22%;
 }

 .education-section .education-sliders .slick-dots{
   display: none!important;
 }

 .education-section .education-sliders .slick-next:before,
 .education-section .education-sliders .slick-prev:before{
  content: '';
  width: 20px;
  height: 24px;
  display: block;
  opacity: 1;
 }
 .education-section .education-sliders .slick-prev:before{
  background: url('/prevArrow.png') no-repeat;
 }
 .education-section .education-sliders .slick-next:before{
  background: url('/prevArrow.png') no-repeat;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
 }
 .education-section .education-sliders .slick-next {
  right: -6px;
}

.education-section .edu-top .edu-select{
  width: 180px;
  background: #E4E1DC;
  border-radius: 3px;
  -webkit-appearance:menulist-button;
  color: #6c757d!important;
  text-indent:0;
  height:30px;
  font-size: 14px;
 }

 .education-section .edu-top .form-control{
  width: 180px;
  height:30px;
  padding-left: 5px;
  font-size: 14px;
 }

 .education-section .edu-video .edu-video-img{
 width: 88%;
 height: 240px;

}

.education-section .edu-wrap{
  padding-top:8px;
  padding-right: 30px;
 }
.pdf-box{
  min-width: 800px;
}
 .education-section .edu-audio .edu-audio-img{
  width: 75%;
  height: 60px;
 }

 .education-section .edu-files .edu-files-img{
  width: 180px;
  height: 100px;
  margin: 0px auto;
 }
 .education-section .education-sliders .slick-slider{
   margin-left: 20px;
 }
 @media (max-width: 1500px){
  .education-section .edu-video .edu-video-img{
    width: 88%;
    height: 170px;
   
   }
 }
 @media (max-width: 767px){
  .education-section .edu-top .form-control{
    width: 100%;
    margin-bottom: 7px;
  }
  
  .education-section .edu-video .edu-video-img, .education-section .edu-audio .edu-audio-img{
    margin: 0px auto;
  }
  .education-section .education-sliders .slick-next {
    right: -22px;
}
.education-section .edu-video .edu-video-img{
  width: 88%;
  height: 100px;
 
 }
}

/*Education Page End */

 
/*-----Start MS-559----------*/
.slick-slider a{
  color:#000;
}
.slick-slider audio.edu-audio-img{
  width: 100%!important;
}
#myAudio button.close,
#myVideo button.close{
  opacity: 1;
  font-weight: normal;
  font-size: 45px;
  padding: 0px 20px;
}

/*-----End MS-559----------*/

/*Start: MS-619*/
#ExpirePop .modal-body{
  padding: 30px;
}
#ExpirePop .modal-title{
  padding-left: 15px;
}
#ExpirePop .form-control{
  border-radius: 0px;
  border: 0px;
  border-bottom: 1px solid #999;
  padding: 8px 0px!important;
}
#ExpirePop label{
  font-size: 14px;
  margin-bottom: 0px;
}
#ExpirePop select.form-control{
  background: url(/combo-dropdown.png) no-repeat;
  background-position: 98% 0%;
}

/*MS 619 Feed Image Zoom issue fix*/

.slider-frame .add-img{background-size:cover!important; background-repeat: no-repeat }

/*MS 591*/
#documentList input[type="checkbox"]:checked + label:before {opacity: 1!important; margin-right:10px}
#ScreeningsList input[type="checkbox"]:checked + label:before {opacity: 1!important; margin-right:10px}


/*Admin Sidebar Scroll Issue*/

.adminSidebar {overflow-y: auto!important}

.adminSidebar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.adminSidebar::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}

.adminSidebar::-webkit-scrollbar-thumb
{
	background-color: #00506B;
	border: 1px solid #00506B;
}

/*MS 562*/
.manual-section .saveBtnManual{background:#004f6b!important}
.manual-section .saveBtnManual:hover{background:#ffffff!important; color: #000}

/*Start: MS 663*/
.request-edit a:hover{
  color:#000;
}

#buildingModal,
#buildingamenities,
#editbuildingnotes,
#reqadditionaldoc,
#defaultapptemplate,
#contacttoemail {
 z-index: 9999;
}
#buildingModal.modal {
 overflow: auto!important;
}


/*End: MS 663*/


/*START: MS 537*/
.action-view a{
  margin: 0px 4px;
  display: -webkit-inline-box;
  font-size: 18px;
}
.action-view a:hover{
  color: #F26C52;
}
.upload-doc{
  overflow: hidden;
  display: -webkit-inline-box;
  position: relative;
}
.upload-doc input{
  opacity: 0;
  position: absolute;
  left: 0;
    height: 40px;
}
.docModal .modal-content{
  min-width: auto;
}
.docModal .modal-sm{
  max-width: 400px;
}
.docModal .form-control{
  border-radius: 0px;
  border:0px;
  border-bottom: 1px solid #999;
}
.docModal .modal-header{
  padding-left: 25px;
}
.docModal .modal-body{
  padding: 25px;
}
/*END: MS 537*/

/*Start: MS 673*/


/*Start: MS 673*/
#ListingModal .react-multi-carousel-list, 
#ListingModal .react-multi-carousel-list .imgFramePreview,
#ListingModal .react-multi-carousel-list li {
  height: 340px;
}
/*End: MS 673*/

/*Start: MS 672*/
.react-multi-carousel-list{
  z-index: -1;
}
#ListingModal .react-multi-carousel-list{
  z-index: 1;
}
/*End: MS 672*/
.actions-grid{
  min-width: 140px;
}

.actions-grid a{
  margin: 0px 5px;
  display: inline-block;
  text-align: center;
}
/*start: MS 538*/
.rotate-wrap{
  text-align: center;
  margin-top: 20px;
  background: #fff;
  position: relative;
  z-index: 999;
}
.rotate-wrap .left-rotate,
.rotate-wrap .right-rotate{
  background: url(/rotate.png) no-repeat;
  background-size: 100%; 
  width: 28px;
  height: 28px;
  border: 0px;
  margin: 2px;

}
.rotate-wrap .left-rotate{
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
}

/*End: MS 538*/
/* MS 673 */
@media (max-width: 767px){
  #ListingModal .react-multi-carousel-list .legend {
    width:50px!important;
    padding-left: 15px!important;
}

#ListingModal .react-multi-carousel-list .hidden-xs {
 display:none
}
.mobileCloseBtn {
  width: 35px;
  height: 35px;
  }

}

/*MS 708*/
.closeBtnCreator {margin-bottom:0px!important; cursor: pointer;}
.closeBtnCreator:hover {color:#004F6B}



/*Start MS_698*/
#incentiveEdit .form-inline{
  margin-bottom: 20px;
}

#incentiveEdit .form-inline .form-group label {
  text-align: left;
  display: block;
  width: 100%;
}
#incentiveEdit .form-inline .form-group{
  display: inline-block;
  margin: 6px 
}
#incentiveEdit select.form-control {
  line-height: 12px;
}
#incentiveEdit ul.btn-inline{
  padding-left: 0px;
}
#incentiveEdit .form-inline .form-control {
  border-radius: 0px;
  border: 0px;
  height: 25px!important;
  border-bottom: 1px solid #999;
  padding-left: 0px;
}

/*End MS_698*/

/*Start MS_726*/
.styles_modal__gNwvD .modal-body.contact-agent-modal{
  min-width: 380px!important;
}
.contact-agent-modal .info{
  float: left;
  widows: 80px;
}
.contact-agent-modal .agent-wrap{
  margin-left: 85px;
  padding-top:10px;
}
.contact-agent-modal .listed-box{
  margin-bottom: 60px;
}
.contact-agent-modal .form-control.txtarea{
  height: 80px;
  padding: 10px;
  border:1px solid rgba(0, 0, 0, 0.5);
}
.contact-agent-modal #contactmailclose{
  margin-right: 10px;
}
/*End MS_726*/
/*Start MS_724*/

.applications-list{
  height: 250px;
  overflow: auto;
}
.applications-list::-webkit-scrollbar {
  width: 6px;
  }

/* Track */
.applications-list::-webkit-scrollbar-track {
  background: #004f6b; 
  border-radius: 6px;

}

.applications-list::-webkit-scrollbar-thumb {
  background:#F26C52;
  border-radius: 6px;
}
/*End MS_724*/
/*Start MS_730*/
.listing-filter.agent-filter .panel_1 .price-list li:before,
.boxes input + label:before,
.neighborhoodDD input + label:before{
  left:0;
}
.listing-filter.agent-filter .panel_1 .price-list li,
.boxes input + label,
.neighborhoodDD input + label{
  padding-left: 25px;
}
.boxes {
  padding: 15px;
}

.listing-filter.agent-filter .panel_1 .neighbor .check-box .form-group label{
  padding-left: 0px;
}
.listing-filter.agent-filter .panel_1 .neighbor .check-box .form-group label:before{
  top:10px;
}
.listing-filter.agent-filter .panel_1 .neighbor .check-box .form-group input:checked + label:after {
  top: 12px;
}
/*End MS_730*/
/*Start MS_724*/

.applications-list{
  height: 250px;
  overflow: auto;
}
.applications-list::-webkit-scrollbar {
  width: 6px;
  }

/* Track */
.applications-list::-webkit-scrollbar-track {
  background: #004f6b; 
  border-radius: 6px;

}

.applications-list::-webkit-scrollbar-thumb {
  background:#F26C52;
  border-radius: 6px;
}
/*End MS_724*/
/*---Start MS-728----*/
#emailtoclient {
  z-index: 9999;
}
#emailtoclient .modal-content{
  min-width: 400px;
}
#emailtoclient .form-group{
  padding: 0px 10px;
}
#emailtoclient .form-control.textArea{
  height: 80px;
}
#emailtoclient .form-control{
  border-radius: 0px;
  border: 0px;
  height: 25px;
  border-bottom: 1px solid #999;
  padding-left: 0px;
}
#emailtoclient .boxes{
  width: 87%;
  height: 150px;
  overflow: auto;
  padding: 15px;
}

#emailtoclient .boxes.clientL label:before{
  right: 0px;
}
#emailtoclient .neighbor-filter.list-filter{
  font-size: 12px;
  color: #495057;
}
#emailtoclient .form-group label{
  margin-bottom: 5px;
  display: block;
  font-size:14px;
}
/*---End MS-728----*/



/*----------------------New Desing------------------*/
/*----------------------New Desing MS 720------------------*/




.Newskyward-design .panel-1{
  height: 800px;
}
.Newskyward-design .panel-1 a,
.Newskyward-design .panel-1 p{
  color:#fff;
}
.Landing-header{
  position: relative;
  height: auto;
  background: transparent;
  box-shadow: none;
}
.Landing-header .navbar-expand-lg .navbar-nav {
  flex-direction: row;
  width: 100%;
  text-align: center;
  display: block;
}
header.main-header .navbar-expand-lg .navbar-nav li,
.Landing-header .navbar-expand-lg .navbar-nav li{
  display: inline-block;
  padding: 20px 30px;
}
header.main-header .navbar-expand-lg .navbar-nav li a,
.Landing-header .navbar-expand-lg .navbar-nav li a{
  color:#fff;
  text-transform: uppercase;
  text-decoration: underline;
  font-family: 'Metropolis-Bold';
  letter-spacing: 2px;
  font-size: 14px;
}
header.main-header .navbar-expand-lg .navbar-nav li a:hover,
.Landing-header .navbar-expand-lg .navbar-nav li a:hover{
  text-decoration: none;
  font-size: 14px;
}
.Landing-header .bg-light{
  background: transparent!important;
}

.Landing-header .navbar-expand-lg .navbar-nav li.get-started-tab{
  margin-top: 40px;
  padding: 0px;
  margin-left: 0px;
}
.Landing-header .navbar-expand-lg .navbar-nav li.get-started-tab .btn{
  border-radius: 0px;
  border:1px solid #014B5E;
  padding: 15px 20px;
  height: auto;
  
}
.Landing-header .navbar-expand-lg .navbar-nav li.get-started-tab .btn:hover{
  border:1px solid #fff;
  background: transparent;
  text-decoration: none;
}
.Newskyward-design .panel-1 .listign-logo{
  text-align: center;
}
.Newskyward-design .panel-1 .listing-landing{
  padding-top: 50px;
}
.Newskyward-design .panel-2{
  text-align: center;
  background: #fff url(/bg_sidebar.png);
  padding: 80px 0;
  position: relative;
  z-index:9999;
}
.Newskyward-design .panel-2 .container.workbody{
  background: #fff;
  max-width: 1050px;
  padding:80px 40px;
}
.Newskyward-design .panel-2 h1{
  display: inline;
  font-family: 'Metropolis-ExtraBold';
  margin-bottom: 40px;
  font-size: 32px;
  letter-spacing: 3px;
  font-style: normal;
  line-height: 40px;
}
.Newskyward-design .panel-2 .shortInfo{
 
  font-family: 'Cormorant Garamond', serif;
  font-size: 25px;
  line-height: 28px;
  max-width: 800px;
  margin: 0px auto;
  margin-top: 40px;
}
.Newskyward-design .panel-2 .exp-content{
  font-family: 'Cormorant Garamond', serif;
  font-size: 25px;
  font-style: italic;
  line-height: 28px;
  min-height: 140px;
  margin-bottom: 30px;
}
.Newskyward-design .panel-2 .exp-content .status{
  font-family: 'Metropolis-ExtraBold';
  margin: 30px 0;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  text-decoration: underline;

}

.Newskyward-design .goto {

  bottom: -48px; 
  width: 100%;
  height: 48px;
  position:absolute;
  z-index: 9!important;
}
.Newskyward-design .container.workbody{
  z-index: 1;
  padding: 150px 15px;
}
.Newskyward-design .relative{
  position: relative;
}
.Newskyward-design .showroom-section{
    background: #beccc0;
    text-align: center;
    font-family: 'Metropolis-Regular';
    font-size: 24px;
}
.Newskyward-design .showroom-section .showroom-info{
  text-align: center;
  margin: 0px auto;
  margin-top: 28px;
  max-width: 280px;
}

.Newskyward-design .showroom-section  h1{
  display: inline;
  font-family: 'Metropolis-ExtraBold';
  margin-bottom: 40px;
  font-size: 32px;
  letter-spacing: 3px;
  font-style: normal;
}

.Newskyward-design .showroom-section .showroom-info h4{
  font-family: 'Cormorant Garamond', serif;
  text-transform: uppercase;
  font-size: 22px; 
  margin-bottom: 20px;
}
.Newskyward-design  h1{
  padding-bottom: 0px;
}
.Newskyward-design  .Join-section{
  min-height: 800px;
  color:#fff;
  position: relative;
}
.Newskyward-design .Join-section h1{
  font-size: 32px;
  letter-spacing: 3px;
  margin-top: 40px;
  border-color: #fff;
}
.Newskyward-design .Join-section h1:before{
  position: absolute;
  width: 100%;
  background: #fff;
  height: 2px;
}
.Newskyward-design .Join-section .info{
  font-family: 'Cormorant Garamond', serif;
  margin: 60px 0;
  font-size: 35px;
  letter-spacing: 1px;
}
.Newskyward-design .Join-section a{
  color:#fff;
  font-family: 'Metropolis-Bold';
  text-decoration: none;
  text-transform: uppercase;
  font-size: 25px;
  letter-spacing: 1px;
}
.Newskyward-design .Join-section a:hover{
  color:#fff;
  font-family: 'Metropolis-Bold';
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 25px;
  letter-spacing: 1px;
}
.Newskyward-design footer{
  padding: 40px 0;
}
.Newskyward-design footer .logo{
  position: relative;
  margin-top: 40px;
}
.Newskyward-design footer .container{
  max-width: 1200px!important;
}
.Newskyward-design footer .navbar-nav{
  text-align: center;
  display: block;
}
.Newskyward-design footer .navbar-nav li{
  display: inline-block;
  margin-left: 0px;
  margin: 0px 40px;
  text-transform: uppercase;
}
.Newskyward-design footer .navbar-nav li a{
  font-size: 13px;
  letter-spacing: 1px;
  font-family: 'Metropolis-Regular';
  text-decoration: underline;
}

.Newskyward-design footer .navbar-nav li a:hover{
 
  text-decoration: none;
}

.Newskyward-design footer .navbar-nav li .getstart{
  border:0px;
  border-radius: 0;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.30);
}
.Newskyward-design footer .navbar-nav li .getstart:hover{
  background: #000000;
  color:#fff;
}
.Newskyward-design footer .navbar-nav li.get-started-tab a{
  text-decoration: none;
}
.Newskyward-design .panel-1 .panel_4 a{
  color: #000;
}
.Newskyward-design .L-header{
  display: none;
}
.Newskyward-design .Join-section{
  padding: 0px;
}
.Newskyward-design .Join-section .caption{
  position:absolute;
	text-align:center;
	top:0%;
	left:0px;
	width:100%;
	height:100%;
	display: flex;
	align-items : center;
	width: 100%;
	justify-content : center;
	color:#fff;

}
.Newskyward-design .Join-section .caption h1.jointxt{
  text-decoration: none;
  font-size:50px;
  letter-spacing: 1;
  line-height: 50px;
  border-bottom: 0px;
}
.Newskyward-design .joinInfo .exp-content p.status{
  font-family: 'Cormorant Garamond', serif;
  font-size: 112px;
  font-style: italic;
  text-decoration: none;
  line-height: 112px;
}
.Newskyward-design .joinInfo .exp-content{
  margin:60px 0;
}

.Newskyward-design .joinInfo .exp-content .InfoTxt{
  margin-top: 40px;
  font-style: normal;
  padding: 0px 60px;
}
.Newskyward-design .panel-2.joinInfo .workbody {
  padding: 0px 40px;
}
header.main-header{
  background: transparent;
  box-shadow: none;
  position: absolute;
  width: 100%;
}
header.main-header .bg-light{
  background: transparent!important;
}
header.main-header .logo {
  width: 92px;
}
.Newskyward-design .panel-1 .getstart{
    border-radius: 0px;
    border: 1px solid #014B5E;
    padding: 15px 20px;
    height: auto;
    color: #fff;
    text-transform: uppercase;
    text-decoration: underline;
    font-family: 'Metropolis-Bold';
    letter-spacing: 2px;
    font-size: 14px;
}
.Newskyward-design .panel-1 .getstart:hover{
  background: none;
  text-decoration: none;
  border:1px solid #fff;
}

@media (max-width: 1200px){
.scrollarea .goto img {
    width: 80px;
    margin-top: 0px;
}
}
@media (max-width:992px){
  .Newskyward-design .L-header{
    display: block;
  }
  .Newskyward-design .Landing-header{
    display: none;
  }
  .Newskyward-design footer .navbar-nav li{
    background: none;
    margin: 0px 0px;
  }
  .Newskyward-design footer {
    padding: 10px 0;
}
.Newskyward-design footer .logo {
  margin-top: 20px;
}
header.main-header{
  background: rgba(0, 0, 0, 0.60);
  height:70px;
}
header.main-header .navbar-dark .navbar-toggler {
  margin-right: 15px;
  margin-top: 1px;
  background: none;
  border:0px;
}
header.main-header .logo {
  width: 54px;
  margin-top: 3px;
}
.Newskyward-design .Join-section {
  min-height: 500px;

}
.Newskyward-design .Join-section .caption h1.jointxt {
  font-size: 35px;
  line-height: 38px;

}
.Newskyward-design .panel-2.joinInfo .workbody {
  padding: 0px 0px;
}
.Newskyward-design .joinInfo .exp-content{
  margin:30px 0;
}
.Newskyward-design .panel-2.joinInfo .experience-section{
  padding: 15px;
}
}
.Newskyward-design .joinInfo .exp-content .InfoTxt{
  padding: 0px;
}

/*---Start MS-729----*/
#multipleCheckListing {
  z-index: 99999;
}
#multipleCheckListing .modal-content{
  min-width: 400px;
}
#multipleCheckListing .form-group{
  padding: 0px 10px;
}
#multipleCheckListing .form-control.textArea{
  height: 80px;
}
#multipleCheckListing .form-control{
  border-radius: 0px;
  border: 0px;
  height: 25px;
  border-bottom: 1px solid #999;
  padding-left: 0px;
}
#multipleCheckListing .boxes{
  width: 87%;
  height: 150px;
  overflow: auto;
  padding: 15px;
}

#multipleCheckListing .boxes.clientL label:before{
  right: 0px;
}
#multipleCheckListing .neighbor-filter.list-filter{
  font-size: 12px;
  color: #495057;
}
#multipleCheckListing .form-group label{
  margin-bottom: 5px;
  display: block;
  font-size:14px;
}
/*---End MS-729----*/



/*Start: MS-747*/
.contact-form .form-control{
  border-radius: 0px;
    border: 0px;
    border-bottom: 1px solid #a4a4a4;
    padding: 5px 0;   
    background-position: 1px;   
}
.contact-form label{
  text-align: left;
  display: block;
  font-size: 14px;
}
/*End: MS-747*/

/*Start :MS-737*/

#FormModal{
  z-index: 99999;
}
#FormModal .modal-content {
  min-width: 650px!important;
}
#FormModal .modal-body{
  min-height: 712px;
}
#FormModal page {
background: white;
display: block;
margin: 0 auto;
margin-bottom: 0.5cm;
padding-top:.5cm;
font-family:arial;
width: 612px;
min-height: 820px; 
position: relative;
}
#FormModal page[size="A4"] {  
width: 612px;
height: 792px; 
position:relative;
}
#FormModal .left1{
float: left;
}
#FormModal .left2 {
  overflow: hidden;
  display: block;
  padding: 0 4px 0 4px;
  vertical-align: top;
}
#FormModal .rotatetxt{
position: absolute;
 
  -webkit-transform: rotate(-90deg);
 
          transform: rotate(-90deg);
  top: 131px;
  left: -132px;
  text-align: center;
  width: 280px;
}
#FormModal .f10{
  font-size: 10px;
}
#FormModal .bor-b{
  font-size: 10px;
  border:0px!important;
  border-bottom:1px solid #000!important;
}
#FormModal .inline-B{
  display: inline;
}
#FormModal .w100{
  width:100%
}
#FormModal td{
  padding: 0px!important;
}
#FormModal td:first-child{
  padding-left: 0px!important;
}
#FormModal page .FooterBox{
  height:30px;
  color:#d2d2d2;
  font-size:11px;
  border-top: 1px solid #d2d2d2;
  bottom: 0px; padding-top:6px;
  position: absolute;
  bottom: 0px;
  width: 100%;

}
#FormModal page .FooterBox > div{
  text-align: center;
  margin-top: 5px;
}
#FormModal .page3,
#FormModal .page2{
  padding:0px 15px 10px;  font-size: 10px;	line-height: 8px;
}
#FormModal .main-box{
  font-size:10px; 
  text-transform:inherit;
}
#FormModal .ml-15{
  margin-left:15px; 
}
#FormModal .ml-9{
  margin-left:9px; 
}
#FormModal .ml-25{
  margin-left:25px;
}
#FormModal .ml-40{
  margin-left:40px;
}
#FormModal .pb-0{
  padding-bottom:0px; 
}
#FormModal  .page2 td{
  padding-bottom:3px!important;
}
#FormModal td.pb-2{
  padding-bottom: 2px!important;
}
#FormModal .pagewrap td{
  padding:2px!important;
}
#FormModal .tables2 td{
  padding:2px!important;
}
#FormModal .space1{
  font-size: 10px; line-height: 8px; margin:2px 0;
}
#FormModal .space3{
  font-size: 10px; line-height: 8px; margin:4px 0;
}
#FormModal .space2{
  font-size: 10px; line-height: 8px; margin: 4px 0 4px 10px;
}
#FormModal .page7 label{
  margin-bottom: 0px!important;
}
#FormModal .page7 h3{
  font-size: 14px;
  font-weight: bold;
}
#FormModal .page9 label{
  margin-bottom: 2px!important;
}
#FormModal .page10 td{
  padding:4px 6px!important;
  line-height: 14px;
}
#FormModal .ins td{
  padding:4px 0px!important;
}
#FormModal .page11 td{
  padding:0px 6px!important;
}
#FormModal .page15 td{
  line-height: 14px;
}
#FormModal .page11 label{
  margin-bottom: 0px!important;
}
#FormModal .pd1{
  padding-bottom: 1px!important;
}

#FormModal .ins td p{
  line-height: 9px;
}
.actions-grid a {
  margin: 0px 4px;
}
/*End :MS-737*/




/*Start :MS-746*/
.sidebar .sidebar-nav li a{
  font-family: 'Metropolis-SemiBold';
  font-size: 17px;
}
.content .work-section .dashboard h2 {
  font-family: 'Metropolis-Bold';
}
.bg-light .navbar-nav li a{
  font-family: 'Metropolis-Bold';
}

.react-tabs__tab{
  font-family: 'Cormorant Garamond', serif;
  text-transform: capitalize;
}
.listing-filter a.advance-search-widget{
  letter-spacing: -.5px;
}
.listing-filter .advance-search .filter-block .form-group label{
  font-size: 13px;
}
.listing-filter .search-wrap {
  margin-top: 0px;
  text-align: center;
}
.btn.search-filter{
  background: none!important;
  border:0px!important;;
  height: auto!important;;
  padding: 0px!important;;
  width: 30px;
  min-width: 50px!important;
  box-shadow: none!important;
}
.listing_count .commoncheck .form-group label {
  font-size: 17px;
  font-family: 'Metropolis-Regular';
}
.content .work-section .dashboard .table.agent-listing tr td.addresstd{
  padding-left:16px!important;
}
#ListingModal .modal-header .price {
  font-size: 30px;
  font-family: 'Metropolis-Bold';
}
#ListingModal .modal-content{
  font-family: 'Metropolis-Regular';
  font-size: 12px;
}
#ListingModal .modal-body h4{
  font-family: 'Cormorant Garamond', serif;
}
#ListingModal .modal-header .room_detail{
  font-size: 14px;
  text-transform: uppercase;
}
.availabel-txt {
  font-size: 16px!important;
  font-family: 'Metropolis-Bold';
}
.listing-filter h4.title {
  font-size: 14px;
  text-transform: uppercase;
}
.duration-widget input[type=checkbox]+label .txt {
  top: 4px;
  font-size: 16px;
}
.splitter-layout .block {
   grid-template-columns: repeat(auto-fill, minmax(235px, 1fr))!important;
}
.react-multi-carousel-list {
  height: 45%;
}
.listing-result .splitter-layout .list-frame .listing-info {
   height: 55%!important;
   padding: 20px 15px;
}
.listing-result .list-frame .listing-info h3.address {
  font-size: 18px;
  text-transform: uppercase;
}
.listing-result .list-frame .listing-info .price {
  font-size: 20px;
  font-family: 'Metropolis-Bold';
}
.listing-result .list-frame .listing-info .room-info {
  font-size: 16px;
  text-transform: uppercase;
  font-family: 'Metropolis-Regular';
}
.desktop-view .search-filter.mr-5{
  margin-right:90px!important;
}
@media (max-width: 1420px){
.listing-filter.agent-filter {
    margin-top: 10px;
}
}
@media (max-width: 1400px){
  .desktop-view .search-filter.mr-5{
    margin-right:187px!important;
    margin-bottom: 20px!important;
  }
}
.content .work-section .dashboard .table.agent-listing thead th, .content .work-section .dashboard .table th {font-weight: bold}


.react-multiple-carousel__arrow:focus {
  outline: none!important;
  border: none;
}

.listing-result .list-frame .listing-info .area{
  font-family: 'Metropolis-Bold';
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 2px;
}
.Newskyward-design .resetImgFilter img {display: none}
/*End :MS-746*/

/* MS-778 */
@media (max-width: 480px){
.agent-detail-section .agent-info {
    margin-left: 0;
    text-align: center;
    clear:both;
}
.team-img {
  margin-bottom:20px;
  width:100%!important;
}

}
/*MS 763*/
.imageNoimageDetail{background: url(/listing-img-detail.png); background-size: cover; background-position: center center; height:445px}



#urbanForm  .modal-dialog {
  max-width: 750px;
  margin-top: 50px;
}
#urbanForm input{
  border:0px; border-bottom:1px solid #000; margin:0px 5px; font-size:11px; font-family: Times New Roman;
}
#urbanForm td{
  text-transform: inherit;
  line-height: 12px;
}
#urbanForm td label{
  text-transform: inherit;
}
.close.urbanmMocal{
  opacity: 1;
  right: 15px;
  top:10px;
  position: relative;
  z-index: 999;
  cursor: pointer;
}

.marketing-filter{
  max-width: 700px;

  margin:20px auto;
  background: #f8f7f6;
    border: 1px solid #bfbfbf;

  padding: 20px;
  
}
.marketing-filter .cell{
  float: left;
  margin: 0px 15px;
}
.marketing-filter .cell.cell1{
  width: 156px;
}
.marketing-filter .form-control{
  background: none;
  box-shadow: none;
  border: 0px;
  border-radius: 0px;
  border-bottom: 1px solid #000;
  color: #000;
  font-family: 'Muli', sans-serif;
  width: 100%;
  height: 40px;
}
.marketing-filter select.form-control{
  background: url(/combo-dropdown.png) no-repeat right;
  padding-left: 0px;

}


.table-fixed tbody {
  height: 300px;
  overflow-y: auto;
  width: 100%;
}


.table-fixed  thead th { position: -webkit-sticky; position: sticky; top: 0; z-index: 1;}

.table-fixed table  { border-collapse: collapse; width: 100%; }

.react-datepicker-popper{z-index: 9999!important;}

/*---Start: Chatbox----*/
.OpenChat{
  width: 70px;
  height: 70px;
  background: #FFFFFF;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  position: fixed;
  right:30px;
  bottom:30px;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;

}
.OpenChat a{
  color:#004F6B;
  font-size: 40px;
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.OpenChatbox{
  background: #FFFFFF;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  min-height: 50px;
  position: fixed;
  bottom: 0px;
  right:0px;
  width: 580px;
  z-index: 9999;
}
.OpenChatbox .header{
  height: 50px;
  text-transform: uppercase;
  position: relative;
  color:#004F6B;
  font-size: 16px;
  font-weight: 800;
  box-sizing: border-box;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
}
.OpenChatbox .header i{
  font-size: 25px;
  margin-right: 5px;
}
.OpenChatbox .header .close{
  position: absolute;
  right:15px;
  top:12px;
  opacity: 1;
}
.OpenChatbox .header .close.maximize{
  right:48px;
}
.OpenChatbox .header .close.minimize{
  right:48px;
}

.OpenChatbox .chatBlock{
  height:60vh;
  overflow: auto;
  padding: 15px;
}

.OpenChatbox .chatBlock .chat
{
    list-style: none;
    margin: 0;
    padding: 0;
}

.OpenChatbox .chatBlock .chat li
{
    margin-bottom: 10px;
    padding-bottom: 5px;
}

.OpenChatbox .chatBlock .chat li.left .chat-body
{
    margin-left: 50px;
}
.OpenChatbox .chatBlock .chat-img,
.OpenChatbox .chatBlock .img-circle{
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.OpenChatbox .chatBlock .img-circle{
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}
.OpenChatbox .chatBlock .chat li.right .chat-body
{
    margin-right: 50px;
}
.OpenChatbox .chatBlock .chat li.left .msgBox{
  background: #F9F9F9;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 0px 5px 5px 5px;
  padding: 10px;
  color:#3E3E3E;
  font-size: 14px;
  display: table;
}
.OpenChatbox .chatBlock .chat li .msgBox p{
  color:#3E3E3E;
  margin: 0;
}
.OpenChatbox .chatBlock .chat li .chat-body .primary-font{
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color:#004F6B;
}
.OpenChatbox .chatBlock .chat li .chat-body .text-muted{
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 10px;
  color:#ADADAD!important;
  letter-spacing: 1px;
}
.OpenChatbox .chatBlock .chat li.right .chat-body .msgBox{
  background: #004C68;
  border: 1px solid #004C68;
  box-sizing: border-box;
  border-radius: 5px 0px 5px 5px;
  padding: 10px;
  color:#fff;
  font-size: 14px;
  display: table;
  float:right;

}
.OpenChatbox .chatBlock .chat li .chat-body ul{
  padding-left: 0px;
  margin-top: 8px;
}
.OpenChatbox .chatBlock .chat li .chat-body ul li{
  list-style: none;
  display: inline-block;
}
.OpenChatbox .chatBlock .chat li .chat-body ul li{
  background: none;
  color:#004F6B;
  text-transform: uppercase;
  min-width: auto;
  margin-right: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: none;
  border:1px solid #004F6B;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
}
.OpenChatbox .chatBlock .chat li .chat-body ul li:hover{
  background:#004F6B;
  color:#fff;
}
.OpenChatbox .chatBlock .btn{
  background: none;
  color:#004F6B;
  text-transform: uppercase;
  min-width: auto;
  margin-right: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.OpenChatbox .chatBlock .btn.didntBtn{
  padding: 5px 10px;
    height: auto;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 5px;

}


.OpenChatbox .chatBlock .btn.btn-default{
  background: none;
  border:1px solid #004F6B;
}
.OpenChatbox .chatBlock .btn.btn-default:hover{
  background:#004F6B;
  color:#fff;

}
.OpenChatbox .chatBlock::-webkit-scrollbar-track{   
    background-color: #D9D9D9;
}
.OpenChatbox .chatBlock::-webkit-scrollbar{
    width: 8px;
    background-color: #004F6B;
}
.OpenChatbox .chatBlock::-webkit-scrollbar-thumb{
    background-color:#004F6B;
}
.OpenChatbox .sendMsg{
  padding: 15px;
}
.OpenChatbox .input-group-text{
  border:0px;
  padding-right:10px;
  background:none;
  vertical-align: top;
}
.OpenChatbox .form-control{
  background: #E9E9E9;
  border: 1px solid #C8C8C8;
  box-sizing: border-box;
  border-radius: 5px!important;
  padding: 10px;
  font-family: 'Metropolis-Regular';
  font-size: 14px;
  color:#888888;
  letter-spacing: 1px;
  min-height: 50px;
}
.OpenChatbox .sendbtn{
  background: #fff;
  width: 40px;
  height: 40px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border:0px;
  border-radius: 50%;
  padding-top: 10px;
  cursor: pointer;

}
.OpenChatbox .sendbtn:hover{
  background: #004F6B;
}
.OpenChatbox .sendbtn:hover .arrow{
  fill: #fff;
}
/*---Emd: Chatbox----*/


/*--Start: MS_805---*/

.FrontEnd h2.title {
  font-size: 32px;
  font-family: 'Metropolis-Bold';
  color:#004F6B;
}
.ourteam-section .team-block .team-info p.name {
  font-size: 20px;
  font-family: 'Metropolis-SemiBold';
  text-transform: uppercase;
  line-height: 22px;
  margin-top:20px;
}

.ourteam-section .team-block .team-info p.phno,
.ourteam-section .team-block .team-info p.email{
  font-size: 15px!important;
  font-family: 'Metropolis-Regular';

}
.ourteam-section .team-block .team-info p.client-title{
  font-family: 'Cormorant Garamond', serif;
  font-size: 18px;
  line-height: 22px;
  margin: 5px 0 15px
}
.agent-detail-section .agent-info p.phnumber, .agent-detail-section .agent-info p.email{
  text-align: left;
}
.agent-detail-section .agent-info p.name{
  font-family: 'Metropolis-SemiBold';
  text-transform: uppercase;
}
.agent-detail-section .agent-info p.clienttitle{
  font-family: 'Cormorant Garamond', serif;
  font-size:26px;
  line-height: 30px;
  margin-top: 10px;

}
.agent-listing h2.listing-title {
  background: #004F6B;
  color: #fff;
  border: 0;
  text-align: left;
  padding: 14px 14px 12px ;
  font-family: 'Metropolis-regular';
  text-transform: uppercase;
  font-weight: 100;
  font-size: 24px;
}

/*--End: MS_805---*/

/*--Start: MS_802---*/

.curtain:before,
.curtain:after{
  display: none;
}



/*--End: MS_802---*/



/*--Start: MS_814---*/
.sidebar .sidebar-nav li a {
  padding: 8px 10px 3px;
}
.sidebar .sidebar-nav li {
  margin-bottom: 4px;
}

/*--End: MS_814---*/
/*--Start: MS_838---*/
#newShowing_agent_search_id_values{
  position: absolute;
 }
/*--End: MS_838---*/
/*---Start: MS_801------*/

.savesdropdown{
  background: url(/combo-dropdown.png) no-repeat;
  background-position: right;
  border:0px;
  border-bottom: 1px solid #BEB7B3;
  background-size: 16px;
  border-radius: 0;
  padding-left: 0px;
  font-size: 14px;
  color:#000;
  height: 30px!important;
}
.trashbox{
  display: inline-block;
  position: relative;
  top: -10px;
 left: 14px;
}
.savesearch-link{
  position: absolute;
  bottom: 15px;
  right: 150px;
  display: block;
  font-size: 12px;
  border: 0px solid #004F6B;;
  border-radius: 3px;
  padding: 2px 8px;
  color: #004F6B!important;
}
.savesearch-link:hover{
  font-weight:bold;
  
}
#Addemail .modal-content{
  min-width: 400px;
}
#Addemail label{
  font-size: 14px;
  margin-bottom: 0px;
}


#Addemail .form-group{
  padding: 0px 10px;
}
#Addemail .form-control.textArea{
  height: 80px;
}
#Addemail .form-control{
  border-radius: 0px;
  border: 0px;
  height: 25px;
  border-bottom: 1px solid #999;
  padding-left: 0px;
}
#Addemail .boxes{
  width: 87%;
  height: 150px;
  overflow: auto;
  padding: 15px;
}

#Addemail .boxes.clientL label:before{
  right: 0px;
}
#Addemail .neighbor-filter.list-filter{
  font-size: 12px;
  color: #495057;
}
#Addemail .form-group label{
  margin-bottom: 5px;
  display: block;
  font-size:14px;
}
@media (max-width: 767px){
.savesearch-link {
  position: relative;
  bottom: 0px;
  right: 0px;
  height: 150px;
  left: 12px;
  top: 5px;
}
}

/*---End: MS_801------*/



/*---Start: MS_836------*/
#addNotes .modal-header {
  padding: 10px 28px;
  font-size: 24px;
  font-family: 'Cormorant Garamond', serif;
  color:#fff;
}


#addNotes .modal-body {
  padding: 28px;
  padding-bottom: 15px;
}
#addNotes .modal-body .form-control.textarea{
  border: 1px solid #999;
  border-radius: 0px;
  height: 120px;
}

/*---End: MS_836------*/
/*---Start: MS_852------*/
.box.agentNameOnBoard{
  position:absolute;
  }
  
  /*---End: MS_852------*/

/*--Start: MS_857---*/
.popLink{
  text-align: center;
  padding: 0px;
  margin: 10px 0;
}
.popLink li{
  display: inline-block;
  list-style: none;
  padding: 10px 25px 30px;
  font-size: 14px
}

.popLink li a{
  text-decoration: underline;
}
.popLink li a:hover{
  text-decoration: none;
}

/*--End: MS_857---*/


/*--Start: MS_867---*/

.manual-section .subdiv {
  min-height:auto!important;
  padding-bottom:10px!important;
}
.manual-section .maindiv {height:auto!important}
/*--End: MS_867---*/
/* -- Start MS_870 --*/
.box.agentNameOnBoard {
  position: absolute;
  z-index: 9999;
  min-height: 50px;
  max-height:105px;
  overflow-y: scroll;
  padding: 10px 22px;
}
/* -- End MS_870 --*/
#imageEdit .modal-content{
  min-width: auto!important;
}
#imageEdit .modal-body input[type="file"] {
  width: 250px;
  font-size: 14px;
}
#imageEdit .modal-body  img{
  width: 80px;
  height:65px
} 

/*--start: MS_882---*/
#docTable th{
  background: #004C68;
  color:#fff;
}
#docTable th{
  padding: 15px 20px;
  font-size: 14px;
}

#docTable td{
  padding: 8px 20px;
  font-size: 14px;
}
#uploadDoc .form-widget .upload-btn-wrapper .btn {
  margin-bottom: 11px;
  background: #FFFFFF;
  border-radius: 3px;
  font-size: 12px;
  height: 40px;
  padding: 10px;
  color: #000;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 79, 107, 0.5);
  border-radius: 3px;
  width: 100%;
}
#docTable{
  overflow: scroll!important;
}

/*--End: MS_882---*/
/*--Start: MS_846---*/
@media (max-width: 767px) {
  #ListingModal .react-multi-carousel-list .legend a {display: none;}}
  /*--End: MS_846---*/

/*--Start: MS_846---*/
@media (max-width: 767px) {
#ListingModal .react-multi-carousel-list .legend a {display: none;}}
/*--End: MS_846---*/
/*--Start: MS_866---*/
#result-list{margin-bottom:65px;}
/*--End: MS_866---*/
/*--Start: MS_858---*/
#createShowingNewPu {max-width: 375px!important; min-width: 375px!important;}
/*--End: MS_858---*/


/*Start: MS-925*/
.career-sections .main-wrap{
  padding-top: 160px;
  }
  .career-sections .container{
  max-width: 1210px;
  }
  .career-sections h1,
  .career-sections h2,
  .career-sections h6,
  .career-sections h4{
  font-family: 'Metropolis-Bold';
  
  margin-bottom: 20px;
  }
  .career-sections h1{ 
  font-size:52px; 
  font-style: normal;
  border: 0;
  margin-bottom: 20px;
  line-height: 52px;
  }
  .career-sections h2{ 
  font-size:44px;
  }
  .career-sections p{
  font-family: 'Metropolis-Regular';
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 20px;
  }
  
  .career-sections .join-wrap h1{
  margin-top: 15%;
  }
  .career-sections h6{
  font-size: 14px;
  }
  .btn.btn-wrap{
  border-radius: 0;
  background: #004F6B;
  border:1px solid #004F6B;
  font-size: 14px;
  font-weight: 400;
  padding: 18px 100px 10px 30px;
  height: 60px;
  color:#fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  }
  .btn.btn-wrap span{
  background: #fff;
  padding: 21px 20px 22px 20px;
  margin: 0px 0px 0px 0px;
  border-bottom: 0px solid #fff;
  display: block;
  position: absolute;
  right: 0;
  top:0;
  height:58px;
  
  }
  .btn.btn-wrap.btn-submit{
  padding: 18px 140px 10px 80px;
  }
  .btn.btn-wrap.btn-submit span{
  margin: 0px 0px 0px 80px;
  }
  .btn.btn-wrap:hover{
  color:#004F6B;
  background: rgba(0, 0, 0, 0.1);
  }
  .career-sections .join-wrap.sucess-wrap ul{
  padding-left: 0;
  margin-bottom: 30px;
  }
  .career-sections .join-wrap.sucess-wrap ul li{
  list-style: none;
  background: url(/b-bullet.png) no-repeat top left;
  font-weight: 300;
  font-family: 'Metropolis-Regular';
  background-position: -10px -12px;
  padding-left: 40px;
  margin-bottom: 20px;
  }
  .career-sections .tesimonial-section{
  background: rgba(190, 209, 206, .25);
  padding: 80px 0;
  margin-bottom: 80px;
  margin-top: 80px;
  }
  
  .career-sections .tesimonial-section .info{
  border:1px solid rgba(39, 53, 92, .2);
  padding:90px 40px 40px;
  text-align: center;
  position: relative;
  min-height: 300px;
  }
  .career-sections .tesimonial-section .info .iconbox{
  position: absolute;
  width: 322px;
  height: 322px;
  top: 158px;
  display: block;
  left: 50%;
  margin-left: -161px;
  margin-top: -320px;
  display: block;
  
  }
  .career-sections .tesimonial-section .info p{
  font-size:18px
  }
  .career-sections .tesimonial-section .info p.name{
  color:#F26C52;
  font-weight: 800;
  font-size:14px;
  margin-bottom: 0px;
  text-transform: uppercase;
  }
  .career-sections .tesimonial-section .info p.duration{
  color:#000;
  font-size:14px;
  font-weight: 800;
  }
  .career-sections h4{
  font-size: 22px;
  }
  .career-sections .solution-box{
  position: relative;
  border:1px solid rgba(190, 183, 179, .5);
  padding: 30px;
  text-align: center;
  padding-top: 100px;
  margin-top: 80px;
  margin-bottom: 60px;
  min-height: 346px;
  }
  .career-sections .solution-box .iconbox{
  position: absolute;
  width: 322px;
  height: 322px;
  top: 158px;
  display: block;
  left: 50%;
  margin-left: -161px;
  margin-top: -320px;
  display: block;
  
  
  }
  .career-sections .join-wrap.sucess-wrap h2{
  margin-top: 70px;
  }
  .career-sections .join-wrap.befefit-wrap{
  margin-top:-145px;
  max-width:745px;
  z-index: 999;
  }
  .career-sections .join-wrap.befefit-wrap .widget {
  margin-bottom: 40px;
  }
  .career-sections .join-wrap.befefit-wrap .widget img{
  position: relative;
  left: -22px;
  top:-40px;
  }
  .career-sections .join-wrap.befefit-wrap .widget .info{
  margin-top:-70px;
  position: relative;
  }
  .career-sections .get-in-touch{
  background: none;
  margin-top:20px
  }
  .career-sections .tesimonial-section.get-in-touch p{
  font-size: 16px;
  }
  .career-sections .tesimonial-section.get-in-touch label{
  display: block;
  font-family: 'Metropolis-Regular';
  font-size: 14px;
  text-transform:uppercase;
  font-weight: 800;
  text-align: left;
  margin-bottom: 5px;
  
  }
  .career-sections .tesimonial-section.get-in-touch .form-control{
  border-radius: 0;
  border:1px solid #DEDEDE;
  height: 50px;
  font-family: 'Metropolis-Regular';
  outline: none
  }
  .career-sections .tesimonial-section.get-in-touch .form-control:focus{
  box-shadow: 0px 0px 20px rgba(0, 79, 107, .1)!important;
  }
  .visible-xs{
  display: none;
  }
  .fadetxt{
  color:rgba(0, 79, 107, .1);
  font-family: 'Metropolis-Bold';
  font-size:190px;  
  position: absolute;
  top:-120px;
  left: -30px;
  }
  .expend{
  color:#F26C52!important;
  position: relative;
  text-decoration: none!important;
  }
  .expend::before{
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  display: block;
  content: '';
  height: 1px;
  border-bottom: 1px dashed #F26C52;
  transition: all .4s;
  }
  .expend:hover::before{
  width: 0;
  }
  .career-sections .info h6{
    margin-bottom: 10px;
  }
  @media (min-width:1921px) {
    .btn.btn-wrap span {
      
      padding: 20px 20px 22px 20px;
      
    }}
@media (max-width: 1280px) {
  .career-sections .join-wrap.sucess-wrap .ml-5{
    margin-left: 0!important;

  }
  .fadetxt {
    color: rgba(0, 79, 107, .1);
    font-family: 'Metropolis-Bold';
    font-size: 157px;
    font-weight: 800;
    position: absolute;
    top: -83px;
    left: 0px;
}

}
@media (max-width: 1200px) {
  .career-sections .join-wrap.befefit-wrap {
    margin-top: -60px;
  }
}
@media (max-width: 1120px) {
  .career-sections .join-wrap.befefit-wrap {
   margin-top: 0px;

}
.career-sections .solution-box {
  min-height: 370px;
}
}
@media (max-width: 992px) {
.career-sections .main-wrap {
  padding-top: 80px;
}
.career-sections .solution-box{
  margin-bottom: 20px;
}
.career-sections .join-wrap.sucess-wrap h2 {
  margin-top: 0px;
}
}
@media (max-width:767px) {
  .career-sections .join-wrap h1 {
    margin-top: 5%;
}

.career-sections h1,
.career-sections h2 {
  font-size:32px;
  line-height: 40px;
}
.career-sections .join-wrap.befefit-wrap .widget {
  text-align: center;
}
.career-sections .join-wrap.befefit-wrap .widget img {
  left: 0px;
}
.career-sections h6 {
  font-size: 14px;
  text-align: center;
}
.career-sections .join-wrap{
  text-align: center;
}
.career-sections .join-wrap.sucess-wrap ul li{
  text-align: justify;

}
.career-sections .get-in-touch{
  padding-top: 0px;
}
.career-sections .tesimonial-section .info {
    padding: 80px 20px 40px;
}
.career-sections .solution-box{
  min-height: auto;
}
.visible-xs{
  display: block;
}
.hidden-xs{
  display: none;
}
.career-sections .tesimonial-section{
  margin: 40px 0;
}
.fadetxt {
  color: rgba(0, 79, 107, .1);
  font-family: 'Metropolis-Bold';
  font-size: 79px;
  font-weight: 800;
  position: absolute;
  top: -64px;
  left: 50%;
  width: 300px;
  margin-left: -150px;
}
.btn.btn-wrap.btn-submit{
  padding: 18px 90px 10px 20px;
}
.btn.btn-wrap.btn-submit span{
  margin: 0px 0px 0px 20px;
}
.career-sections .get-in-touch .form-group{
  margin-bottom: 0px;
}
.mobileFade{margin-top:40px;}
}

.disableGetInTouch {
  pointer-events: none;
  color: #999!important;
  background: rgba(0, 0, 0, 0.1)!important;
  border-color: rgba(0, 0, 0, 0.1)!important;
}
/*End: MS-925*/



/*Start: MS-955*/
.listing-section .container{
  max-width: 930px;

}
.listing-section .listing-slider{
  max-width: 900px;
  margin: 0px auto;
}
.listing-section .listing-details .listing-info{
  position: relative;
  top:0px;
}
.listing-section .listing-header .listing-price{
  font-family: Metropolis-Bold;
}
.listing-section .listing-header{
  font-family: Metropolis-regular;
}
.listing-section .listing-details p{
  font-family: Metropolis-regular;
  font-size: 14px;
}
.listing-section .listing-details h4 {
  font-family: Cormorant Garamond,serif;
}
.listing-section .map-wrap {
  box-shadow: 0 4px 4px rgba(0,0,0,.25);
  border-radius: 1px;
  border: 2px solid #bfbfbf;
  overflow: hidden;
  height: 300px;
  margin-bottom: 15px;
}
.listing-section .listing-details .listing-amenities li{
  font-family: Metropolis-regular;
}

.listing-section .listing-details .listed-box .info {
  float: left;
  width: 110px;
  font-size: 14px;
}
/*Start: MS-910*/

.listing-result {
  z-index: 1;
}

/*End: MS-910*/
/*start: MS-990*/
.ourteam-section .team-block .team-img {
   height: 290px;
}
/*End: MS-990*/
.listing-section .listing-details .listing-discription th {
  width: 124px;
}

/*Start: MS-994*/
#ListingModal .listing-banner,
.listing-section .listing-slider{
  position: relative;
}
#ListingModal .listing-banner .tag,
.listing-section .listing-slider .tag {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  z-index: 9;
  background: url(/no-feetag.png) no-repeat;
  background-size: 100%;
}
/*End: MS-994*/
/*Start: MS-996*/
#ListingModal .modal-header {
  height: auto;
  padding-bottom: 0;
}
/*End: MS-996*/

.dropleft .dropdown-toggle::before{
  display: none;
}

.sidebar-nav span{
  max-width: 80%;
}
.dropdown-menu.show{
  padding: 10px;

}
.dropdown-menu.show li a{
  line-height: 18px;
}
/*start: MS-1011*/
.list-frame .tag {
  position: absolute;
  top: 0;
  left: 16px;
  width: 70px;
  height: 70px;
  z-index: 999;
  background: url(/no-feetag.png) no-repeat;
  background-size: 100%;
}



.member-layout .react-multi-carousel-list {
  position: relative!important;
  z-index: 99;
  height: 190px;
}

.member-layout .list-frame .listing-info {
  padding: 15px;
  height: 200px;
}
.member-layout ul li a.view-full img {
  
  height: -webkit-fill-available!important;
}

/*End: MS-1011*/


/*start: MS-953*/
#ListingModal .react-multi-carousel-list img{
 width: auto!important;
}
/*End: MS-953*/
/*--Start: MS_902---*/
.form-panel .form-widget .pagination li.left, .form-panel .form-widget .pagination li.right{
  width:55px;
}
.form-panel .form-widget .pagination li.left {
  width: 55px;
}
.form-panel .form-widget .pagination li.mid {
  width: 198px;
}
@media (max-width: 767px){
 .form-panel .form-widget .pagination li.mid {
      width: 226px;
  }
}
@media (max-width: 360px){
  .form-panel .form-widget .pagination li.mid {
       width: 185px;
   }
 }
 @media (max-width: 320px){
  .form-panel .form-widget .pagination li.mid {
       width: 145px;
  }
.form-panel a img.d-block{
  max-width:280px!important;
}
}


/*--End: MS_902---*/

/*--Start: MS_902-v1 ---*/
@media (max-width: 362px)
{.form-panel .form-widget .pagination li.mid {
width: 130px;
}
.form-panel .form-widget .pagination li.left, .form-panel .form-widget .pagination li.right {
width: 54px;
}
}

/*--End: MS_902---*/
/*Start: MS 999*/
.Newskyward-design .container.workbody.vr-section{
  padding:100px 80px 150px;
  margin-top: 150px;

}
@media (max-width: 992px) {
  .Newskyward-design .container.workbody.vr-section{
    padding:100px 30px 150px;
    margin-top: 40px;
  
  }
.Newskyward-design .container.workbody.vr-section h1{
  font-size: 25px;
}
}
@media (max-width: 767px) {
  .Newskyward-design .container.workbody.vr-section{
    padding:60px 30px 60px;
    
   
  }
.Newskyward-design .container.workbody.vr-section h1{
  font-size: 18px;
}
}
/*End: MS 999*/


/*Start: MS-1009*/

.react-tabs__tab-panel .agent-listing{
  margin-bottom: 0;
}
@media (max-width:1280px) {
  .listing-filter.agent-filter {
    max-width: 850px;
}
.listing-filter.agent-filter .panel_1{
  margin: 0 4px;
}
}
@media (max-width:1200px) {

.listing-filter.agent-filter .panel_1{
  margin: 0 15px;
}
}


/*End: MS-1009*/

/*Start: MS-997*/
.listing-banner .carousel .slide img{
  width: 100%;
}

.carousel .slide img{
  width: auto !important;
    max-height: 435px;
}


@media screen and (max-width: 767px) {
  .carousel .slide img {
    width: auto;
    vertical-align: top;
    border: 0;
    max-height: 207px;
}
}
/*End: MS-997*/ 


/*Start: MS-962*/

#searchr{
    overflow: auto!important;
    z-index: 9999;
}

/*End: MS-962*/




/*Start: MS-1039*/
.content .work-section .dashboard .panel-body.showingWrap{
  min-height: 160px;
  overflow: auto;
  padding: 0px 4px;

}

/*End: MS-1039*/

/*start: MS-1044*/
@media (max-width:1040px) {
header.FrontHeader .navbar-nav li a {
  font-size: 13px;
}
}
@media (max-width:992px) {
  .FrontHeader .navbar-collapse {
    margin-top: 60px!important;
}
.FrontHeader .navbar-nav li {
  padding: 0px 19px;
}
.FrontHeader .navbar-nav li img.user {
  margin-right: 20px;
}
}
@media (max-width:767px) {
.FrontHeader .navbar-collapse {
    margin-top: 34px!important;
}
.FrontHeader .navbar-nav li img.user {
  margin-right: 0px;
}
  }
/*End: MS-1044*/



/*Start: MS-1046*/
@media (max-width:1024px) {
.splitter-layout {
  padding-left:15px;
  padding-right: 15px;
}
.splitter-layout > .layout-splitter {
  display: none;
}
.splitter-layout > .layout-splitter + .layout-pane {
  display: none;
}
#mobileListpopup {
  display: block;
  
}
#mobileListpopup .modal-dialog {
  max-width: 100%;
}
}
@media (max-width:767px) {
  #mobileListpopup {
    left: 0%;
  }
}
/*End: MS-1046*/

/*Start: MS-916*/
ul.select-day{
  padding-left:0;
}
ul.select-day li{
  display: inline-block;
  border:1px solid #999;
  padding: 6px 29px;
  border-left: 0px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}
ul.select-day li:first-child{
  border-left:1px solid #999;
}
ul.select-day li:hover{
  background:#004C68;
  color:#fff;
}
ul.select-day li.active,
ul.select-day li:focus{
  background:#004C68;
  color:#fff;
}
@media (max-width:1200px) {
  ul.select-day li{
  padding: 6px 15px;
  font-size: 12px;
  }
}
/*End: MS-916*/


/*Start: MS-1077*/

@media (max-width: 992px){

.navbar {
    z-index: 9999!important;
}
}
/*End: MS-1077*/


/*Start: MS-1069*/
#gallery{
  z-index: 9999;
  padding-right: 0px!important;
}
#gallery .legend{
  display: none;
}
#gallery .modal-dialog{
  max-width: 100%;
  height: 100vh;
  margin: 0px;
}
#gallery .modal-content{
  min-width: auto;
  background: rgba(255,255,255, 0.9);
}
#gallery .modal-body{
  width: 90%;
  margin: 0px auto;
  height: 100vh;
  
}

#gallery .listing-banner .carousel .slide {max-height: 70vh;}
#gallery .listing-banner .carousel .slide img {
  width:auto;
 /* max-height: 600px;*/
}
#gallery .carousel .control-next.control-arrow {
  background: url(/next.png) no-repeat center!important;
  width: 55px!important;
  opacity: 1;
}
#gallery .carousel .control-prev.control-arrow {
  background: url(/prev.png) no-repeat center!important;
  width: 55px!important;
  opacity: 1;
}
#gallery .carousel.carousel-slider .control-arrow {background: #eff5f6;}
#gallery .carousel .slider-wrapper.axis-horizontal .slider .slide {background: none;}
#gallery .control-dots {display: none;}
/*End: MS-1069*/


/*Start: MS-1091*/
#ListingModal .react-multi-carousel-list img {
  width: 100%!important;
}

/*End: MS-1091*/

/*Start: MS-1020*/
#ScreeningsList{
  width: 95%;
  max-width: 300px;
}
#ScreeningsList input[type="checkbox"]:checked + label:after{
  display: none;
}
#ScreeningsList input[type="checkbox"]:checked + label:before{
  background: #074F6A;
  border:0px;
  opacity:0;
}
#ScreeningsList .active label:before{
  background: #074F6A;
  border:0px;
  opacity:1;
  }
/*End: MS-1020*/
#ScreeningsList .inactive label:before{
  display: none;
}
/*End: MS-1020*/
/*Start: MS-1084*/
.ansChat{
  box-shadow: none!important;
  border: 0px solid #004F6B!important;
  cursor: default!important;
  display: block!important;
}

.ansChat:hover{
  background: none!important;
  color:#004F6B!important
  
}
/*End: MS-1084*/

/*start: MS-1120*/
#ListingModal .react-multi-carousel-list li  div{
  background: #000000;
}
#ListingModal .react-multi-carousel-list li div img {
  margin: 0 auto;
  max-width: -webkit-fill-available;
  height: -webkit-fill-available;
  width: auto!important;
}
#ListingModal .react-multi-carousel-list .legend img{
  height: auto;
}
/*End: MS-1120*/

/*start: MS-1106*/
.listed-box .browselist-email{
  word-break: break-all;
}
/*End: MS-1106*/ 
/*start: MS-1107*/
.detail-exvr .experience-vr,
#ListingModal .experience-vr{
  border: 1px solid rgba(0,0,0,.5);
  border-radius: 3px;
  color: #000;
  font-family: Muli,sans-serif;
  max-width: 182px;
  margin: 0px auto;
  padding: 14px 17px;
  margin-bottom: 20px;
  margin-top: -10px;
}
#ListingModal .experience-vr img{
  height: auto;
  width: 20px!important;
  margin-right: 5px;
}
.detail-exvr .experience-vr{
  font-family: Muli,sans-serif;
  max-width: 182px;
  margin: 0px auto;
  padding: 14px 17px;
  margin-bottom: 20px;
  margin-top: 15px;
}
.detail-exvr .experience-vr span{
  padding-left: 5px;
  font-family: Muli,sans-serif;
  font-size: 14px;
}
.detail-exvr .experience-vr a:hover{
  text-decoration: none;
}
@media (max-width: 767px){
  #ListingModal .experience-vr {
      width: 50px!important;
      padding-left: 15px!important;
  }
}
/*start: MS-1107*/

.styles_modal__gNwvD{
  max-width: 100%;
}

/*End: MS-1069*/
/*MS-1116*/
.dateandtime{
  font-size: 14px;
  border-bottom: 1px solid #ccc;
}
.time-seprator{
  display:block;
}
.time-width{
  min-width: 400px;
}
.border-right{
  border-right: 1px solid #ccc;
}
/*MS-1116*/


/*Start: MS-1101*/
.editsmall {
  display: inline-flex;
}
.editsmall:hover,
.editsmall:focus{
  text-decoration: none;
}
/*End: MS-1101*/
/*MS-1160*/
 .img-360{
   position: absolute;
   top:10px;
   right: 10px;
   width:58px;
   height: 36px;
   border-radius: 5px;
   background: #fff;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   text-align: center;
   padding-top: 4px;
   z-index: 999;
 }
/*MS-1160*/


/*Start:MS-1174*/
.Newskyward-design .showroom-section .showroom-info{
  max-width: 100%;
}
.Newskyward-design .showroom-section h1{
  font-size: 25px;
}
/*MS-1174*/


/*Start: MS-1154*/
#addressSearchfront .box{
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  max-width: 420px;
}
#addressSearchfront .box input{
  width: 350px;
}
#addressSearchfront .box .close{
  position: relative;
  top:-20px;
  color:#00506B;
  opacity: 1;
}
#addressSearchfront .box .close:hover{
  opacity: .2;
}
#addressSearchfront .box ul{
   max-height: 230px;
  overflow: auto;
  margin-top: -10px;
}
#addressSearchfront .box ul li{
  background: #fff;
  padding:0px 10px;
}

@media (max-width: 550px){
  #addressSearchfront .box input{
    width: 180px;
  }
  #addressSearchfront .box{
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    max-width:240px;
  }
  .listing-filter .advance-search .filter-block{
    padding: 10px 25px;
  }
  
}
/*End: MS-1154*/
/*start:MS-1183*/
#gallery .cl-btn{
  float: right;
  position: relative;
}
#gallery .cl-btn::before{
  content: 'Close';
  display: block;
  right: 21px;
  top: 7px;
  color: #004C68;
  position: absolute;
  font-size: 19px;
  letter-spacing: 1px;

}

/*end:MS-1183*/
/*start-1191*/
@media (max-width: 767px){
.detail-exvr .experience-vr {
    width: 50px!important;
    padding: 9px 22px!important;
    padding-left: 15px!important;
}
}
/*end-1191*/

/*Start:MS-1192*/
.a4-footer .btn.editor-btn{
  width: 125px!important;
  padding: 10px 12px!important;
}
/*End:MS-1192*/

.table-striped tbody .List div:nth-of-type(odd) {background-color: #fff;}
.table-striped tbody .List div:nth-of-type(even) {background-color: rgba(0,0,0,.05);}
.List tr {background-color: transparent!important;}


/*start:MS-1212*/
.newappform #application_form_section_one,
.newappform #application_form_section_two,
.newappform #application_form_section_three,
.newappform #application_form_section_four,
.newappform #application_form_section_five,
.newappform #application_form_section_six{
  display: block!important;
}
.newappform .form-panel,
.newappform .form-panel .form-widget.login-widget{
  max-width: 800px;
}
.newappform .form-widget .form-wrap{
  width:100%;
}
.newappform .form-panel .form-widget .pagination{
  width: 300px;
  left:50%;
  margin-left: -150px;
  display: none;
}
.newappform .form-panel .form-widget{
  padding-bottom: 0px;
}
.newappform .form-panel .progress-widget{
 max-width: 990px;
 display: none;
}
.newappform .form-panel .form-widget .form-section{
  min-height: auto;
}
.newappform .form-panel .form-widget .message{
  height: auto;
  padding: 25px 35px;
}
/*end:MS-1212*/




/*Start: MS 1263*/

.headVr .headerVR .navbar {
  padding:0px;
}
.headVr .headerVR .navbar-expand-lg .navbar-nav{
  display: flex;
  width: 100%;
  align-items: center;
}
.headVr .headerVR{
  background:#004F6B;
  position: relative;
  height: 50px;
}

.headVr .headerVR .navbar-expand-lg .navbar-nav li {
  padding:5px 0;
}
.headVr .headerVR .navbar-expand-lg .navbar-nav li a{
  padding: 20px 30px;
}
.headVr .headerVR .navbar-expand-lg .navbar-nav li.active a,
.headVr .headerVR .navbar-expand-lg .navbar-nav li a:hover{
  background:#fff;
  color:#004F6B;
}
.jointheGiving .infoWrap h2{
    font-weight: 700;
    font-size: 33px;
    font-style: normal;
    padding: 14px;
    color: #000;
    width:100%;
    height:68px;
    background-color:#e3e3e3;
    box-sizing: border-box;
    /*Below are two new line which enable opacity*/
    opacity: 0.9;
    filter: alpha(opacity=90);
}
.jointheGiving .infoWrap h3{
  font-weight: 700;
  font-size: 25px;
  padding-bottom: 15px;
  font-style: normal;
}
.jointheGiving .infoWrap {
  padding:0px 30px;
  margin-top:60px;
}
.jointheGiving .infoWrap:nth-child(1){
  border-right:1px solid #DEDBD9;
}
.jointheGiving .infoWrap:nth-child(2){
  border-right:1px solid #DEDBD9;
}
.jointheGiving .infoWrap:nth-child(n+1) h2{
   border-bottom:2px solid #004F6B;
}
.jointheGiving .infoWrap:nth-child(n+2) h2{
  border-bottom:2px solid #F37158;
}
.jointheGiving .infoWrap:nth-child(n+3) h2{
  border-bottom:2px solid #7B7D1B;
}
.jointheGiving .infoWrap:nth-child(n+1) h3{
  border-bottom:2px solid #004F6B;
}
.jointheGiving .infoWrap:nth-child(n+2) h3{
 border-bottom:2px solid #F37158;
}
.jointheGiving .infoWrap:nth-child(n+3) h3{
 border-bottom:2px solid #7B7D1B;
}
.jointheGiving .InfoTxt{
  font-family: 'Metropolis-ExtraBold';
  text-align: left;
  font-size: 18px;

}
.jointheGiving .price-widget{
  min-height: 60px;
}
.jointheGiving .price-widget span.price{
  font-size: 24px;
  font-family: 'Metropolis-ExtraBold';
  font-style: normal;
}
.jointheGiving .price-widget span.duration{
  display: block;
  font-style: normal;
  font-size: 14px; 
  text-transform: uppercase;
  font-family: 'Metropolis-Regular';
}
.jointheGiving ul{
  padding-left: 0px;
  text-align: left;
  margin-top:30px
}
.jointheGiving ul li{
  position: relative;
  list-style: none;
  text-align: left;
  font-style: normal;
  padding-left: 20px;
  font-family: 'Metropolis-Regular';
  font-size: 18px;
  line-height: 35px;
}
.jointheGiving ul li.bold{
  font-family: 'Metropolis-ExtraBold';
}
.jointheGiving ul li::before{
  content: '>';
  position: absolute;
  left:0px;
  top:1px;
}
.Newskyward-design .Join-section.philanthropy {
  min-height: 800px;
}
.philanthropy .philanthropy-box{
  padding:50px 30px;
  max-width: 1000px;
  margin:120px auto 50px;

}
.philanthropy .philanthropy-box p{
  font-size: 24px;
  font-style: italic;
  padding: 0 85px;
  font-family: 'Cormorant Garamond', serif;
  color:#000;
  padding-bottom: 25px;
}
.btnlight{
  border-radius:0px;
  border:0;
  font-size: 24px;
  padding: 8px 20px 6px;
  box-shadow:none;
  text-decoration:none;
  height: auto;
  background: #b8cbc8; 
  display: inline-block;
  font-family: 'Metropolis-ExtraBold';
  font-style: normal;
  color:#fff!important;
  letter-spacing: 3px;
}

.btnlight:hover{
  font-size: 24px;
  text-decoration: none;
  background:#000;
}

.bgstrip{
  background: #b9cdc9;
}
.bgstrip .row{
  margin:0px;
  max-height: 555px;
}

.virtual-section .imgwrap{
  margin-left: -15px;
}
.virtual-section .text-info{
  max-width: 450px;
  margin:80px 0;
}
.virtual-section .text-info h2{
  font-family: 'Metropolis-ExtraBold';
  color:#fff;
  text-decoration: underline;
}
.virtual-section .text-info p{
  font-family: 'Cormorant Garamond', serif;
  font-size: 20px;
  margin:30px 0;
 
}
.virtual-section .link{
  color:#fff;
  font-family: 'Metropolis-ExtraBold';
  font-size: 22px;
  letter-spacing: 4px;
}
.tours-section .text-block{
  margin:40px 0;
  margin-right: 80px;
}
.tours-section .text-block .title{
  font-family: 'Cormorant Garamond', serif;
  color:#000;
  font-style: normal;
  font-size: 32px;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 60px;
}
.tours-section .text-block .headtxt{
  font-family: 'Metropolis-ExtraBold';
  line-height: 20px;
}
.tours-section .text-block .info{
  font-family: 'Cormorant Garamond', serif;
  font-size: 26px;
  margin-top:25px;
  line-height: 30px;
  color: #000;
    font-weight: 600;
  letter-spacing: 1px;
}

@media (max-width: 992px){
  .tours-section .img-block{
    text-align: center;
  }
  .virtual-section .imgwrap img,
  .tours-section .img-block img{
    width: 100%;
  }
  .headVr .headerVR .navbar-expand-lg .navbar-nav{
    margin-top:48px;
  } 
  .headVr .headerVR .navbar-expand-lg .navbar-nav li,
  .headVr .headerVR .navbar-expand-lg .navbar-nav{
    display: block;
  } 
  .headVr .headerVR .navbar-dark .navbar-toggler {
    position: absolute;
    top: 10px;
    right: -10px;
}
.headVr .headerVR .navbar {
  position: relative;
}
.headVr .headerVR .navbar-expand-lg .navbar-nav li {
  padding: 0px 0;
}
.headVr .headerVR .navbar-expand-lg .navbar-nav li a {
  padding: 12px 30px;
}

.philanthropy .philanthropy-box p {
  font-size: 24px;  
  padding: 0 0px;
}
}
/*End: MS 1263*/
/*Start:MS-1269*/
.btn-table{
    min-width: auto!important;
    padding: 3px 8px;
    height: auto;
    font-size: 11px;
    margin:0px 5px;
} 
@media (max-width:767px){
  .bgstrip .row{
    margin:0px;
    max-height: none;
  }
  .Newskyward-design .Join-section.philanthropy {
    min-height: 1050px;
  }
}



/*End:MS-1269*/

/* by farhan */

.FrontEnd footer .logo {
  width: 92px;
  height: 95px;
  background: url(/wht-logo.png) no-repeat;
  position: absolute;
  left: 50%;
  margin-left: -46px;
  top: -100px;
  margin-top: 0;
}
@media only screen and (min-width: 768px) and (max-width: 1024px)  { 
  .ourteam-section .team-block .team-img {
height: 330px;
}
}

@media only screen and (min-width: 375px) and (max-width: 767px)  { 
  .ourteam-section .team-block .team-img {
height: 400px;
}
}

.theme_box {
  background: #7e2d2d00;
  padding: 20px 0px;
  max-width: 800px;
  margin: auto;
  margin-bottom: 10px !important;
 
}
.theme_box p {
  color: #fff !important;
  text-align: center;
  font-size: 3.5rem;
  font-weight: 500;
  font-family: 'Cormorant Garamond', serif;
  margin: 0;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.show_loader_on_click {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.show_loader_on_click {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    _background: url('/images/loader.gif') 50% 50% no-repeat rgb(249,249,249);
    opacity: .8;
}
.noListingFoundDiv{
  font-family: 'Cormorant Garamond', serif;
  font-style: normal;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
    width: 100%;
}
#signature::-webkit-input-placeholder {
  color: #ff0000;
  opacity: 1;
}
#signature::placeholder {
  color: #ff0000;
  opacity: 1;
}
    _background: url('/images/loader.gif') 50% 50% no-repeat rgb(249,249,249);
    opacity: .8;
}
.noListingFoundDiv{
  font-family: 'Cormorant Garamond', serif;
  font-style: normal;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
    width: 100%;
}
#signature::-webkit-input-placeholder {
  color: #ff0000;
  opacity: 1;
}
#signature::placeholder {
  color: #ff0000;
  opacity: 1;
}

.sidebar_content_pdf{
  width: 100%;
  height: 85vh;
  box-shadow: 0px 0px 10px 5px #cccccc30;
  padding: 30px 10px;
  overflow: auto;
}
.sidebar_content_pdf .card {
  height: 160px;
  width: 100%;
  overflow: hidden;
  transition: all 0.5s;
}
.main_content_pdf {
  width: 800px;
  height: 85vh;
  box-shadow: 0px 0px 10px 5px #cccccc30;
  padding: 30px;
 overflow-x: hidden !important;
 margin: auto;
}
canvas.react-pdf__Page__canvas {
  width: 100% !important;
   height: 100% !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a7a7a7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #858585;
  width: 10px;
}
::-webkit-scrollbar:hover {
  width: 10px;
}
.priview_page_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility:hidden;
  background: #fff;
}
.sidebar_content_pdf .card:hover .priview_page_wrap {
  opacity: 1;
  visibility: visible;
}
.icon_div {
  width: 25%;
  padding: 5px;
  background: #fff;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.icon_div:hover{
  background-color: #ddd;
}
.page_no {
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  background: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
}
.pic_body {
  width: 100%;
  height: 160px;
}
.pic_body img {
  width: 100%;
}
.add__file {
  position: relative;
  right: 0;
  top: 0px;
  width: 30px;
  height: 16px;
}
.add__file i {
  font-size: 20px;
} 
.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  background-color: transparent;
  margin: 0;
}

.file-input i{
color: #000;
}

.leaflet-cluster-anim .leaflet-marker-icon, .leaflet-cluster-anim .leaflet-marker-shadow {
  transition: opacity 0.3s ease-in, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in, -webkit-transform 0.3s ease-out; }

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in; }

.marker-cluster-small {
  background-color: rgba(154, 212, 99, 0.6); }

.marker-cluster-small div {
  background-color: #777913;
  color: #ffffff; }

.marker-cluster-medium {
  background-color: rgba(156, 186, 224, 0.925); }

.marker-cluster-medium div {
  background-color: #254f6b;
  color: #ffffff; }

.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6); }

.marker-cluster-large div {
  background-color: #e96b51; }

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
  background-color: #b5e28c; }

.leaflet-oldie .marker-cluster-small div {
  background-color: #6ecc39; }

.leaflet-oldie .marker-cluster-medium {
  background-color: #f1d357; }

.leaflet-oldie .marker-cluster-medium div {
  background-color: #f0c20c; }

.leaflet-oldie .marker-cluster-large {
  background-color: #fd9c73; }

.leaflet-oldie .marker-cluster-large div {
  background-color: #f18017; }

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px; }

.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  font: 20px "Metropolis-Bold"; }

.marker-cluster span {
  line-height: 30px; }

.markercluster-map {
  height: 690px;
  width: 588px;
  margin-bottom: 10px; }
  .markercluster-map .marker-cluster-custom {
    background: #9370db;
    border: 3px solid #ededed;
    border-radius: 50%;
    color: #ededed;
    height: 40px;
    line-height: 37px;
    text-align: center;
    width: 40px; }
  .markercluster-map .panel-body pre {
    padding: 0; }

/* required styles */

.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
	position: absolute;
	left: 0;
	top: 0;

	}
.leaflet-container {
	overflow: hidden;
	height:600px;
	}
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
	-webkit-user-select: none;
	        user-select: none;
	  -webkit-user-drag: none;
	}
/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
	image-rendering: -webkit-optimize-contrast;
	}
/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
	width: 1600px;
	height: 1600px;
	-webkit-transform-origin: 0 0;
	}
.leaflet-marker-icon,
.leaflet-marker-shadow {
	display: block;
	}
/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer {
	max-width: none !important;
	}

.leaflet-container.leaflet-touch-zoom {
	touch-action: pan-x pan-y;
	}
.leaflet-container.leaflet-touch-drag {
	-ms-touch-action: pinch-zoom;
	}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
	touch-action: none;
}
.leaflet-container {
	-webkit-tap-highlight-color: transparent;
}
.leaflet-container a {
	-webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}
.leaflet-tile {
	-webkit-filter: inherit;
	        filter: inherit;
	visibility: hidden;
	}
.leaflet-tile-loaded {
	visibility: inherit;
	}
.leaflet-zoom-box {
	width: 0;
	height: 0;
	box-sizing: border-box;
	z-index: 800;
	}
/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
	-moz-user-select: none;
	}

.leaflet-pane         { z-index: 400; }

.leaflet-tile-pane    { z-index: 200; 
 -webkit-filter: grayscale(100%);
  filter: grayscale(100%); 
}
.leaflet-overlay-pane { z-index: 400; }
.leaflet-shadow-pane  { z-index: 500; }
.leaflet-marker-pane  { z-index: 600; }
.leaflet-tooltip-pane   { z-index: 650; }
.leaflet-popup-pane   { z-index: 700; }

.leaflet-map-pane canvas { z-index: 100; }
.leaflet-map-pane svg    { z-index: 200; }

.leaflet-vml-shape {
	width: 1px;
	height: 1px;
	}
.lvml {
	behavior: url(#default#VML);
	display: inline-block;
	position: absolute;
	}


/* control positioning */

.leaflet-control {
	position: relative;
	z-index: 800;
	pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
	pointer-events: auto;
	}
.leaflet-top,
.leaflet-bottom {
	position: absolute;
	z-index: 1000;
	pointer-events: none;
	}
.leaflet-top {
	top: 0;
	}
.leaflet-right {
	right: 0;
	}
.leaflet-bottom {
	bottom: 0;
	}
.leaflet-left {
	left: 0;
	}
.leaflet-control {
	float: left;
	clear: both;
	}
.leaflet-right .leaflet-control {
	float: right;
	}
.leaflet-top .leaflet-control {
	margin-top: 10px;
	}
.leaflet-bottom .leaflet-control {
	margin-bottom: 10px;
	}
.leaflet-left .leaflet-control {
	margin-left: 10px;
	}
.leaflet-right .leaflet-control {
	margin-right: 10px;
	}


/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile {
	will-change: opacity;
	}
.leaflet-fade-anim .leaflet-popup {
	opacity: 0;
	transition: opacity 0.2s linear;
	}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
	opacity: 1;
	}
.leaflet-zoom-animated {
	-webkit-transform-origin: 0 0;
	        transform-origin: 0 0;
	}
.leaflet-zoom-anim .leaflet-zoom-animated {
	will-change: transform;
	}
.leaflet-zoom-anim .leaflet-zoom-animated {
	transition:         -webkit-transform 0.25s cubic-bezier(0,0,0.25,1);
	transition:         transform 0.25s cubic-bezier(0,0,0.25,1);
	transition:         transform 0.25s cubic-bezier(0,0,0.25,1), -webkit-transform 0.25s cubic-bezier(0,0,0.25,1);
	}
.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
	transition: none;
	}

.leaflet-zoom-anim .leaflet-zoom-hide {
	visibility: hidden;
	}


/* cursors */

.leaflet-interactive {
	cursor: pointer;
	}
.leaflet-grab {
	cursor:    -moz-grab;
	}
.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
	cursor: crosshair;
	}
.leaflet-popup-pane,
.leaflet-control {
	cursor: auto;
	}
.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
	cursor: move;
	cursor:    -moz-grabbing;
	}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
	pointer-events: none;
	}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive {
	pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
	pointer-events: auto;
	}

/* visual tweaks */

.leaflet-container {
	background: #ddd;
	outline: 0;
	}
.leaflet-container a {
	color: #0078A8;
	}
.leaflet-container a.leaflet-active {
	outline: 2px solid orange;
	}
.leaflet-zoom-box {
	border: 2px dotted #38f;
	background: rgba(255,255,255,0.5);
	}


/* general typography */
.leaflet-container {
	font: 20px "Metropolis-Bold", Arial, Helvetica, sans-serif;
	}


/* general toolbar styles */

.leaflet-bar {
	box-shadow: 0 1px 5px rgba(0,0,0,0.65);
	border-radius: 4px;
	}
.leaflet-bar a,
.leaflet-bar a:hover {
	background-color: #fff;
	border-bottom: 1px solid #ccc;
	width: 26px;
	height: 26px;
	line-height: 26px;
	display: block;
	text-align: center;
	text-decoration: none;
	color: black;
	}
.leaflet-bar a,
.leaflet-control-layers-toggle {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	display: block;
	}
.leaflet-bar a:hover {
	background-color: #f4f4f4;
	}
.leaflet-bar a:first-child {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	}
.leaflet-bar a:last-child {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border-bottom: none;
	}
.leaflet-bar a.leaflet-disabled {
	cursor: default;
	background-color: #f4f4f4;
	color: #bbb;
	}

.leaflet-touch .leaflet-bar a {
	width: 30px;
	height: 30px;
	line-height: 30px;
	}
.leaflet-touch .leaflet-bar a:first-child {
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	}
.leaflet-touch .leaflet-bar a:last-child {
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
	font: bold 18px 'Lucida Console', Monaco, monospace;
	text-indent: 1px;
	}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out  {
	font-size: 22px;
	}


/* layers control */

.leaflet-control-layers {
	box-shadow: 0 1px 5px rgba(0,0,0,0.4);
	background: #fff;
	border-radius: 5px;
	}
.leaflet-control-layers-toggle {
	background-image: url(/layers.png);
	width: 36px;
	height: 36px;
	}
.leaflet-retina .leaflet-control-layers-toggle {
	background-image: url(/layers-2x.png);
	background-size: 26px 26px;
	}
.leaflet-touch .leaflet-control-layers-toggle {
	width: 44px;
	height: 44px;
	}
.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
	display: none;
	}
.leaflet-control-layers-expanded .leaflet-control-layers-list {
	display: block;
	position: relative;
	}
.leaflet-control-layers-expanded {
	padding: 6px 10px 6px 6px;
	color: #333;
	background: #fff;
	}
.leaflet-control-layers-scrollbar {
	overflow-y: scroll;
	overflow-x: hidden;
	padding-right: 5px;
	}
.leaflet-control-layers-selector {
	margin-top: 2px;
	position: relative;
	top: 1px;
	}
.leaflet-control-layers label {
	display: block;
	}
.leaflet-control-layers-separator {
	height: 0;
	border-top: 1px solid #ddd;
	margin: 5px -10px 5px -6px;
	}

/* Default icon URLs */
.leaflet-default-icon-path {
	/*background-image: url(./marker-icon.png);*/
	background-image: url(/marker-icon.png);
	}


/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
	background: #fff;
	background: rgba(255, 255, 255, 0.7);
	margin: 0;
	}
.leaflet-control-attribution,
.leaflet-control-scale-line {
	padding: 0 5px;
	color: #333;
	}
.leaflet-control-attribution a {
	text-decoration: none;
	}
.leaflet-control-attribution a:hover {
	text-decoration: underline;
	}
.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
	font-size: 11px;
	}
.leaflet-left .leaflet-control-scale {
	margin-left: 5px;
	}
.leaflet-bottom .leaflet-control-scale {
	margin-bottom: 5px;
	}
.leaflet-control-scale-line {
	border: 2px solid #777;
	border-top: none;
	line-height: 1.1;
	padding: 2px 5px 1px;
	font-size: 11px;
	white-space: nowrap;
	overflow: hidden;
	box-sizing: border-box;

	background: #fff;
	background: rgba(255, 255, 255, 0.5);
	}
.leaflet-control-scale-line:not(:first-child) {
	border-top: 2px solid #777;
	border-bottom: none;
	margin-top: -2px;
	}
.leaflet-control-scale-line:not(:first-child):not(:last-child) {
	border-bottom: 2px solid #777;
	}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	box-shadow: none;
	}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	border: 2px solid rgba(0,0,0,0.2);
	background-clip: padding-box;
	}


/* popup */

.leaflet-popup {
	position: absolute;
	text-align: center;
	margin-bottom: 20px;
	}
.leaflet-popup-content-wrapper {
	padding: 1px;
	text-align: left;
	border-radius: 12px;
	}
.leaflet-popup-content {
	margin: 13px 19px;
	line-height: 1.4;
	}
.leaflet-popup-content p {
	margin: 18px 0;
	}
.leaflet-popup-tip-container {
	width: 40px;
	height: 20px;
	position: absolute;
	left: 50%;
	margin-left: -20px;
	overflow: hidden;
	pointer-events: none;
	}
.leaflet-popup-tip {
	width: 17px;
	height: 17px;
	padding: 1px;

	margin: -10px auto 0;

	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
	background: white;
	color: #333;
	box-shadow: 0 3px 14px rgba(0,0,0,0.4);
	}
.leaflet-container a.leaflet-popup-close-button {
	position: absolute;
	top: 0;
	right: 0;
	padding: 4px 4px 0 0;
	border: none;
	text-align: center;
	width: 18px;
	height: 14px;
	font: 16px/14px Tahoma, Verdana, sans-serif;
	color: #c3c3c3;
	text-decoration: none;
	font-weight: bold;
	background: transparent;
	}
.leaflet-container a.leaflet-popup-close-button:hover {
	color: #999;
	}
.leaflet-popup-scrolled {
	overflow: auto;
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
	}

.leaflet-oldie .leaflet-popup-content-wrapper {
	zoom: 1;
	}
.leaflet-oldie .leaflet-popup-tip {
	width: 24px;
	margin: 0 auto;

	-ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
	filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
	}
.leaflet-oldie .leaflet-popup-tip-container {
	margin-top: -1px;
	}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
	border: 1px solid #999;
	}


/* div icon */

.leaflet-div-icon {
	background: #fff;
	border: 1px solid #666;
	}


/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
	position: absolute;
	padding: 6px;
	background-color: #fff;
	border: 1px solid #fff;
	border-radius: 3px;
	color: #222;
	white-space: nowrap;
	-webkit-user-select: none;
	user-select: none;
	pointer-events: none;
	box-shadow: 0 1px 3px rgba(0,0,0,0.4);
	}
.leaflet-tooltip.leaflet-clickable {
	cursor: pointer;
	pointer-events: auto;
	}
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
	position: absolute;
	pointer-events: none;
	border: 6px solid transparent;
	background: transparent;
	content: "";
	}

/* Directions */

.leaflet-tooltip-bottom {
	margin-top: 6px;
}
.leaflet-tooltip-top {
	margin-top: -6px;
}
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
	left: 50%;
	margin-left: -6px;
	}
.leaflet-tooltip-top:before {
	bottom: 0;
	margin-bottom: -12px;
	border-top-color: #fff;
	}
.leaflet-tooltip-bottom:before {
	top: 0;
	margin-top: -12px;
	margin-left: -6px;
	border-bottom-color: #fff;
	}
.leaflet-tooltip-left {
	margin-left: -6px;
}
.leaflet-tooltip-right {
	margin-left: 6px;
}
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
	top: 50%;
	margin-top: -6px;
	}
.leaflet-tooltip-left:before {
	right: 0;
	margin-right: -12px;
	border-left-color: #fff;
	}
.leaflet-tooltip-right:before {
	left: 0;
	margin-left: -12px;
	border-right-color: #fff;
	}

  .input_suggestion {
    border: 1px solid #999;
    padding: 0.5rem;
    width: 300px;
  } */
  
  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }
  
  .suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(284px + 1rem);
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: #acacac;
    color: #000000;
    cursor: pointer;
    font-weight: 700;
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }

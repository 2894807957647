// TEMP: uncomment at v1.2.0
@import './styles';

$puple: #9370db;
$gray: #ededed;


  // MapExample component styles
  .markercluster-map {
    height: 690px;
   width : 588px;
    margin-bottom: 10px;

    // Customising the Clustered Markers
    .marker-cluster-custom {
      background: $puple;
      border: 3px solid $gray;
      border-radius: 50%;
      color: $gray;
      height: 40px;
      line-height: 37px;
      text-align: center;
      width: 40px;

  }


  // Overrite bootstrap pre tag style
  .panel-body {
    pre {
      padding: 0;
    }
  }
}

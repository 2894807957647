.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.show_loader_on_click {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.show_loader_on_click {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    _background: url('/images/loader.gif') 50% 50% no-repeat rgb(249,249,249);
    opacity: .8;
}
.noListingFoundDiv{
  font-family: 'Cormorant Garamond', serif;
  font-style: normal;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
    width: 100%;
}
#signature::-webkit-input-placeholder {
  color: #ff0000;
  opacity: 1;
}
#signature::placeholder {
  color: #ff0000;
  opacity: 1;
}
    _background: url('/images/loader.gif') 50% 50% no-repeat rgb(249,249,249);
    opacity: .8;
}
.noListingFoundDiv{
  font-family: 'Cormorant Garamond', serif;
  font-style: normal;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
    width: 100%;
}
#signature::-webkit-input-placeholder {
  color: #ff0000;
  opacity: 1;
}
#signature::placeholder {
  color: #ff0000;
  opacity: 1;
}

.sidebar_content_pdf{
  width: 100%;
  height: 85vh;
  box-shadow: 0px 0px 10px 5px #cccccc30;
  padding: 30px 10px;
  overflow: auto;
}
.sidebar_content_pdf .card {
  height: 160px;
  width: 100%;
  overflow: hidden;
  transition: all 0.5s;
}
.main_content_pdf {
  width: 800px;
  height: 85vh;
  box-shadow: 0px 0px 10px 5px #cccccc30;
  padding: 30px;
 overflow-x: hidden !important;
 margin: auto;
}
canvas.react-pdf__Page__canvas {
  width: 100% !important;
   height: 100% !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a7a7a7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #858585;
  width: 10px;
}
::-webkit-scrollbar:hover {
  width: 10px;
}
.priview_page_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility:hidden;
  background: #fff;
}
.sidebar_content_pdf .card:hover .priview_page_wrap {
  opacity: 1;
  visibility: visible;
}
.icon_div {
  width: 25%;
  padding: 5px;
  background: #fff;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.icon_div:hover{
  background-color: #ddd;
}
.page_no {
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  background: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
}
.pic_body {
  width: 100%;
  height: 160px;
}
.pic_body img {
  width: 100%;
}
.add__file {
  position: relative;
  right: 0;
  top: 0px;
  width: 30px;
  height: 16px;
}
.add__file i {
  font-size: 20px;
} 
.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  background-color: transparent;
  margin: 0;
}

.file-input i{
color: #000;
}
